// USA states list
export const US_States = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    code: 'FM',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

// Microsoft Timezone list
export const MS_TimeZone = [
  { code: 'Dateline Standard Time', name: '(UTC-12:00) International Date Line West' },
  { code: 'UTC-11', name: '(UTC-11:00) Coordinated Universal Time-11' },
  { code: 'Hawaiian Standard Time', name: '(UTC-10:00) Hawaii' },
  { code: 'Alaskan Standard Time', name: '(UTC-09:00) Alaska' },
  { code: 'Pacific Standard Time (Mexico)', name: '(UTC-08:00) Baja California' },
  { code: 'Pacific Standard Time', name: '(UTC-08:00) Pacific Time (US & Canada)' },
  { code: 'US Mountain Standard Time', name: '(UTC-07:00) Arizona' },
  { code: 'Mountain Standard Time (Mexico)', name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan' },
  { code: 'Mountain Standard Time', name: '(UTC-07:00) Mountain Time (US & Canada)' },
  { code: 'Central America Standard Time', name: '(UTC-06:00) Central America' },
  { code: 'Central Standard Time', name: '(UTC-06:00) Central Time (US & Canada)' },
  {
    code: 'Central Standard Time (Mexico)',
    name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  },
  { code: 'Canada Central Standard Time', name: '(UTC-06:00) Saskatchewan' },
  { code: 'SA Pacific Standard Time', name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco' },
  { code: 'Eastern Standard Time', name: '(UTC-05:00) Eastern Time (US & Canada)' },
  { code: 'US Eastern Standard Time', name: '(UTC-05:00) Indiana (East)' },
  { code: 'Venezuela Standard Time', name: '(UTC-04:30) Caracas' },
  { code: 'Paraguay Standard Time', name: '(UTC-04:00) Asuncion' },
  { code: 'Atlantic Standard Time', name: '(UTC-04:00) Atlantic Time (Canada)' },
  { code: 'Central Brazilian Standard Time', name: '(UTC-04:00) Cuiaba' },
  { code: 'SA Western Standard Time', name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan' },
  { code: 'Pacific SA Standard Time', name: '(UTC-04:00) Santiago' },
  { code: 'Newfoundland Standard Time', name: '(UTC-03:30) Newfoundland' },
  { code: 'E. South America Standard Time', name: '(UTC-03:00) Brasilia' },
  { code: 'Argentina Standard Time', name: '(UTC-03:00) Buenos Aires' },
  { code: 'SA Eastern Standard Time', name: '(UTC-03:00) Cayenne, Fortaleza' },
  { code: 'Greenland Standard Time', name: '(UTC-03:00) Greenland' },
  { code: 'Montevideo Standard Time', name: '(UTC-03:00) Montevideo' },
  { code: 'Bahia Standard Time', name: '(UTC-03:00) Salvador' },
  { code: 'UTC-02', name: '(UTC-02:00) Coordinated Universal Time-02' },
  { code: 'Mid-Atlantic Standard Time', name: '(UTC-02:00) Mid-Atlantic - Old' },
  { code: 'Azores Standard Time', name: '(UTC-01:00) Azores' },
  { code: 'Cape Verde Standard Time', name: '(UTC-01:00) Cape Verde Is.' },
  { code: 'Morocco Standard Time', name: '(UTC) Casablanca' },
  { code: 'UTC', name: '(UTC) Coordinated Universal Time' },
  { code: 'GMT Standard Time', name: '(UTC) Dublin, Edinburgh, Lisbon, London' },
  { code: 'Greenwich Standard Time', name: '(UTC) Monrovia, Reykjavik' },
  {
    code: 'W. Europe Standard Time',
    name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    code: 'Central Europe Standard Time',
    name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  { code: 'Romance Standard Time', name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { code: 'Central European Standard Time', name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
  { code: 'W. Central Africa Standard Time', name: '(UTC+01:00) West Central Africa' },
  { code: 'Namibia Standard Time', name: '(UTC+01:00) Windhoek' },
  { code: 'Jordan Standard Time', name: '(UTC+02:00) Amman' },
  { code: 'GTB Standard Time', name: '(UTC+02:00) Athens, Bucharest' },
  { code: 'Middle East Standard Time', name: '(UTC+02:00) Beirut' },
  { code: 'Egypt Standard Time', name: '(UTC+02:00) Cairo' },
  { code: 'Syria Standard Time', name: '(UTC+02:00) Damascus' },
  { code: 'E. Europe Standard Time', name: '(UTC+02:00) E. Europe' },
  { code: 'South Africa Standard Time', name: '(UTC+02:00) Harare, Pretoria' },
  { code: 'FLE Standard Time', name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
  { code: 'Turkey Standard Time', name: '(UTC+02:00) Istanbul' },
  { code: 'Israel Standard Time', name: '(UTC+02:00) Jerusalem' },
  { code: 'Libya Standard Time', name: '(UTC+02:00) Tripoli' },
  { code: 'Arabic Standard Time', name: '(UTC+03:00) Baghdad' },
  { code: 'Kaliningrad Standard Time', name: '(UTC+03:00) Kaliningrad, Minsk' },
  { code: 'Arab Standard Time', name: '(UTC+03:00) Kuwait, Riyadh' },
  { code: 'E. Africa Standard Time', name: '(UTC+03:00) Nairobi' },
  { code: 'Iran Standard Time', name: '(UTC+03:30) Tehran' },
  { code: 'Arabian Standard Time', name: '(UTC+04:00) Abu Dhabi, Muscat' },
  { code: 'Azerbaijan Standard Time', name: '(UTC+04:00) Baku' },
  { code: 'Russian Standard Time', name: '(UTC+04:00) Moscow, St. Petersburg, Volgograd' },
  { code: 'Mauritius Standard Time', name: '(UTC+04:00) Port Louis' },
  { code: 'Georgian Standard Time', name: '(UTC+04:00) Tbilisi' },
  { code: 'Caucasus Standard Time', name: '(UTC+04:00) Yerevan' },
  { code: 'Afghanistan Standard Time', name: '(UTC+04:30) Kabul' },
  { code: 'West Asia Standard Time', name: '(UTC+05:00) Ashgabat, Tashkent' },
  { code: 'Pakistan Standard Time', name: '(UTC+05:00) Islamabad, Karachi' },
  { code: 'India Standard Time', name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
  { code: 'Sri Lanka Standard Time', name: '(UTC+05:30) Sri Jayawardenepura' },
  { code: 'Nepal Standard Time', name: '(UTC+05:45) Kathmandu' },
  { code: 'Central Asia Standard Time', name: '(UTC+06:00) Astana' },
  { code: 'Bangladesh Standard Time', name: '(UTC+06:00) Dhaka' },
  { code: 'Ekaterinburg Standard Time', name: '(UTC+06:00) Ekaterinburg' },
  { code: 'Myanmar Standard Time', name: '(UTC+06:30) Yangon (Rangoon)' },
  { code: 'SE Asia Standard Time', name: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
  { code: 'N. Central Asia Standard Time', name: '(UTC+07:00) Novosibirsk' },
  { code: 'China Standard Time', name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
  { code: 'North Asia Standard Time', name: '(UTC+08:00) Krasnoyarsk' },
  { code: 'Singapore Standard Time', name: '(UTC+08:00) Kuala Lumpur, Singapore' },
  { code: 'W. Australia Standard Time', name: '(UTC+08:00) Perth' },
  { code: 'Taipei Standard Time', name: '(UTC+08:00) Taipei' },
  { code: 'Ulaanbaatar Standard Time', name: '(UTC+08:00) Ulaanbaatar' },
  { code: 'North Asia East Standard Time', name: '(UTC+09:00) Irkutsk' },
  { code: 'Tokyo Standard Time', name: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
  { code: 'Korea Standard Time', name: '(UTC+09:00) Seoul' },
  { code: 'Cen. Australia Standard Time', name: '(UTC+09:30) Adelaide' },
  { code: 'AUS Central Standard Time', name: '(UTC+09:30) Darwin' },
  { code: 'E. Australia Standard Time', name: '(UTC+10:00) Brisbane' },
  { code: 'AUS Eastern Standard Time', name: '(UTC+10:00) Canberra, Melbourne, Sydney' },
  { code: 'West Pacific Standard Time', name: '(UTC+10:00) Guam, Port Moresby' },
  { code: 'Tasmania Standard Time', name: '(UTC+10:00) Hobart' },
  { code: 'Yakutsk Standard Time', name: '(UTC+10:00) Yakutsk' },
  { code: 'Central Pacific Standard Time', name: '(UTC+11:00) Solomon Is., New Caledonia' },
  { code: 'Vladivostok Standard Time', name: '(UTC+11:00) Vladivostok' },
  { code: 'New Zealand Standard Time', name: '(UTC+12:00) Auckland, Wellington' },
  { code: 'UTC+12', name: '(UTC+12:00) Coordinated Universal Time+12' },
  { code: 'Fiji Standard Time', name: '(UTC+12:00) Fiji' },
  { code: 'Magadan Standard Time', name: '(UTC+12:00) Magadan' },
  { code: 'Kamchatka Standard Time', name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old' },
  { code: 'Tonga Standard Time', name: "(UTC+13:00) Nuku'alofa" },
  { code: 'Samoa Standard Time', name: '(UTC+13:00) Samoa' },
  { code: 'Line Islands Standard Time', name: '(UTC+14:00) Kiritimati Island' },
];

export const QrisStatus = [
  { id: '0', name: 'No. Provider is eligible but does not participate in QRIS.' },
  { id: '1', name: 'Yes. Provider does participate in a QRIS.' },
  {
    id: '7',
    name: "The State has an operating QRIS in the provider's area, but the provider is not eligible to participate.",
  },
  { id: '8', name: "The State does not have an operating QRIS in the provider's area." },
];
export const AccreditationStatus = [
  { id: '0', name: 'No' },
  { id: '1', name: 'Yes. National Accreditation' },
  { id: '2', name: 'Yes. State Accreditation' },
  { id: '3', name: 'Yes. Other Accreditation (not National or State Level)' },
  { id: '4', name: 'Yes. Level/Type of Accreditation Unavailable' },
  { id: '9', name: 'NA. Information Currently Unavailable.' },
];
export const CareTypes = [
  { id: '2', key: 2, name: 'Licensed family child care home' },
  { id: '3', key: 3, name: 'Licensed large family child care home' },
  { id: '4', key: 4, name: 'Licensed center-based care' },
  // { id: "5", name: "License-exempt in child’s home by a relative" },
  // { id: "6", name: "License-exempt in child’s home by a nonrelative" },
  // { id: "7", name: "License-exempt outside the child’s home by a relative" },
  // { id: "8", name: "License-exempt outside the child’s home by a nonrelative" },
  { id: '11', key: 11, name: 'License-exempt center-based care' },
];
export const NOAActions = [
  { id: '1', name: 'Start of Service' },
  { id: '2', name: 'Service Denied' },
  { id: '4', name: 'Change in Service' },
  { id: '8', name: 'Termination of Service' },
  { id: '16', name: 'Termination of Service for Delinquent Fees' },
];

export const IncomeTypes = [
  // Countable, Not Countable, Deductable
  { id: '1', name: 'COUNTABLE' },
  { id: '2', name: 'NOT_COUNTABLE' },
  { id: '3', name: 'DEDUCTABLE' },
];

export const DisableOption = [
  { id: false, name: 'Active' },
  { id: true, name: 'Inactive' },
];

export const BooleanOption = [
  { id: 1, name: 'Yes', title: 'Active' },
  { id: 0, name: 'No', title: 'Inactive' },
];

export const Months = [
  { id: 1, text: 'January', abb: 'Jan' },
  { id: 2, text: 'February', abb: 'Feb' },
  { id: 3, text: 'March', abb: 'Mar' },
  { id: 4, text: 'April', abb: 'Apr' },
  { id: 5, text: 'May', abb: 'May' },
  { id: 6, text: 'June', abb: 'Jun' },
  { id: 7, text: 'July', abb: 'Jul' },
  { id: 8, text: 'August', abb: 'Aug' },
  { id: 9, text: 'September', abb: 'Sep' },
  { id: 10, text: 'October', abb: 'Oct' },
  { id: 11, text: 'November', abb: 'Nov' },
  { id: 12, text: 'December', abb: 'Dec' },
];

export const WeekDays = [
  { id: 0, text: 'Sunday', abb: 'Sun' },
  { id: 1, text: 'Monday', abb: 'Mon' },
  { id: 2, text: 'Tuesday', abb: 'Tue' },
  { id: 3, text: 'Wednesday', abb: 'Wed' },
  { id: 4, text: 'Thursday', abb: 'Thu' },
  { id: 5, text: 'Friday', abb: 'Fri' },
  { id: 6, text: 'Saturday', abb: 'Sat' },
];

export const wkCodeList = ['stanford', 'stanfordtest', 'cwademo'];

export const sfWkCodeList = ['stanford', 'stanfordtest'];
