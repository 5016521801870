<div class="password-strength-widget dx-form-dark" style="padding-top: 0px !important">
  <div>
    <!-- <div class="password-requirement-title">
          {{ (('PASSWORD_SECURITY_REQUIREMENT' | translate) || 'Password security requirement:') }}
      </div> -->
    <ul>
      <li
        *ngIf="!checkPassword.rules?.hasNumber"
        [ngClass]="{ invalid: !checkPassword.rules?.hasNumber }"
      >
        <span> {{ ('AT_LEAST_ONE_NUMBER' | translate) || 'At least one number (0-9)' }}</span>
      </li>
      <li
        *ngIf="!checkPassword.rules?.hasLetter"
        [ngClass]="{ invalid: !checkPassword.rules?.hasLetter }"
      >
        <span>
          {{
            ('AT_LEAST_ONE_LOWERCASE_LETTER' | translate) || 'At least one lowercase letter (a-z)'
          }}</span
        >
      </li>
      <li
        *ngIf="!checkPassword.rules?.hasUpperLetter"
        [ngClass]="{ invalid: !checkPassword.rules?.hasUpperLetter }"
      >
        <span>
          {{
            ('AT_LEAST_ONE_UPPERCASE_LETTER' | translate) || 'At least one uppercase letter (A-Z)'
          }}</span
        >
      </li>
      <li
        *ngIf="!checkPassword.rules?.hasSymbal"
        [ngClass]="{ invalid: !checkPassword.rules?.hasSymbal }"
      >
        <span>
          {{
            ('AT_LEAST_ONE_SPECIAL_CHARACTER' | translate) || 'At least one special character'
          }}</span
        >
      </li>
      <li
        *ngIf="!checkPassword.rules?.isValidLength"
        [ngClass]="{ invalid: !checkPassword.rules?.isValidLength }"
      >
        <span> {{ ('AT_LEAST_CHARACTERS' | translate) || 'At least 8 characters' }}</span>
      </li>
      <li
        *ngIf="!checkPassword.rules?.isOverLength"
        [ngClass]="{ invalid: !checkPassword.rules?.isOverLength }"
      >
        <span> {{ ('AT_MOST_CHARACTERS' | translate) || 'At most 16 characters' }}</span>
      </li>
    </ul>
  </div>
</div>
