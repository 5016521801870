import { Injectable } from '@angular/core';
import { JsonClientFactoryService } from '../carecloud/JsonClientFactory.service';
import {
  GetEntityTypeByCodeRequest,
  GetUsedParentEntityTypeByEntityTypeCodeRequest,
} from '../models/entity.api';
import { GetDictionaryByCodeRequest } from '../models/dictionary.api';
import {
  EntityType,
  EntityField,
  DataType,
  EditorType,
  FieldType,
  DataSourceType,
} from '../models/datamodel/entityType';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
// import { GetContractByCodeRequest, GetContractByIdRequest } from '../models/servicemodel/contract.api';
import { LogService } from '../log/log.service';
import { ConvertService } from '../../shared/utils/convert.service';
import { EntityCacheService } from './cacheService';

export class GridOptions {
  IsDisable: boolean = false;
  IsExport: boolean = false;
  IsColumnChooser: boolean = false;
  IsAdvance: boolean = false;
  IsQuery: boolean = false;
}

export class WksEntity {
  Title: string;
  EntityType: EntityType;
  EntityFields: EntityField[];
  Fields: EntityField[];
  FieldMap: { [name: string]: EntityField };
  Columns?: any[];
  FormItems?: any[];
  FormOptions?: any;
  TitleOptions?: any;
  GridOptions?: GridOptions;
  QueryFormItems?: any[] = [];
  DefaultModel?: any;
}

export class WksContract {
  ServiceEntityType: WksEntity;
  Id: number;
  Code: string;
  Name: string;
  Meta: any;
  public get EntityCode() {
    return 'ct.service.' + this.Code;
  }
}

@Injectable()
export class EntityService {
  private entityMap: { [name: string]: WksEntity } = {};
  private dictMap: { [name: string]: any } = {};
  private contractMap: { [name: string]: WksContract } = {};

  constructor(
    private cacheService: EntityCacheService,
    private factory: JsonClientFactoryService,
    // private reportService: ReportService,
    private convert: ConvertService,
    private logger: LogService,
  ) {}

  setDefaultGridOption(data) {
    if (!(data && data.GridOptions)) {
      data.GridOptions = {};
    }
    const opt = data.GridOptions;
    opt.IsExport = opt.IsExport ? opt.IsExport : false;
    opt.IsColumnChooser = opt.IsColumnChooser ? opt.IsColumnChooser : false;
    opt.IsAdvance = opt.IsAdvance ? opt.IsAdvance : false;
    opt.IsDisable = opt.IsDisable ? opt.IsDisable : false;
    opt.IsQuery = opt.IsQuery ? opt.IsQuery : false;
    return opt;
  }

  ///optionParmDic 为aiyong修改 Vacation 去除过滤 (GetDictionaryByCodeRequest 添加了新的参数 EntityCode, EntityId, ParentEntityDataId)  @ 10/14/2020
  public async getEntity(entityCode: string, refresh = false, optionParmDic: any = null) {
    let entity = this.entityMap[entityCode];
    if (!entity || refresh) {
      entity = await this.getRemoteEntityType(entityCode);
      // if (entityCode === 'cw_app_child_provider_forsite') {
      //     debugger;
      // }
      if (entity) {
        entity.Columns = this.getDataGridColumns(
          entity.EntityFields.filter(f => f.Disable == false && f.Apply == 0),
          optionParmDic,
        );
        entity.FormOptions = entity.EntityType.DefaultFormOptions
          ? entity.EntityType.DefaultFormOptions
          : { labelLocation: 'top', colCount: 3 };
        entity.FormItems = this.getDxFormItems(
          entity.EntityFields.filter(f => f.Disable == false && f.Apply == 0),
          entity,
          optionParmDic,
        );
        entity.TitleOptions = this.getTitleItems(
          entity.EntityFields.filter(f => f.Disable == false && f.TitleOptions),
          entity,
        );
        entity.GridOptions = this.setDefaultGridOption(entity.EntityType);
        // if (entity.GridOptions.IsQuery) { }
        const queryFormItems = entity.EntityFields.filter(f => f.Disable == false && f.Apply == 1);
        entity.QueryFormItems = this.getDxQueryFormItems(queryFormItems, 1);
        entity.DefaultModel = this.getDefaultModel(
          entity.EntityFields.filter(f => f.Disable == false && f.Apply == 1),
        );

        // console.log('====entity.QueryFormItems：', entity.QueryFormItems);

        // report.Columns = this.entityService.getDataGridColumns(report.EntityFields.filter(f => f.Disable == false && f.Apply == 0), null, isCacheSchedule, report);
        // report.ColumnFormItems = this.getDxFormItems(report.EntityFields.filter(f => f.Disable == false && f.Apply == 0), 0);
        // report.FormOptions = report.EntityType ? (report.EntityType.DefaultFormOptions ? report.EntityType.DefaultFormOptions
        //     : { labelLocation: 'top', colCount: 4 }) : {};
        // report.FormItems = this.getDxFormItems(report.EntityFields.filter(f => f.Disable == false && f.Apply == 1), 1);
        // report.DefaultModel = this.getDefaultModel(report.EntityFields.filter(f => f.Disable == false && f.Apply == 1));
      } else {
        // const contact = await this.getContract(entityCode);
        // if (contact) {
        //     entity = contact.ServiceEntityType;
        // } else {
        //     entity = new WksEntity();
        // }
        entity = new WksEntity();
      }
      // this.entityMap[entityCode] = entity;
    }
    return entity;
  }

  public getDefaultModel(fields: EntityField[]) {
    const items = {};
    for (const item of fields) {
      const formitem: any = this.convertQueryFormJsonType(item.QueryOptions);
      const key = item.FieldName;
      let op = key + this.getOp(item.QueryOptions.Operator);
      if (formitem && formitem.SpecialConditions == 'As of Date') {
        op = 'Report_As_of_Date';
      }
      const dataField = op;
      if (formitem.DefaultValue || formitem.DefaultValue == 0) {
        if (formitem.DefaultValue == '@Today') {
          items[dataField] = new Date();
        } else if (formitem.DefaultValue == '@Today+1') {
          items[dataField] = moment(new Date()).add(1, 'day');
        } else if (formitem.DefaultValue == '@1st day of current month') {
          items[dataField] = moment(new Date()).startOf('month');
        } else if (formitem.DefaultValue == '@Last day of current month') {
          items[dataField] = moment(new Date()).endOf('month');
        } else if (formitem.DefaultValue == '@Me') {
          items[dataField] = this.factory.currentUser.UserId;
        } else {
          // items[item.BindFieldName] = item.DefaultValue;
          if (
            typeof formitem.DefaultValue != 'object' &&
            EditorType[item.EditorType] == EditorType.TagBox
          ) {
            const defaultvalue = [];
            defaultvalue.push(formitem.DefaultValue.toString());
            items[dataField] = defaultvalue;
          } else {
            items[dataField] = formitem.DefaultValue;
          }

          if (EditorType[item.EditorType] == EditorType.SelectBox) {
            items[dataField] = formitem.DefaultValue.toString();
          }
        }
      }

      if (EditorType[item.EditorType] == EditorType.CheckBox) {
        if (!formitem.DefaultValue) {
          items[dataField] = false;
        }
      }
    }

    return items;
  }

  private getDxQueryFormItems(fields: EntityField[], type) {
    const items = [];
    for (const item of fields) {
      const formitem: any = this.buildQueryFormItem(item, type);
      items.push(formitem);
    }
    return items;
  }

  private buildQueryFormItem(item: EntityField, type) {
    const vm = this;
    this.convertQueryFormJsonType(item.QueryOptions);
    const formitem: any = {};
    formitem.entityField = item;
    const itemQueryOption = item.QueryOptions ? item.QueryOptions : {};
    // todo tabbed
    if (type == 1) {
      const key = item.FieldName;
      let op = key + this.getOp(item.QueryOptions.Operator);
      if (itemQueryOption && itemQueryOption.SpecialConditions == 'As of Date') {
        op = 'Report_As_of_Date';
      }
      formitem.dataField = op;
      // formitem.dataField = item.FieldName;
    } else {
      formitem.dataField = item.FieldName;
    }
    formitem.helpText = item.Description;
    formitem.editorType = this.getEditorType(EditorType[item.EditorType]);
    formitem.label = formitem.label || {};
    // if (item && item.QueryOptions) {
    //     const operatorValue = item.QueryOptions.Operator ? " (" + item.QueryOptions.Operator + ")" : ''
    //     formitem.label.text = item.Name + operatorValue;
    // } else {
    //     formitem.label.text = item.Name;
    // }
    formitem.label.text = item.Name;
    formitem.label.location = itemQueryOption.labelLocation
      ? itemQueryOption.labelLocation
      : undefined;
    formitem.editorOptions = this.getEditorOptions(item.EditorOptions);
    formitem.visibleIndex = item.VisibleIndex;
    formitem.colSpan = itemQueryOption.colSpan;
    if (typeof itemQueryOption.visible == 'undefined') {
      formitem.visible = false;
    } else {
      formitem.visible = itemQueryOption.visible;
    }

    if (itemQueryOption.Format) {
      formitem.editorOptions['format'] = itemQueryOption.Format;
    }

    if (itemQueryOption.searchEnabled) {
      formitem.editorOptions['searchEnabled'] = itemQueryOption.searchEnabled;
    }

    if (EditorType[item.EditorType] == EditorType.TagBox) {
      formitem.editorOptions.showDropDownButton = true;
      formitem.editorOptions.showSelectionControls = true;
      formitem.editorOptions.showClearButton = true;
      if (item.DataSourceType == DataSourceType.None) {
        formitem.editorOptions.acceptCustomValue = true;
      }
    }
    if (
      EditorType[item.EditorType] == EditorType.SelectBox ||
      EditorType[item.EditorType] == EditorType.TagBox ||
      EditorType[item.EditorType] == EditorType.RadioBox
    ) {
      if (item.DataSourceType == DataSourceType.Dictionary) {
        // dict
        // this.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
        formitem.editorOptions.displayExpr = 'Name';
        formitem.editorOptions.valueExpr = 'Code';

        formitem.editorOptions.dataSource = {
          store: new CustomStore({
            key: 'Code',
            loadMode: 'raw',
            load: loadOptions => {
              return vm.getDict(item.DataSource, item.EditorType);
            },
            // byKey(id) {
            //     return vm.getOrganizationById(id);
            // },
          }),
          sort: 'Order',
        };
      } else if (item.DataSourceType == DataSourceType.Array) {
        // array
        formitem.editorOptions.items = item.DataSource.split(';').map(x => x.trim());
      }
    }
    return formitem;
  }

  private convertQueryFormJsonType(col: any) {
    if (!col) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in col) {
      const val = col[att];
      if (val && !(val instanceof Array)) {
        if (val === 'true' || val === 'True') {
          col[att] = true;
        } else if (val === 'false' || val === 'False') {
          col[att] = false;
        } else if (!isNaN(Number(val))) {
          col[att] = Number(val);
        }
      }
    }
    return col;
  }

  public async getContract(code, refresh = false) {
    let contractCode = code;
    if (!code.startsWith('ct.service')) {
      contractCode = 'ct.service.' + code;
    }
    let contract = this.contractMap[contractCode];
    if (!contract || refresh) {
      let con = null;
      if (isNaN(Number(code))) {
        con = await this.getRemoteContractByCode(code);
      } else {
        con = await this.getRemoteContractById(code);
      }
      if (!con) {
        throw new Error(contractCode + ' is not correct contract code or id');
      }
      contract = new WksContract();
      contract.Id = con.Id;
      contract.Code = con.Code;
      contract.Name = con.Name;
      contract.Meta = {};
      if (con.Meta && con.Meta.length > 0) {
        const metas = JSON.parse(con.Meta);
        for (const item of metas) {
          contract.Meta[item.Key] = item.Value;
        }
      }
      contract.ServiceEntityType = await this.getEntity(contract.EntityCode);
      this.contractMap[contract.EntityCode] = contract;
      this.contractMap['ct.service.' + contract.Id] = contract;
    }
    return contract;
  }

  public async getDict(
    code: string,
    editorType: string = '',
    optionParmDic: any = null,
    isCacheSchedule: any = false,
  ) {
    let dict = this.dictMap[code];
    if (!dict) {
      if (code === 'ct.sys.organization') {
        dict = await this.getOrganizations();
      } else {
        dict = await this.searchDictDataByCode(code, optionParmDic, isCacheSchedule);
      }
      const c = code.toLowerCase();
      if (c === 'classroom' || (c === 'care schedule' && !isCacheSchedule)) {
        // for vacation 过滤classroom, schedule 缓存 @10/14/2020
      } else {
        this.dictMap[code] = dict;
      }
    }

    let dictionary = [];
    let isHasEmpty = false;
    for (const d in dict) {
      if (dict[d].Code == '') {
        isHasEmpty = true;
        break;
      }
    }
    // InternationalTelephoneCode 电话号码的国籍码，去除下拉框空选项
    if (
      !isHasEmpty &&
      EditorType[editorType] == EditorType.SelectBox &&
      code !== 'InternationalTelephoneCode'
    ) {
      dictionary = [{ Code: '', Name: '', Order: 0 }].concat(dict);
    } else {
      dictionary = dict;
    }

    return dictionary;
  }

  public async getColumns(entityCode: string) {
    const entity = await this.getEntity(entityCode);
    if (entity) {
      return entity.Columns;
    }
    return null;
  }

  public async getFormItems(entityCode: string) {
    const entity = await this.getEntity(entityCode);
    if (entity) {
      return entity.FormItems;
    }
    return null;
  }

  private async getRemoteEntityType(entityCode) {
    const response = await this.getRemoteEntityTypeData(entityCode);
    if (response.IsSuccess) {
      const wkentity = new WksEntity();
      wkentity.EntityType = response.EntityType;
      wkentity.EntityFields = response.Fields;
      wkentity.Fields = wkentity.EntityFields.filter(f => f.BindFieldName && f.Disable == false);
      wkentity.FieldMap = wkentity.Fields.reduce((map, f) => ((map[f.BindFieldName] = f), map), {});
      return wkentity;
    } else {
      return null;
    }
  }

  private async getRemoteEntityTypeData(entityCode) {
    let cacheKey = 'GetEntityTypeByCodeRequest_' + entityCode;
    let cacheData = this.cacheService.getCacheData(cacheKey);
    if (!cacheData) {
      const client = this.factory.MctCareWaitClient;
      const postData = new GetEntityTypeByCodeRequest();
      postData.EntityTypeCode = entityCode;
      const response = await client.get(postData);
      this.cacheService.setCacheData(cacheKey, response);
      return response;
    }
    return cacheData;
  }

  private async getRemoteContractByCode(code: string) {
    // if (code.startsWith('ct.service')) {
    //     code = code.replace('ct.service.', '');
    // }
    // const client = this.factory.CareTrackClient;
    // const postData = new GetContractByCodeRequest();
    // postData.Code = code;
    // const response = await client.get(postData);
    // if (response.IsSuccess) {
    //     return response.Data;
    // } else {
    //     return null;
    // }
  }

  private async getRemoteContractById(id: number) {
    // const client = this.factory.CareTrackClient;
    // const postData = new GetContractByIdRequest();
    // postData.Id = id;
    // const response = await client.get(postData);
    // if (response.IsSuccess) {
    //     return response.Data;
    // } else {
    //     return null;
    // }
  }

  public getDataGridColumns(
    fields: EntityField[],
    optionParmDic: any = null,
    isCacheSchedule: any = false,
    entity: any = null,
  ) {
    const cols = [];
    for (const item of fields) {
      cols.push(this.buildColumn(item, optionParmDic, isCacheSchedule, entity));
    }
    return cols;
  }

  private buildColumn(
    item: EntityField,
    optionParmDic: any = null,
    isCacheSchedule: any = false,
    entity: any = null,
  ) {
    const vm = this;
    const col: any = this.convertJsonType(item.GridColumnsOptions);
    col.dataField = item.FieldName;
    col.caption = col.caption ? col.caption : item.Name;
    col.dataType = this.getDataType(DataType[item.DataType]);
    col.format = col.format || item.Format;
    if (col.linkUrl && col.linkUrl.length > 1) {
      col.cellTemplate = 'urlDetail';
    }
    if (col.isRowNumber) {
      col.cellTemplate = 'rowNumberDetail';
      col.allowExporting = false;
    }
    if (typeof col.visible == 'undefined') {
      col.visible = false;
    }

    //pivot grid 如果是Dictionary，或Entity
    if (entity && entity.EntityType.ReportType == 2) {
      if (
        item.DataSourceType == DataSourceType.Dictionary ||
        item.DataSourceType == DataSourceType.Entity
      )
        col.dataType = 'string';
    }

    // DataType
    if (DataType[item.DataType] === DataType.Image) {
      col.cellTemplate = 'image';
    }
    if (
      DataType[item.DataType] === DataType.Date ||
      DataType[item.DataType] === DataType.DateTime
    ) {
      // col.allowHeaderFiltering = false;
      if (col.filterValue) {
        if (Array.isArray(col.filterValue)) {
          const filterlist = [];
          col.filterValue.forEach(itemfilter => {
            filterlist.push(new Date(itemfilter));
          });
          col.filterValue = filterlist;
        } else {
          col.filterValue = new Date(col.filterValue);
        }
      }
    }
    // DataSource
    if (DataType[item.DataType] === DataType.Array) {
      col.calculateDisplayValue = data => {
        return data[item.FieldName] ? data[item.FieldName].replace('[', '').replace(']', '') : '';
      };
    } else {
      if (item.DataSourceType == DataSourceType.Dictionary) {
        // dict
        if (EditorType[item.EditorType] != EditorType.TagBox) {
          col.lookup = {};
          col.lookup.dataSource = {
            store: new CustomStore({
              key: 'Code',
              loadMode: 'raw',
              load: () => {
                return this.getDict(item.DataSource, '', optionParmDic, isCacheSchedule);
              },
            }),
            sort: 'Order',
          };
          col.lookup.displayExpr = 'Name';
          col.lookup.valueExpr = 'Code';
        }
      } else if (item.DataSourceType == DataSourceType.Array) {
        // array
      } else if (item.DataSourceType == DataSourceType.Entity) {
        // entity
        col.lookup = {};
        // col.lookup.entityField = item;
        if (item.DataSource && item.DataSource.toLowerCase() == 'ct.sys.organization') {
          // col.lookup.showEditorAlways = true;
          col.lookup.dataSource = {
            store: new CustomStore({
              key: 'Id',
              loadMode: 'raw',
              load: loadOptions => {
                return vm.getDict('ct.sys.organization').then(dicts => {
                  if (loadOptions.requireTotalCount === true) {
                    return { data: dicts, totalCount: dicts.length };
                  } else {
                    return dicts;
                  }
                });
              },
              // byKey(id) {
              //     return vm.getOrganizationById(id);
              // },
            }),
            sort: 'Code',
          };
          col.lookup.displayExpr = 'Code';
          col.lookup.valueExpr = 'Id';
        }
      }
    }
    return col;
  }

  public getDxFormItems(
    fields: EntityField[],
    entity: WksEntity,
    optionParmDic: any = null,
    type = 0,
  ) {
    const items = [];
    for (const item of fields) {
      const formitem: any = this.buildFormItem(item, entity, optionParmDic, type);
      items.push(formitem);
    }
    return items;
  }

  // Copy from reportService
  private getOp(opchar) {
    if (!opchar) {
      return '';
    }
    switch (opchar) {
      case '=': {
        return '';
      }
      case 'StartsWith': {
        return 'StartsWith';
      }
      case 'EndsWith': {
        return 'EndsWith';
      }
      case '!=': {
        return '!';
      }
      case '>': {
        return 'GreaterThan';
      }
      case '>=': {
        return 'GreaterThanOrEqualTo';
      }
      case '<': {
        return 'LessThan';
      }
      case '<=': {
        return 'LessThanOrEqualTo';
      }
      case 'In': {
        return 'In';
      }
      case 'Contains': {
        return 'Contains';
      }
      case 'IsNull': {
        return 'IsNull';
      }
      case 'IsNotNull': {
        return 'IsNotNull';
      }
      default:
        return '';
    }
  }

  private buildFormItem(item: EntityField, entity: WksEntity, optionParmDic: any = null, type = 0) {
    const vm = this;
    const formitem: any = this.convertJsonType(item.FormItemOptions);
    formitem.entityField = item;
    // todo tabbed
    const itemType = this.getFormItemType(item.FieldType);
    formitem.itemType = itemType;

    this.convertJsonType(item.QueryOptions);
    // const formitem: any = {};
    formitem.entityField = item;
    const itemQueryOption = item.QueryOptions ? item.QueryOptions : {};

    // to add entity query form
    if (type == 1) {
      // const reportService = new ReportService();
      const key = item.FieldName;
      let op = key + this.getOp(item.QueryOptions.Operator);
      if (itemQueryOption && itemQueryOption.SpecialConditions == 'As of Date') {
        op = 'Report_As_of_Date';
      }
      formitem.dataField = op;
    } else {
      formitem.dataField = item.BindFieldName;
    }
    // to add entity query form
    if (itemType == 'simple') {
      // if (item.BindFieldName && !formitem.dataField) {
      //     formitem.dataField = item.BindFieldName;
      // }
      formitem.isRequired = item.IsRequired;
      formitem.helpText = item.Description;
      formitem.label = formitem.label || {};
      formitem.label.text = formitem.caption ? formitem.caption : item.Name;
      formitem.label.location = formitem.labelLocation ? formitem.labelLocation : undefined;
      formitem.label.visible = formitem.hideCaption ? false : true;
      const etype = EditorType[item.EditorType];
      formitem.editorType = this.getEditorType(etype);
      formitem.editorOptions = this.getEditorOptions(item.EditorOptions);
      formitem.visibleIndex = item.VisibleIndex;
      if (item.IsAutoValue) {
        formitem.editorOptions.readOnly = true;
        if (type == 1) {
          formitem.editorOptions.readOnly = false; // fix autovalue to entity query not editor
        }
      }
      if (typeof formitem.visible == 'undefined') {
        formitem.visible = false;
      }
      if (item.QueryOptions && type == 1) {
        formitem.visible = itemQueryOption.visible;
      }

      if (EditorType[item.EditorType] == EditorType.RadioBox) {
        if (!formitem.editorOptions['layout']) {
          formitem.editorOptions['layout'] = 'horizontal';
        }
      }

      if (item.DefaultValue) {
        formitem.editorOptions['value'] = item.DefaultValue;
      }

      if (EditorType[item.EditorType] == EditorType.SelectBox && !item.DefaultValue) {
        formitem.editorOptions['value'] = '';
      }

      if (EditorType[item.EditorType] == EditorType.TagBox) {
        formitem.editorOptions.showDropDownButton = true;
        formitem.editorOptions.showSelectionControls = true;
        formitem.editorOptions.showClearButton = true;
        if (item.DataSourceType == DataSourceType.None) {
          formitem.editorOptions.acceptCustomValue = true;
        }
      }

      if (
        EditorType[item.EditorType] == EditorType.SelectBox ||
        EditorType[item.EditorType] == EditorType.TagBox ||
        EditorType[item.EditorType] == EditorType.RadioBox
      ) {
        if (item.DataSourceType == DataSourceType.Dictionary) {
          // dict
          // this.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
          formitem.editorOptions.displayExpr = 'Name';
          formitem.editorOptions.valueExpr = 'Code';
          formitem.editorOptions.dataSource = {
            store: new CustomStore({
              key: 'Code',
              loadMode: 'raw',
              load: loadOptions => {
                return vm.getDict(item.DataSource, item.EditorType, optionParmDic);
              },
              // byKey(id) {
              //     return vm.getOrganizationById(id);
              // },
            }),
            sort: 'Order',
          };
        } else if (item.DataSourceType == DataSourceType.Array) {
          // array
          formitem.editorOptions.items = item.DataSource.split(';').map(x => x.trim());
        } else if (item.DataSourceType == DataSourceType.Entity) {
          // entity
          if (item.DataSource && item.DataSource.toLowerCase() == 'ct.sys.organization') {
            formitem.editorOptions.displayExpr = 'Code';
            formitem.editorOptions.valueExpr = 'Id';
            // vm.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
            // formitem.editorOptions.items = [{Id: '5', Code: 'Code'}];
            formitem.editorOptions.dataSource = {
              store: new CustomStore({
                key: 'Id',
                loadMode: 'raw',
                load: loadOptions => {
                  return vm.getDict('ct.sys.organization').then(dicts => {
                    if (loadOptions.requireTotalCount === true) {
                      return { data: dicts, totalCount: dicts.length };
                    } else {
                      return dicts;
                    }
                  });
                },
                // byKey(id) {
                //     return vm.getOrganizationById(id);
                // },
              }),
              sort: 'Code',
            };
            // this.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
          }
        }
      }
    } else if (itemType == 'group') {
      formitem.caption = formitem.hideCaption ? null : formitem.caption || item.Name;
      const items = entity.EntityFields.filter(f => f.ParentFieldId == item.Id);
      formitem.items = this.getDxFormItems(items, entity);
      if (formitem.labelLocation) {
        formitem.items.forEach(ci => {
          if (!ci.label || !ci.label.location) {
            ci.label.location = formitem.labelLocation;
          }
        });
      }
    } else if (itemType == 'empty') {
      formitem.visibleIndex = item.VisibleIndex;
    }
    return formitem;
  }

  public getTitleItems(fields: EntityField[], entity: WksEntity) {
    const items = [];
    for (const item of fields) {
      const titleitem: any = this.buildTitleItem(item, entity);
      items.push(titleitem);
    }
    return items;
  }

  private buildTitleItem(item: EntityField, entity: WksEntity) {
    const vm = this;
    const titleitem: any = this.convertJsonType(item.TitleOptions);
    titleitem.entityField = item;
    // todo tabbed

    titleitem.dataField = item.BindFieldName;
    titleitem.Name = titleitem.caption ? titleitem.caption : item.Name;
    titleitem.editorType = this.getEditorType(EditorType[item.EditorType]);
    titleitem.NameVisible = titleitem.hideCaption ? false : true;
    titleitem.visibleIndex = titleitem.visibleIndex ? titleitem.visibleIndex : 0;
    titleitem.hasdic =
      item.DataSourceType && item.DataSourceType != 0 && item.DataSource ? true : false;
    if (typeof titleitem.visible == 'undefined') {
      titleitem.visible = false;
    }

    return titleitem;
  }

  private getDataType(type: DataType) {
    switch (type) {
      case DataType.Boolean:
        return 'boolean';
      case DataType.Date:
        return 'date';
      case DataType.DateTime:
        return 'datetime';
      case DataType.Int:
        return 'number';
      case DataType.Number:
        return 'number';
      default:
        return 'string';
    }
  }

  private getEditorType(type: EditorType) {
    switch (type) {
      case EditorType.CheckBox:
        return 'dxCheckBox';
      case EditorType.DateTimeBox:
        return 'dxDateBox';
      case EditorType.DateBox:
        return 'dxDateBox';
      case EditorType.NumberBox:
        return 'dxNumberBox';
      case EditorType.RadioBox:
        return 'dxRadioGroup';
      case EditorType.SelectBox:
        return 'dxSelectBox';
      case EditorType.TagBox:
        return 'dxTagBox';
      case EditorType.TextArea:
        return 'dxTextArea';
      default:
        return 'dxTextBox';
    }
  }

  private getEditorOptions(options: any) {
    if (!options) {
      return {};
    }
    for (const key in options) {
      if (options[key] && options[key].indexOf && options[key].indexOf('{') == 0) {
        options[key] = JSON.parse(options[key]);
      }
    }
    return options;
  }

  private getFormItemType(type: FieldType) {
    switch (type) {
      case FieldType.Simple:
        return 'simple';
      case FieldType.Group:
        return 'group';
      case FieldType.Tabbed:
        return 'tabbed';
      case FieldType.Empty:
        return 'empty';
      case FieldType.Button:
        return 'button';
      default:
        return 'simple';
    }
  }

  private convertJsonType(col: any) {
    if (!col) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in col) {
      const val = col[att];
      if (val) {
        if (val === 'true' || val === 'True') {
          col[att] = true;
        } else if (val === 'false' || val === 'False') {
          col[att] = false;
        } else if (!isNaN(Number(val))) {
          col[att] = Number(val);
        }
      }
    }
    return col;
  }

  public convertFromEntityData(entityCode: string, entityData: any) {
    const entity = this.entityMap[entityCode];
    for (const field of entity.Fields) {
      if (DataType[field.DataType] === DataType.Boolean) {
        if (entityData[field.BindFieldName] === 'true') {
          entityData[field.BindFieldName] = true;
        } else {
          entityData[field.BindFieldName] = false;
        }
      }
    }
    return entityData;
  }

  public convertToEntityData(entityCode: string, entityData: any) {
    const entity = this.entityMap[entityCode];
    // tslint:disable-next-line:forin
    for (const att in entityData) {
      const field = entity.FieldMap[att];
      if (field && DataType[field.DataType] == DataType.Date) {
        const d = moment(entityData[att]);
        if (d.isValid) {
          entityData[att] = d.format('YYYY/MM/DD');
        } else {
          entityData[att] = null;
        }
      }
    }
    return entityData;
  }

  public getGridColumns(e) {
    return [];
  }

  public async getGetUsedParentEntityType(entityCode) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetUsedParentEntityTypeByEntityTypeCodeRequest();
    postData.EntityTypeCode = entityCode;
    const response = await client.get(postData);
    if (response.IsSuccess) {
      return response.Data;
    } else {
      return null;
    }
  }

  public getEntities() {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/entity/type/of/' + this.factory.currentWorkspaceId;
    client.get<any>(url).then(res => {
      return res.Data;
    });
  }

  public getOrganizations() {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/organization/all';
    return client.get<any>(url).then(res => {
      return res.Results;
    });
  }

  public getOrganizationById(id) {
    // const client = this.factory.MctCareWaitClient;
    // const postData = new GetOrganizationByIdRequest();
    // postData.Id = id;
    // return client.get(postData).then(res => {
    //     return res.Data;
    // });
  }

  public async searchDictDataByCode(
    code: string,
    optionParmDic: any = null,
    isCacheSchedule: any = false,
  ) {
    // const client = this.factory.MctCareWaitClient;
    // const postData = new GetDictionaryByCodeRequest();
    // postData.Code = code;
    // postData.IncludeDisable = false;
    // const response = await client.get(postData);
    const response = await this.searchDictDataByCodeData(code, optionParmDic, isCacheSchedule);
    if (response.Dictionary) {
      response.Dictionary.forEach(data => (data.disabled = data.Disable));
    }
    return response.Dictionary;
  }

  public async searchDictDataByCodeData(
    code: string,
    optionParmDic: any = null,
    isCacheSchedule: any = false,
  ) {
    const key = 'GetDictionaryByCodeRequest_' + code;
    const cacheData = this.cacheService.getCacheData(key);
    if (!cacheData) {
      const client = this.factory.MctCareWaitClient;
      const postData = new GetDictionaryByCodeRequest();
      postData.Code = code;
      postData.IncludeDisable = false;
      if (optionParmDic) {
        postData.EntityCode = optionParmDic.EntityCode ? optionParmDic.EntityCode : '';
        postData.EntityId = optionParmDic.EntityId ? optionParmDic.EntityId : '';
        postData.ParentEntityDataId = optionParmDic.ParentEntityDataId
          ? optionParmDic.ParentEntityDataId
          : '';
      }
      // console.log('==== GetDictionaryByCodeRequest add more parameters:', code, postData, optionParmDic);
      const response = await client.get(postData);
      if (response.Dictionary) {
        const c = code.toLowerCase();
        if (c === 'classroom' || (c === 'care schedule' && !isCacheSchedule)) {
          // for vacation 过滤classroom, schedule 缓存
          this.cacheService.setCacheData(key, '');
        } else {
          this.cacheService.setCacheData(key, response);
        }
      }
      return response;
    }
    return cacheData;
  }

  public async buildAllDicList(itemList, optionParmDic: any = null) {
    let allDicList = [];
    if (itemList) {
      for (const item in itemList) {
        const itemtype = itemList[item];
        if (
          itemtype.editorType == 'dxTagBox' &&
          itemtype.entityField.DataSourceType == DataSourceType.Dictionary
        ) {
          let isHasCode = false;
          for (const c in allDicList) {
            const columnDic = allDicList[c];
            if (columnDic.Code == itemtype.entityField.DataSource) {
              isHasCode = true;
              break;
            }
          }
          if (!isHasCode) {
            let getdic = await this.getDict(itemtype.entityField.DataSource, '', optionParmDic);
            let dic = { Code: itemtype.entityField.DataSource, DicList: getdic };
            allDicList.push(dic);
          }
        }
      }
    }
    return allDicList;
  }

  async searchUsers(options) {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/organization/member/all';
    const response = await client.get<any>(url);
    return response.Results;
  }

  async searchGroups(options) {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/groups/all';
    const response = await client.get<any>(url);
    return response.Results;
  }

  async searchTemplates(codes) {
    const client = this.factory.MctCareWaitClient;
    let url = '/core/reports/query?';
    url += 'CodeIn=' + codes;
    url += '&OrderBy=Name';
    const response = await client.get<any>(url);
    // return response.Data;
    return response.Results;
  }

  hideDisableColumn(gridColumns) {
    if (gridColumns && gridColumns.length > 0) {
      const disColumn = gridColumns.find(x => {
        return x.dataField && x.dataField.toLowerCase() === 'disable';
      });
      if (disColumn) {
        disColumn.visible = false;
      }
    }
  }
}
