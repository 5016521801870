import { Provider, EntityData_Provider } from './provider/ProviderInfo';
import { CreateOrUpdateEntityTableDataInfo } from './application/ApplicationDetailInfo';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse } from '../carecloud/common.api';
export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

//[Route("/provider", "POST")]
export class EntityDataResponse extends Response<{ [key: string]: any }> {
  Id: string;
}

export class GetProviderRequest extends CareWaitBaseRequest implements IReturn<EntityDataResponse> {
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetProviderRequest';
  }
}

export class PostProviderRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostProviderRequest';
  }
}

//[Route("/provider", "PUT")]
export class PutProviderRequest extends CareWaitBaseRequest implements IReturn<EntityDataResponse> {
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutProviderRequest';
  }
}

//[Route("/provider/{Id}", "DELETE")]
export class DeleteProviderRequest extends CareWaitBaseRequest implements IReturn<BaseResponse> {
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteProviderRequest';
  }
}

// [Route("/provider/restore/{Id}", "PUT")]
export class RestoreProviderRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreProviderRequest';
  }
}

//Vacancy

// [Route("/provider/vacancy/{Id}", "GET")]
export class GetProviderVacancyRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetProviderVacancyRequest';
  }
}

// [Route("/provider/vacancy", "POST")]
export class PostProviderVacancyRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostProviderVacancyRequest';
  }
}

// [Route("/provider/vacancy/{Id}", "DELETE")]
export class DeleteProviderVacancyRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteProviderVacancyRequest';
  }
}

// [Route("/provider/vacancy/restore/{Id}", "PUT")]
export class RestoreProviderVacancyRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreProviderVacancyRequest';
  }
}

// [Route("/provider/vacancy", "PUT")]
export class PutProviderVacancyRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutProviderVacancyRequest';
  }
}

//Vacancy Child
// [Route("/provider/vacancy/child/{Id}", "GET")]
export class GetProviderVacancyChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetProviderVacancyChildRequest';
  }
}

// [Route("/provider/vacancy/child", "POST")]
export class PostProviderVacancyChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostProviderVacancyChildRequest';
  }
}

// [Route("/provider/vacancy/child/{Id}", "DELETE")]
export class DeleteProviderVacancyChildRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteProviderVacancyChildRequest';
  }
}

// [Route("/provider/vacancy/child/restore/{Id}", "PUT")]
export class RestoreProviderVacancyChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreProviderVacancyChildRequest';
  }
}

// [Route("/provider/vacancy/child", "PUT")]
export class PutProviderVacancyChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutProviderVacancyChildRequest';
  }
}

// [Route("/application/child/provider", "POST")]
export class PostChildProviderRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostChildProviderRequest';
  }
}

// [Route("/application/child/provider", "PUT")]
export class PutChildProviderRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutChildProviderRequest';
  }
}

// [Route("/application/child/provider/{Id}", "DELETE")]
export class DeleteChildProviderRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: string;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteChildProviderRequest';
  }
}

export class ProviderPriorityItem {
  Id: string;
  ProviderId: number;
  Key: string;
  Name: string;
  Number: number | null;
}

// [Route("/provider/priority", "GET")]
export class GetProviderPriorityRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<ProviderPriorityItem[]>>
{
  ProviderId: number;
  createResponse() {
    return new Response<ProviderPriorityItem[]>();
  }
  getTypeName() {
    return 'GetProviderPriorityRequest';
  }
}

// [Route("/provider/priority", "POST")]
export class PostProviderPriorityRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Prioity: ProviderPriorityItem[];
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'PostProviderPriorityRequest';
  }
}

export class PushProviderVacancyChildResponse extends BaseResponse {
  Result: number; // 0: 推了小孩（PushCount为推的小孩数量） 1: Vacancy无效 2： Vacancy已满  3：没有合适的小孩
  PushCount: number;
}

// [Route("/provider/vacancy/pushchild/{Id}", "POST")]
export class PushProviderVacancyChildRequest
  extends CareWaitBaseRequest
  implements IReturn<PushProviderVacancyChildResponse>
{
  Id: string;
  createResponse() {
    return new PushProviderVacancyChildResponse();
  }
  getTypeName() {
    return 'PushProviderVacancyChildRequest';
  }
}

//#region 修改Offer Status API
export class StatusItem {
  Status: number;
  Name: string;
  ActionName: string;
}

// 在Offer Detail页面里，获取改状态
// [Route("/provider/vacancy/child/status/getworkflow/{Id}", "GET")]
export class GetProviderVacancyChildStatusWorkflowRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<StatusItem[]>>
{
  Id: string;
  createResponse() {
    return new Response<StatusItem[]>();
  }
  getTypeName() {
    return 'GetProviderVacancyChildStatusWorkflowRequest';
  }
  //UserType: number;//WLO: 1   Parent: 2
}

export class ProviderVacancyChildChangeToStatusItem {
  VacancyChildId: string;
  ChangeTo: StatusItem[];
}

// 在Offer List里获取所有的Offer能改的状态
// [Route("/provider/vacancy/child/status/getvacancyallreferralworkflow", "GET")]
export class GetVacancyAllProviderVacancyChildStatusWorkflowRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<ProviderVacancyChildChangeToStatusItem[]>>
{
  VacancyId: string;
  createResponse() {
    return new Response<ProviderVacancyChildChangeToStatusItem[]>();
  }
  getTypeName() {
    return 'GetVacancyAllProviderVacancyChildStatusWorkflowRequest';
  }
  //UserType: number;//WLO: 1   Parent: 2
}

// // [Route("/provider/vacancy/child/changestatus/{Id}", "PUT")]
// export class ChangeProviderVacancyChildStatusRequest extends CareWaitBaseRequest implements IReturn<BaseResponse>
// {
//     Id: string;
//     NewStatus: number;
//     Reason: string[];
//     createResponse() { return new BaseResponse(); }
//     getTypeName() { return 'ChangeProviderVacancyChildStatusRequest'; }
//     //OtherReason: string;
// }
//#endregion

//获取vacancy child数据, 增加附加列
// [Route("/provider/vacancy/child/forsite/{Id}", "GET")]
export class GetProviderVacancyChildForSiteRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetProviderVacancyChildForSiteRequest';
  }
}

// [Route("/provider/vacancy/child/forparent/{Id}", "GET")]
export class GetProviderVacancyChildForParentRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetProviderVacancyChildForParentRequest';
  }
}

// [Route("/provider/vacancy/child/changestatus/{Id}", "PUT")]
export class ChangeProviderVacancyChildStatusRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: string;
  NewStatus: number;
  Reason: string[];
  OtherReason: string;
  EnrollStartDate: string; //8位,yyyyMMdd
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'ChangeProviderVacancyChildStatusRequest';
  }
}

export class GetChildProviderRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetChildProviderRequest';
  }
}

// API：在Site的OfferDetail中获取Offer Entity信息时，增加字段"CanDo_PrintOfferPDF": true，用于决定是否要显示打印的按钮
// API：获取下载地址
// [Route("/provider/vacancy/child/download/offerpdf/{Id}", "GET")]
export class GetProviderVacancyChildDownloadOfferPDFUrlRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<string>>
{
  Id: string;
  createResponse() {
    return new Response<string>();
  }
  getTypeName() {
    return 'GetProviderVacancyChildDownloadOfferPDFUrlRequest';
  }
}

// [Route("/provider/vacancy/child/batchchangestatus", "PUT")]
export class BatchChangeProviderVacancyChildStatusRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Ids: string[];
  FromStatus: number;
  NewStatus: number;
  Reason: string[];
  OtherReason: string;
  EnrollStartDate: string; //8位,yyyyMMdd
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'BatchChangeProviderVacancyChildStatusRequest';
  }
}
