import { EntityType, EntityField } from './datamodel/entityType';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../carecloud/common.api';

import { SysMapping, SysMappingCategory } from './mapping.model';
import { EntityDataResponse } from './dictionary.api';
export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

// cw_sys_template
// [Route("/template/{Id}", "GET")]
export class GetTemplateRequest extends CareWaitBaseRequest implements IReturn<EntityDataResponse> {
  Id: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetTemplateRequest';
  }
}

// // [Route("/template/autoquery/and", "GET")]
// export class GetTemplateListByAutoQueryRequest extends QueryDb<TemplateListV2>
// {
//     IncludeDisable: boolean;
// }

// // [Route("/template/autoquery/or", "GET")]
// export class GetTemplateListByAutoQueryByOrRequest extends QueryDb<TemplateListV2>
// {
//     IncludeDisable: boolean;
// }

// [Route("/template", "POST")]
export class PostTemplateRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostTemplateRequest';
  }
}

// [Route("/template/{Id}", "DELETE")]
export class DeleteTemplateRequest extends CareWaitBaseRequest implements IReturn<BaseResponse> {
  Id: string;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'DeleteTemplateRequest';
  }
}

// [Route("/template/restore/{Id}", "PUT")]
export class RestoreTemplateRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: string;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreTemplateRequest';
  }
}

// [Route("/template", "PUT")]
export class PutTemplateRequest extends CareWaitBaseRequest implements IReturn<EntityDataResponse> {
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutTemplateRequest';
  }
}
