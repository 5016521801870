import { JsonServiceClient } from '@servicestack/client';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { AuthService } from '../auth/auth.service';
import { BaseRequest } from './common.api';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkspaceGuardService } from '../auth/workspace.guard.service';
import { AuthAppService } from '../auth/auth.app';
import { GetRequestCustomerIdByCustomerIdRequest } from '../models/waitlist.api';

const baseUrl = environment.apigateway_url;
const apikey = environment.apigateway_key ? environment.apigateway_key : '';

@Injectable()
export class JsonClientFactoryService {
  private accesscode;
  private appToken;
  public currentUser: User;
  public currentWorkspaceId: string;
  public currentRequestCustomerId: number;
  public workspaceId: string;

  public validatePassword: EventEmitter<any> = new EventEmitter<any>();

  // Care Cloud API clients
  public SubscriptionClient: JsonServiceClient;
  public AdminClient: JsonServiceClient;
  public BillingClient: JsonServiceClient;
  public MessageClient: JsonServiceClient;
  public SignInClient: JsonServiceClient;
  public AnonyClient_SendVerifyCode: JsonServiceClient;
  public AnonyClient_CheckVerifyCode: JsonServiceClient;
  public FileClient: JsonServiceClient;
  public MctCareWaitClient: JsonServiceClient;

  constructor(
    public auth: AuthService,
    public authApp: AuthAppService,
    private wks: WorkspaceGuardService,
    private http: HttpClient,
  ) {
    authApp.apiReady.subscribe(t => {
      this.appToken = t;
    });
    auth.currentUser.subscribe(u => {
      if (u) {
        this.currentUser = u;
        this.accesscode = u.accessCode; // this.auth.getToken();
        this.SubscriptionClient = this.getBillingApiClient();
        this.AdminClient = this.getAdminApiClient();
        this.BillingClient = this.getBillingApiClient();
        this.MessageClient = this.getMessageApiClient();
        this.SignInClient = this.getSignInApiClient();
        this.FileClient = this.getFileApiClient();
        this.MctCareWaitClient = this.getMctCarewaitV2ApiClient();
      }
    });

    wks.currentWorkspace.subscribe(w => {
      if (w) {
        this.currentWorkspaceId = w.Id;
        this.currentRequestCustomerId = w.CustomerId;
      }
    });
  }

  public getClient(module: string, refUrl?: string): JsonServiceClient {
    let url = baseUrl + module;
    if (!url.endsWith('/')) {
      url += '/';
    }
    if (refUrl) {
      url += refUrl;
    }
    const jc = new JsonServiceClient(url);
    jc.bearerToken = this.accesscode;
    jc.headers.append('X-CC-AppId', apikey);
    jc.headers.append('X-CC-AppToken', this.appToken);
    jc.requestFilter = req => {
      const body = req.body;
      if (body) {
        const dto = JSON.parse(body as string);
        if (dto) {
          if (this.currentUser) {
            dto.UserId = dto.UserId ? dto.UserId : this.currentUser.UserId;
            dto.CustomerId = dto.CustomerId ? dto.CustomerId : this.currentUser.CustomerId;
            if (dto.CustomerId === '${blank}') dto.CustomerId = null

            if (!dto.RequestWorkspaceId) {
              dto.RequestWorkspaceId = this.currentWorkspaceId ? this.currentWorkspaceId : '';
            }
            if (!dto.RequestCustomerId) {
              dto.RequestCustomerId = this.currentRequestCustomerId
                ? this.currentRequestCustomerId
                : '';
            }
          }
          req.body = JSON.stringify(dto);
        }
      } else {
        if (req.url.indexOf('?') < 0) {
          req.url = req.url + '?';
        } else {
          req.url = req.url + '&';
        }
        if (this.currentUser) {
          // const upt = req.url.toLowerCase().split('?');
          const upt = req.url.toLowerCase();
          if (
            (upt && upt.indexOf('&userid=') > -1) ||
            upt.indexOf('?userid=') > -1 ||
            upt.indexOf('&amp;userid=') > -1
          ) {
            // 已指定了userId，customerID 不再追加
          } else {
            req.url = req.url + 'UserId=' + this.currentUser.UserId;
            req.url = req.url + '&CustomerId=' + this.currentUser.CustomerId;
          }
          if (req.url.indexOf('&RequestWorkspaceId=') < 0) {
            const workspaceId = this.currentWorkspaceId ? this.currentWorkspaceId : '';
            req.url = req.url + '&RequestWorkspaceId=' + workspaceId;
          }
          if (req.url.indexOf('&RequestCustomerId=') < 0) {
            const customerId = this.currentRequestCustomerId ? this.currentRequestCustomerId : '';
            req.url = req.url + '&RequestCustomerId=' + customerId;
          }
        }
      }
    };
    return jc;
  }

  public getAuthClient(): JsonServiceClient {
    const url = baseUrl + 'cc_auth';
    const jc = new JsonServiceClient(url);
    return jc;
  }

  public getMessageV2ApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('mctmessagev2', refUrl);
  }

  public getBillingApiClient(): JsonServiceClient {
    return this.getClient('billing');
  }

  public getPlatformApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('platform', refUrl);
  }

  public getAdminApiClient(): JsonServiceClient {
    return this.getClient('admin');
  }

  public getMessageApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('message', refUrl);
  }

  public getSignInApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('signin', refUrl);
  }

  public getAnonyClient(key = '', refUrl: string = ''): JsonServiceClient {
    return this.getClient(key, refUrl);
  }

  public getFamilyApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('famportal', refUrl);
  }

  public getFileApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('file', refUrl);
  }

  public getMctCarewaitApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('mctcarewait', refUrl);
  }

  public getMctCarewaitV2ApiClient(refUrl: string = ''): JsonServiceClient {
    return this.getClient('stanfordcwv2', refUrl);
  }

  public getZipDetailService(zipCode): Observable<any> {
    // const lang = this.translator.getDefaultLanguage();
    const url = 'https://apigateway.mcttechnology.com/google/map/geocode/json';
    const language = 'en';
    const country = 'USA';
    const postData = {
      address: zipCode + ',' + country,
      language: language,
    };
    return this.http.get(url, { params: new HttpParams({ fromObject: postData }) });
  }
  public getZipDetail(zipCode, callback) {
    let City;
    let State;
    this.getZipDetailService(zipCode).subscribe(result => {
      if (result.status == 'OK') {
        result.results[0].address_components.map(function (item) {
          if (item['types'][0] && item['types'][0] == 'locality') {
            City = item['long_name'];
          } else if (item['types'][0] && item['types'][0] == 'administrative_area_level_1') {
            State = item['short_name'];
          }
          if (City || State) {
            callback({
              data: { City: City, State: State },
            });
          }
        });
      } else {
        callback({
          error: result.status,
        });
        console.error('app-zip-input http request result :' + result.status);
      }
    });
  }

  public setRequestCustomerId(CustomerId: string): void {
    localStorage.setItem('RequestCustomerId', CustomerId);
  }

  async getRequestCustomerId(): Promise<string> {
    let value = localStorage.getItem('RequestCustomerId');
    let changevalue = '';
    if (!value) {
      changevalue = await this.GetRequestCustomerIdByCustomerId();
    } else {
      changevalue = value;
    }
    return changevalue;
  }

  async GetRequestCustomerIdByCustomerId(): Promise<string> {
    const client = this.MctCareWaitClient;
    const postData = new GetRequestCustomerIdByCustomerIdRequest();
    let requrestCustomerId;
    const response = await client.get(postData);
    if (response.IsSuccess) {
      requrestCustomerId = response.Data;
      localStorage.setItem('RequestCustomerId', requrestCustomerId);
      return requrestCustomerId;
    } else {
      return '';
    }
  }
}
