import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../carecloud/common.api';
import { IReturn } from '@servicestack/client';
import { SysReportDataSource } from './datamodel/report';
import { EntityType, EntityField } from './datamodel/entityType';

export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class EntityTypeResponse extends BaseResponse {
  EntityType: EntityType;
  Fields: EntityField[];
}
// [Route("/query/reportdatasource", "GET")]
export class GetAllQueryDataSourceRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<SysReportDataSource[]>>
{
  createResponse() {
    return new Response<SysReportDataSource[]>();
  }
  getTypeName() {
    return 'GetAllQueryDataSourceRequest';
  }
}

// [Route("/query/reportdatasource/by-code/{ReportDataSourceByCode}", "GET")]
export class GetDataSourceByCodeRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<SysReportDataSource>>
{
  ReportDataSourceByCode: string;
  createResponse() {
    return new Response<SysReportDataSource>();
  }
  getTypeName() {
    return 'GetDataSourceByCodeRequest';
  }
}

// [Route("/entity/table/query/by-entitytablenames/{EntityTableNames}", "GET")]
export class GetEntityTableByEntityTableNamesRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  EntityTableNames: string[];
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetEntityTableByEntityTableNamesRequest';
  }
}

// [Route("/entity/table/field/query/by-entitytableid/{EntityTableId}", "GET")]
export class GetEntityTableFieldByEntityTableIdRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  EntityTableId: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetEntityTableFieldByEntityTableIdRequest';
  }
}

// [Route("/entity/table/query/by-reportdatasourcecode/{ReportDataSourceCode}", "GET")]
export class GetEntityTableByReportDataSourceCodeRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  ReportDataSourceCode: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetEntityTableByReportDataSourceCodeRequest';
  }
}

// [Route("/query/query/{ReportDataSourceCode}", "GET")]
export class GetAllQueryRequest extends CareWaitBaseRequest implements IReturn<Response<any>> {
  IncludeDisable: boolean;
  Apply: number; //1:SmartList 2:Report
  ReportDataSourceCode: string;
  OwnerType: string; //0: global 1: mycustom 2: shared
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetAllQueryRequest';
  }
}

// [Route("/query/custom", "Post")]
export class PostCustomQueryRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  EntityType: any;
  Fields: any;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'PostCustomQueryRequest';
  }
}

// [Route("/query/custom/field/{EntityTypeId}", "PUT")]
export class PutCustomQueryFieldRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  EntityTypeId: string;
  Fields: { [key: string]: any }[];
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'PutCustomQueryFieldRequest';
  }
}

// [Route("/entity/type/setdefault/{EntityTypeId}", "PUT")]
export class SetEntityTypeDefaultRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  EntityTypeId: string;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'SetEntityTypeDefaultRequest';
  }
}

// [Route("/query/getdefault/{ReportDataSourceCode}", "GET")]
export class GetDefaultQueryRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<string>>
{
  IncludeDisable: boolean;
  Apply: number; //1:SmartList 2:Report
  ReportDataSourceCode: string;
  OwnerType: string; //0: global 1: mycustom 2: shared
  createResponse() {
    return new Response<string>();
  }
  getTypeName() {
    return 'GetDefaultQueryRequest';
  }
}

// [Route("/query/getalldefault", "GET")]
export class GetAllDefaultQueryRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<DefaultQueryParam[]>>
{
  DefaultQueryParam: DefaultQueryParam[];
  createResponse() {
    return new Response<DefaultQueryParam[]>();
  }
  getTypeName() {
    return 'GetAllDefaultQueryRequest';
  }
}
export class DefaultQueryParam {
  Apply: number; //1:SmartList 2:Report
  ReportDataSourceCode: string;
  OwnerType: string; //0: global 1: mycustom 2: shared
  QueryId: string; //返回该项默认的QueryId
}

// [Route("/entity/type/share/{EntityTypeId}", "PUT")]
export class ShareEntityTypeRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  EntityTypeId: string;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'ShareEntityTypeRequest';
  }
}

// [Route("/entity/type/unshare/{EntityTypeId}", "PUT")]
export class UnshareEntityTypeRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  EntityTypeId: string;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'UnshareEntityTypeRequest';
  }
}

export class S3FilePath {
  FilePath: string;
  FileUrl: string;
}

// [Route("/report/stats/getdata", "GET")]
export class GetStatsReportFileRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<S3FilePath>>
{
  DataSourceCode: string;
  Query: { [key: string]: any };
  createResponse() {
    return new Response<S3FilePath>();
  }
  getTypeName() {
    return 'GetStatsReportFileRequest';
  }
}

// [Route("/document/GetS3FileUrl", "GET")]
export class GetS3FileUrlRequest extends CareWaitBaseRequest implements IReturn<Response<string>> {
  S3FilePath: string;
  createResponse() {
    return new Response<string>();
  }
  getTypeName() {
    return 'GetS3FileUrlRequest';
  }
}

//获取有Dashbaord哪些项目
//[Route("/entity/type/dashboarditem", "GET")]
export class GetDashboardItemListRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetDashboardItemListRequest';
  }
}

//获取某项的数据
//[Route("/report/stats/getdata", "GET,POST")]
export class GetStatsReportDataRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  EntityTypeId: string;
  Options: { [key: string]: any };
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetStatsReportDataRequest';
  }
}
