import { RLChildItem } from './waitlist/childItem';
import { Query } from './waitlist/query';
import { StatusItem } from './waitlist/statusItem';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse } from '../carecloud/common.api';
import { QueryOption } from './query.api';

export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

//[Route("/application/{Id}", "GET")]
export class EntityDataResponse extends Response<{ [key: string]: any }> {
  Id: string;
}

// EntityCode：cw_sys_job
// // [Route("/job/autoquery/and", "GET")]
// export  class GetJobListByAutoQueryRequest extends QueryDb<JobListV2>
// {
//     IncludeDisable: boolean;
// }

// [Route("/job/run", "POST")]
export class RunJobRequest extends CareWaitBaseRequest implements IReturn<Response<boolean>> {
  AsDate: string; // YYYYMMDD
  JobId: string; //
  ForceRun: boolean;
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'RunJobRequest';
  }
}

// [Route("/job/getisruning", "GET")]
export class GetJobIsRuningRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<boolean>>
{
  // AsDate: string; // YYYYMMDD
  JobId: string; //
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'GetJobIsRuningRequest';
  }
}

// [Route("/job/{Id}", "GET")]
export class GetJobRequest extends CareWaitBaseRequest implements IReturn<EntityDataResponse> {
  Id: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetJobRequest';
  }
}

// [Route("/job", "PUT")]
export class PutJobRequest extends CareWaitBaseRequest implements IReturn<EntityDataResponse> {
  Entity: { [key: string]: any };
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutJobRequest';
  }
}
