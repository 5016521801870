import { Injectable } from '@angular/core';
import { EntityService, WksEntity } from './entityService';
import { ConvertService } from '../../shared/utils/convert.service';
import { LogService } from '../log/log.service';
import { EntityDataService } from './entityDataService';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({ providedIn: 'root' })
export class EntityFieldService {
  constructor(
    private entityService: EntityService,
    private entityDataService: EntityDataService,
    private convert: ConvertService,
    private logger: LogService,
  ) {}

  public buildEntityDataSource(
    editorOptions,
    sourceCode: string,
    entityCode,
    fields,
    filterExpr,
    displayExpr,
    valueExpr,
    sortExpr,
  ) {
    if (!sourceCode || !editorOptions) {
      return editorOptions;
    }
    editorOptions.searchEnabled = true;
    editorOptions.showClearButton = true;
    const code = sourceCode.toLowerCase();
    // if (code.startsWith('ct.view.')) {
    //     this.buildWorkspaceViewDataSource(editorOptions, code, entityCode, fields, filterExpr, displayExpr, valueExpr, sortExpr);
    //     return editorOptions;
    // }
    switch (code) {
      case 'cw_app_child':
        if (!displayExpr) {
          displayExpr = item => {
            if (item) {
              return item.FirstName + ' ' + item.LastName;
            } else {
              return '';
            }
          };
        }
        if (!sortExpr) {
          sortExpr = ['FirstName', 'LastName'];
        }
        this.buildWorkspaceEntityDataSource(
          editorOptions,
          code,
          filterExpr,
          displayExpr,
          valueExpr,
          sortExpr,
        );
        break;
      case 'cw_sys_role':
        if (!displayExpr) {
          displayExpr = item => {
            if (item) {
              return item.RoleName;
            } else {
              return '';
            }
          };
        }
        if (!valueExpr) {
          valueExpr = 'Id';
        }
        if (!sortExpr) {
          sortExpr = ['RoleName'];
        }
        this.buildWorkspaceEntityDataSource(
          editorOptions,
          code,
          filterExpr,
          displayExpr,
          valueExpr,
          sortExpr,
        );
        break;
      case 'cw_pro_provider':
        if (!displayExpr) {
          displayExpr = item => {
            if (item) {
              return item.ProviderName;
            } else {
              return '';
            }
          };
        }
        if (!sortExpr) {
          sortExpr = ['ProviderName'];
        }
        this.buildWorkspaceEntityDataSource(
          editorOptions,
          code,
          filterExpr,
          displayExpr,
          valueExpr,
          sortExpr,
        );
        break;
      case 'cw_sys_user':
        if (!displayExpr) {
          displayExpr = item => {
            if (item) {
              return item.FirstName + ' ' + item.LastName;
            } else {
              return '';
            }
          };
        }
        if (!sortExpr) {
          sortExpr = ['FirstName', 'LastName'];
        }
        this.buildWorkspaceEntityDataSource(
          editorOptions,
          code,
          filterExpr,
          displayExpr,
          valueExpr,
          sortExpr,
        );
        break;
      case 'cw_sys_organization':
        if (!displayExpr) {
          displayExpr = item => {
            if (item) {
              return item.Name;
            } else {
              return '';
            }
          };
        }
        if (!sortExpr) {
          sortExpr = ['Name'];
        }
        this.buildWorkspaceEntityDataSource(
          editorOptions,
          code,
          filterExpr,
          displayExpr,
          valueExpr,
          sortExpr,
        );
        break;
      // case 'ct.sys.organization.user':
      //     if (!displayExpr) {
      //         displayExpr = (item) => {
      //             if (item) {
      //                 return item.FirstName + ' ' + item.LastName;
      //             } else {
      //                 return '';
      //             }
      //         }
      //     }
      //     if (!sortExpr) {
      //         sortExpr = ['FirstName', 'LastName'];
      //     }
      //     this.buildCoreEntityDataSource(editorOptions, code, displayExpr, 'CloudUserId', sortExpr);
      //     break;
      // case 'ct.sys.organization.member':
      // case 'ct.sys.contract':
      //     this.buildCoreEntityDataSource(editorOptions, code, displayExpr, valueExpr, sortExpr);
      //     break;
      // case 'ct.sys.family':
      // case 'ct.sys.provider':
      default:
        this.buildWorkspaceEntityDataSource(
          editorOptions,
          code,
          filterExpr,
          displayExpr,
          valueExpr,
          sortExpr,
        );
        break;
    }
    return editorOptions;
  }

  private buildWorkspaceEntityDataSource(
    editorOptions,
    entityCode,
    filterExpr,
    displayExpr,
    valueExpr,
    sortExpr,
  ) {
    editorOptions.displayExpr = displayExpr || 'Name';
    editorOptions.valueExpr = valueExpr || 'Id';
    editorOptions.dataSource = {
      store: new CustomStore({
        key: valueExpr || 'Id',
        loadMode: 'raw',
        cacheRawData: true,
        load: loadOptions => {
          const filter = filterExpr ? filterExpr() : '';
          return this.entityDataService
            .getDataList(entityCode, loadOptions, false, false, filter)
            .then(response => {
              if (loadOptions.requireTotalCount === true) {
                // console.log(response.Results);
                return { data: response.Results, totalCount: response.Total };
              } else {
                return response.Results;
              }
            });
        },
        // byKey: (Id) => {
        //     return this.entityDataService.getEntityByRowId(entityCode, Id)
        //         .then(res => res.Data);
        // }
      }),
      sort: sortExpr || ['Name'],
    };
  }

  private buildWorkspaceViewDataSource(
    editorOptions,
    viewCode,
    entityCode,
    fieldsExpr,
    filterExpr,
    displayExpr,
    valueExpr,
    sortExpr,
  ) {
    editorOptions.displayExpr = displayExpr || 'Name';
    editorOptions.valueExpr = valueExpr || 'RowId';
    entityCode = entityCode || viewCode;
    editorOptions.dataSource = {
      store: new CustomStore({
        key: valueExpr || 'RowId',
        loadMode: 'raw',
        cacheRawData: true,
        load: loadOptions => {
          const filter = filterExpr ? filterExpr() : '';
          const fields = fieldsExpr ? fieldsExpr : '';
          return this.entityDataService.getViewList(viewCode, fields, filter).then(response => {
            if (loadOptions.requireTotalCount === true) {
              return { data: response.Results, totalCount: response.Total };
            } else {
              return response.Results;
            }
          });
        },
        byKey: rowid => {
          return this.entityDataService.getEntityByRowId(entityCode, rowid).then(res => res.Data);
        },
      }),
      sort: sortExpr || ['Name'],
    };
  }

  private buildCoreEntityDataSource(editorOptions, entityCode, displayExpr, valueExpr, sortExpr) {
    editorOptions.displayExpr = displayExpr || 'Name';
    editorOptions.valueExpr = valueExpr || 'Id';
    editorOptions.dataSource = {
      store: new CustomStore({
        key: valueExpr || 'Id',
        loadMode: 'raw',
        cacheRawData: true,
        load: loadOptions => {
          return this.entityDataService.getCoreDictList(entityCode);
        },
        byKey: id => {
          return this.entityDataService.getCoreDictById(entityCode, id);
        },
      }),
      sort: sortExpr || ['Name'],
    };
  }

  public buildEntityFieldFilter(entity, column, queryModel) {
    if (entity) {
      const entityFieldList = entity.EntityFields.filter(f => f.Disable == false && f.Apply == 0);
      for (const i in entityFieldList) {
        const entityfield = entityFieldList[i];
        const itemColumnOptions = entityfield.GridColumnsOptions
          ? entityfield.GridColumnsOptions
          : {};
        for (const c in column) {
          const col = column[c];
          if (col.dataField == entityfield.FieldName) {
            if (col.hasOwnProperty('filterValue')) {
              itemColumnOptions['filterValue'] = col.filterValue;
            }
            if (col.filterValues) {
              itemColumnOptions['filterValues'] = col.filterValues;
            }
            if (col.selectedFilterOperation) {
              itemColumnOptions['selectedFilterOperation'] = col.selectedFilterOperation;
            }
            itemColumnOptions['visible'] = col.visible;
            itemColumnOptions['visibleIndex'] = col.visibleIndex;
            itemColumnOptions['sortOrder'] = col.sortOrder;
            itemColumnOptions['sortIndex'] = col.sortIndex;
            itemColumnOptions['groupIndex'] = col.groupIndex;
          }
          entityFieldList[i].GridColumnsOptions = itemColumnOptions;
        }
      }

      let entityQueryFieldList = entity.EntityFields.filter(
        f => f.Disable == false && f.Apply == 1,
      );
      console.log(entityQueryFieldList);
      for (const i in entityQueryFieldList) {
        const entityQueryfield = entityQueryFieldList[i];
        const itemQueryOptions = entityQueryfield.QueryOptions ? entityQueryfield.QueryOptions : {};

        const key = entityQueryfield.FieldName;
        const op = key + this.getOp(itemQueryOptions.Operator);
        const opField = 't_op_' + key + this.getOp(itemQueryOptions.Operator);
        const queryDataField = op;

        for (const q in queryModel) {
          const query = queryModel[q];
          if (q == queryDataField) {
            itemQueryOptions['DefaultValue'] = query;
          }
          if (q == opField) {
            itemQueryOptions['Operator'] = query;
          }
          entityQueryFieldList[i].QueryOptions = itemQueryOptions;
        }
      }
    }
    console.log(entity.EntityFields);
    return entity;
  }

  private getOp(opchar) {
    if (!opchar) {
      return '';
    }
    switch (opchar) {
      case '=': {
        return '';
      }
      case 'StartsWith': {
        return 'StartsWith';
      }
      case 'EndsWith': {
        return 'EndsWith';
      }
      case '!=': {
        return '!';
      }
      case '>': {
        return 'GreaterThan';
      }
      case '>=': {
        return 'GreaterThanOrEqualTo';
      }
      case '<': {
        return 'LessThan';
      }
      case '<=': {
        return 'LessThanOrEqualTo';
      }
      case 'In': {
        return 'In';
      }
      case 'Contains': {
        return 'Contains';
      }
      default:
        return '';
    }
  }

  private getExpr(expr, entityCode, defaultValue = null) {
    let f: any;
    if (expr && expr instanceof Function) {
      f = expr(entityCode);
    } else {
      f = expr;
    }
    return f || defaultValue;
  }
}
