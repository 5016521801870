import { QueryOption } from './query.api';
import { BaseRequest, IReturn, IReturnVoid, BaseResponse } from '../carecloud/common.api';
import { Pager } from './subscription.api';
import { EntityDataResponse } from './applicationdetail.api';

/* Options:
Date: 2018-10-18 22:58:35
Version: 4.56
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost/servicebillingapi/v1/api
*/

export class BaseSubscriptionRequest extends BaseRequest {}

export class Response<T> extends BaseResponse {
  Data: T;
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1, EmitDefaultValue=false)
  ErrorCode: string;

  // @DataMember(Order=2, EmitDefaultValue=false)
  FieldName: string;

  // @DataMember(Order=3, EmitDefaultValue=false)
  Message: string;

  // @DataMember(Order=4, EmitDefaultValue=false)
  Meta: { [index: string]: string };
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  ErrorCode: string;

  // @DataMember(Order=2)
  Message: string;

  // @DataMember(Order=3)
  StackTrace: string;

  // @DataMember(Order=4)
  Errors: ResponseError[];

  // @DataMember(Order=5)
  Meta: { [index: string]: string };
}

export class FamilyLedgerInfo {
  public TransId: string;
  public ParentId: string;
  public ParentFirstName: string;
  public ParentLastName: string;
  public FamilyId: string;
  public CustomerId: string;
  public PostDate: string;
  public DueDate: string;
  // additional data
  public OrganizationName: string;
  public UserName: string;
  /*[ServiceStack.DataAnnotations.Reference]*/
  public Items: LedgerInvoiceItem[];
  public PlanId: number;
  public Status: string;
  public Amount: number;
  public ChargeBalance: number;
  public SequenceNo: string;
  public IsPayment: number;
  public PeriodStart: string;
  public PeriodEnd: string;
  public Disable: boolean;
  public ActuallyAmount: number;
  public Comment: string;
  public ThirdPartyAccountName: string;
  public IsThirdAccount: boolean;
}
export class LedgerInvoiceItem {
  public ItemId: string;
  public TransId: string;
  public FamilyLedgerInfoId: string;
  public Type: string;
  public PeriodStart: string;
  public PeriodEnd: string;
  public Amount: number;
  public ChildId: string;
  public ChildFirstName: string;
  public ChildLastName: string;
  public Program: string;
  public Comment: string;
  public ChargeBalance: number;
  public Price: number;
  public Quantity: number;
  public PersentDiscount: number;
}

export class ApplicationListResponse extends Response<FamilyLedgerInfo[]> {
  public Pager: Pager;
}

/*[Route("/parentledger/parentledgerlist/{ParentId}","POST")]*/
export class ApplicationListRequest
  extends BaseRequest
  implements IReturn<ApplicationListResponse>
{
  public Type: string;
  public Status: string;
  public ParentId: string;
  public PostStartDate: string;
  public PostEndDate: string;
  public Pager: Pager;
  createResponse() {
    return new ApplicationListResponse();
  }
  getTypeName() {
    return 'ApplicationListRequest';
  }
}

// [Route("/application/child/provider/getrank/{Id}", "GET")]
export class GetApplicantProviderRankRequest
  extends BaseRequest
  implements IReturn<Response<number>>
{
  Id: string;
  createResponse() {
    return new Response<number>();
  }
  getTypeName() {
    return 'GetApplicantProviderRankRequest';
  }
}

// [Route("/application/recalculate/{Id}", "PUT")]
export class RecalculateApplicationRequest extends BaseRequest implements IReturn<BaseResponse> {
  Id: string;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'RecalculateApplicationRequest';
  }
}

// EntityTypeCode: cw_tp_Affiliation
// [Route("/thirdpart/affiliationdata/by-emplid/{EMPLID}", "GET")]
export class GetAffiliationDataByEMPLIDRequest
  extends BaseRequest
  implements IReturn<EntityDataResponse>
{
  EMPLID: string;
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetAffiliationDataByEMPLIDRequest';
  }
}

// EntityTypeCode: cw_tp_Student
// 通过StudentId获取数据
// [Route("/thirdpart/studentdata/by-emplid/{EMPLID}", "GET")]
export class GetStudentDataByEMPLIDRequest
  extends BaseRequest
  implements IReturn<EntityDataResponse>
{
  EMPLID: string;
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetStudentDataByEMPLIDRequest';
  }
}

// [Route("/thirdpart/affiliationdata/{Id}", "GET")]
export class GetAffiliationDataRequest extends BaseRequest implements IReturn<EntityDataResponse> {
  Id: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetAffiliationDataRequest';
  }
}

// [Route("/thirdpart/studentdata/{Id}", "GET")]
export class GetStudentDataRequest extends BaseRequest implements IReturn<EntityDataResponse> {
  Id: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetStudentDataRequest';
  }
}

export class ParentEmployeeAndStudentIdItem {
  ContactId: string;
  Responsibility: string; //A, B
  DataType: number; //1 : EmployeeId 2: StudentId
  DataId: string;
}

// [Route("/application/parent/getemmployeeandstudentid", "GET")]
export class GetApplicationParentEmployeeAndStudentIdRequest
  extends BaseRequest
  implements IReturn<Response<ParentEmployeeAndStudentIdItem[]>>
{
  ApplicationId: string;
  createResponse() {
    return new Response<ParentEmployeeAndStudentIdItem[]>();
  }
  getTypeName() {
    return 'GetApplicationParentEmployeeAndStudentIdRequest';
  }
}

// Code: cw_pro_vacancy_child_forwlo
// Get List API:
//     [Route("/application/vacancychild/wlo/autoquery/and", "GET")]

// export interface GetProviderVacancyChildListForWLOByAutoQueryRequest extends QueryDb<ProviderVacancyChildListV2, OfferListItem>
//     , IJoin<ProviderVacancyChildListV2, DataSourceVacancy>
//     , IJoin<DataSourceVacancy, DataSourceProvider>
//     , IJoin<ProviderVacancyChildListV2, DataSourceChild>
//     , IJoin<DataSourceChild, DataSourceChildProvider>
//     {
//         IncludeDisable: boolean;
//     }

// Parent - Offer list
// Code: cw_pro_vacancy_child_forparent
// Get List API:
//     [Route("/application/vacancychild/parent/autoquery/and", "GET")]

// export interface GetProviderVacancyChildListForParentByAutoQueryRequest extends QueryDb<ProviderVacancyChildListV2, OfferListItem>
//     , IJoin<ProviderVacancyChildListV2, DataSourceVacancy>
//     , IJoin<DataSourceVacancy, DataSourceProvider>
//     , IJoin<ProviderVacancyChildListV2, DataSourceChild>
//     , IJoin<DataSourceChild, DataSourceChildProvider>
//     {
//         IncludeDisable: boolean;
//     }

export class CustomerItem {
  public Name: string;
  public CustomerId: number;
}

export class MockService {
  GetCurrentUser() {
    let userData = null,
      logonName = '';
    const userJson = localStorage.getItem('family_user');
    if (userJson) {
      const user = JSON.parse(userJson);
      if (user && user.Email) {
        logonName = user.Email;
        // tslint:disable-next-line:max-line-length
        userData = {
          IsSuccess: true,
          Data: {
            Linked: true,
            UserId: 223375,
            LogonName: 'apcbshtest@mcttechnology.com',
            CustomerId: 43,
            NickName: '',
            FirstName: 'uesr1-test12',
            LastName: 'shtest',
            Phone: '(123) 444-6666 x',
            Email: 'apcbshtest@mcttechnology.com',
            Gender: 'm',
            Status: 1,
            IsAdmin: true,
            IsMCTSupport: false,
            CustomerCode: null,
            AccountType: 1,
            Roles: [
              'MessageUser',
              'carewait.role.admin',
              'carewait.sp.sys.admin.org',
              'carewait.sp.sys.admin.user',
              'carewait.sp.sys.admin.setting',
              'StudentAdmin',
              'AdminUser',
              'AttendanceAdmin',
              'BillingUser',
            ],
            Apps: ['message', 'carewait', 'students', 'admin', 'attendance', 'billing'],
          },
          Error: '',
        };
        if (logonName && logonName.toLowerCase().indexOf('0') > -1) {
          // tslint:disable-next-line:max-line-length
          userData = {
            IsSuccess: true,
            Data: {
              Linked: false,
              UserId: 111112942,
              LogonName: '903667633@qq.com',
              CustomerId: 889,
              NickName: '903667633@qq.com',
              FirstName: 'cc',
              LastName: 'chao',
              Phone: '(315) 628-1288',
              Email: '903667633@qq.com',
              Gender: 'm',
              Status: 1,
              IsAdmin: true,
              IsMCTSupport: false,
              CustomerCode: null,
              AccountType: 2,
              Roles: [
                'BaseUser',
                'ReportUser',
                'AdvanceUser',
                'StudentAdmin',
                'BillingAdmin',
                'SubsidyUser',
              ],
              Apps: ['students', 'billing'],
            },
            Error: '',
          };
        }
      }
    }
    return userData;
  }

  GetCustomerList() {
    const list = [
      {
        Name: 'apcbshtest',
        CustomerId: 43,
        LogoUrl: 'https://shtest-familyportal.mcttechnology.cn/Images/provider.png',
      },
      {
        Name: 'Candy01',
        CustomerId: 889,
        LogoUrl: 'https://shtest-familyportal.mcttechnology.cn/Images/user.png',
      },
    ];
    return list;
  }

  GetApplicationListRequest(customerId = 43, type = '0') {
    // tslint:disable-next-line:max-line-length
    let jsonObj = {
      Pager: { CurrentPage: 1, StartIndex: 0, PageSize: 20, TotalRecords: 9, TotalPageNumber: 1 },
      Data: [
        {
          TransId: 'ffb282bc-cade-4eef-8f49-df6dded064ad',
          ParentId: '3d819a77-27e7-48ba-8f03-898937fcbc09',
          ParentFirstName: 'Mom',
          ParentLastName: 'CBP',
          FamilyId: '1f05c0dd-ba20-45d7-819f-9aa403e0ea5d',
          CustomerId: '43',
          PostDate: '20190710',
          DueDate: '20190718',
          OrganizationName: 'Organizaiton1',
          UserName: 'wychild',
          Items: [
            {
              ItemId: '43f8d2ff-c299-4630-9b8a-46ccf99ed971',
              TransId: 'ffb282bc-cade-4eef-8f49-df6dded064ad',
              FamilyLedgerInfoId: 'ffb282bc-cade-4eef-8f49-df6dded064ad',
              Type: null,
              PeriodStart: '20190401',
              PeriodEnd: '20190430',
              Amount: 19.8,
              ChildId: '91d2d893-7d9b-430e-aa6e-27e229ba4d38',
              ChildFirstName: 'Test1',
              ChildLastName: 'CBP',
              Program: '1111',
              Comment: null,
              ChargeBalance: 19.8,
              Price: 20.0,
              Quantity: 1.0,
              PersentDiscount: 1.0,
            },
          ],
          PlanId: 2571,
          Status: '3',
          Amount: 19.8,
          ChargeBalance: 19.8,
          SequenceNo: '10662',
          IsPayment: 0,
          PeriodStart: '20190401',
          PeriodEnd: '20190430',
          Disable: false,
          ActuallyAmount: 0,
          Comment: '123ddddd',
          ThirdPartyAccountName: '',
          IsThirdAccount: false,
        },
        {
          TransId: 'eb65e708-2156-4585-8d91-5d34ee582a35',
          ParentId: '3d819a77-27e7-48ba-8f03-898937fcbc09',
          ParentFirstName: 'Mom',
          ParentLastName: 'CBP',
          FamilyId: '1f05c0dd-ba20-45d7-819f-9aa403e0ea5d',
          CustomerId: '43',
          PostDate: '20190624',
          DueDate: '20190704',
          OrganizationName: 'Organizaiton1',
          UserName: 'wychild',
          Items: [
            {
              ItemId: 'fa6e2acf-00d9-4315-902d-9a834ff1ab25',
              TransId: 'eb65e708-2156-4585-8d91-5d34ee582a35',
              FamilyLedgerInfoId: 'eb65e708-2156-4585-8d91-5d34ee582a35',
              Type: null,
              PeriodStart: '20191101',
              PeriodEnd: '20191130',
              Amount: 19.8,
              ChildId: '91d2d893-7d9b-430e-aa6e-27e229ba4d38',
              ChildFirstName: 'Test1',
              ChildLastName: 'CBP',
              Program: '1111',
              Comment: null,
              ChargeBalance: 19.8,
              Price: 20.0,
              Quantity: 1.0,
              PersentDiscount: 1.0,
              OrganizationName: 'Organizaiton1',
              UserName: 'wychild',
            },
          ],
          PlanId: 2571,
          Status: '1',
          Amount: 19.8,
          ChargeBalance: 19.8,
          SequenceNo: '10669',
          IsPayment: 0,
          PeriodStart: '20191101',
          PeriodEnd: '20191130',
          Disable: false,
          ActuallyAmount: 0,
          Comment: '123ddddd',
          ThirdPartyAccountName: '',
          IsThirdAccount: false,
        },
        {
          TransId: 'c5516b3e-475b-4185-9ac2-ba6c725d11a3',
          ParentId: '3d819a77-27e7-48ba-8f03-898937fcbc09',
          ParentFirstName: 'Mom',
          ParentLastName: 'CBP',
          FamilyId: '1f05c0dd-ba20-45d7-819f-9aa403e0ea5d',
          CustomerId: '43',
          PostDate: '20190526',
          DueDate: '20190730',
          OrganizationName: 'Organizaiton1',
          UserName: 'wychild',
          Items: [
            {
              ItemId: 'c762bbf9-5af8-4e9c-b9ff-25716686049e',
              TransId: 'c5516b3e-475b-4185-9ac2-ba6c725d11a3',
              FamilyLedgerInfoId: 'c5516b3e-475b-4185-9ac2-ba6c725d11a3',
              Type: null,
              PeriodStart: '20191001',
              PeriodEnd: '20191031',
              Amount: 19.8,
              ChildId: '91d2d893-7d9b-430e-aa6e-27e229ba4d38',
              ChildFirstName: 'Test1',
              ChildLastName: 'CBP',
              Program: '1111',
              Comment: null,
              ChargeBalance: 19.8,
              Price: 20.0,
              Quantity: 1.0,
              PersentDiscount: 1.0,
              OrganizationName: 'Organizaiton1',
              UserName: 'wychild',
            },
          ],
          PlanId: 2571,
          Status: '2',
          Amount: 19.8,
          ChargeBalance: 19.8,
          SequenceNo: '10668',
          IsPayment: 0,
          PeriodStart: '20191001',
          PeriodEnd: '20191031',
          Disable: false,
          ActuallyAmount: 0,
          Comment: '123ddddd',
          ThirdPartyAccountName: '',
          IsThirdAccount: false,
        },
        {
          TransId: '79bc0b4f-f5f3-4103-83f0-dd4fc4c5ad0d',
          ParentId: '3d819a77-27e7-48ba-8f03-898937fcbc09',
          ParentFirstName: 'Mom',
          ParentLastName: 'CBP',
          FamilyId: '1f05c0dd-ba20-45d7-819f-9aa403e0ea5d',
          CustomerId: '43',
          PostDate: '20190711',
          DueDate: '20190714',
          OrganizationName: 'Organizaiton1',
          UserName: 'wychild',
          Items: [
            {
              ItemId: '8f722a2a-072d-42a9-b7a6-36748199c379',
              TransId: '79bc0b4f-f5f3-4103-83f0-dd4fc4c5ad0d',
              FamilyLedgerInfoId: '79bc0b4f-f5f3-4103-83f0-dd4fc4c5ad0d',
              Type: null,
              PeriodStart: '20190801',
              PeriodEnd: '20190831',
              Amount: 19.8,
              ChildId: '91d2d893-7d9b-430e-aa6e-27e229ba4d38',
              ChildFirstName: 'Test1',
              ChildLastName: 'CBP',
              Program: '1111',
              Comment: null,
              ChargeBalance: 19.8,
              Price: 20.0,
              Quantity: 1.0,
              PersentDiscount: 1.0,
              OrganizationName: 'Organizaiton1',
              UserName: 'wychild',
            },
          ],
          PlanId: 2571,
          Status: '1',
          Amount: 19.8,
          ChargeBalance: 19.8,
          SequenceNo: '10666',
          IsPayment: 0,
          PeriodStart: '20190801',
          PeriodEnd: '20190831',
          Disable: false,
          ActuallyAmount: 0,
          Comment: '123ddddd',
          ThirdPartyAccountName: '',
          IsThirdAccount: false,
        },
        {
          TransId: '1d0b53cc-1740-4ff7-b783-d48f0e872c09',
          ParentId: '3d819a77-27e7-48ba-8f03-898937fcbc09',
          ParentFirstName: 'Mom',
          ParentLastName: 'CBP',
          FamilyId: '1f05c0dd-ba20-45d7-819f-9aa403e0ea5d',
          CustomerId: '43',
          PostDate: '20190419',
          DueDate: '20190514',
          OrganizationName: 'Organizaiton1',
          UserName: 'wychild',
          Items: [
            {
              ItemId: 'f3c4758f-cb76-4f95-84b5-d6aa829e0808',
              TransId: '1d0b53cc-1740-4ff7-b783-d48f0e872c09',
              FamilyLedgerInfoId: '1d0b53cc-1740-4ff7-b783-d48f0e872c09',
              Type: null,
              PeriodStart: '20190301',
              PeriodEnd: '20190331',
              Amount: 19.8,
              ChildId: '91d2d893-7d9b-430e-aa6e-27e229ba4d38',
              ChildFirstName: 'Test1',
              ChildLastName: 'CBP',
              Program: '1111',
              Comment: null,
              ChargeBalance: 0.0,
              Price: 20.0,
              Quantity: 1.0,
              PersentDiscount: 1.0,
              OrganizationName: 'Organizaiton1',
              UserName: 'wychild',
            },
          ],
          PlanId: 2571,
          Status: '3',
          Amount: 19.8,
          ChargeBalance: 0.0,
          SequenceNo: '10661',
          IsPayment: 0,
          PeriodStart: '20190301',
          PeriodEnd: '20190331',
          Disable: false,
          ActuallyAmount: 0,
          Comment: '123ddddd',
          ThirdPartyAccountName: '',
          IsThirdAccount: false,
        },
        {
          TransId: 'dd2f80c4-b4b8-4a36-b018-2e2cff80cb17',
          ParentId: '3d819a77-27e7-48ba-8f03-898937fcbc09',
          ParentFirstName: 'Mom',
          ParentLastName: 'CBP',
          FamilyId: '1f05c0dd-ba20-45d7-819f-9aa403e0ea5d',
          CustomerId: '43',
          PostDate: '20190603',
          DueDate: null,
          Items: null,
          PlanId: 0,
          Status: '33',
          Amount: 100.0,
          ChargeBalance: -75.2,
          SequenceNo: '1541',
          IsPayment: 1,
          PeriodStart: null,
          PeriodEnd: null,
          Disable: false,
          ActuallyAmount: 0,
          Comment: null,
          ThirdPartyAccountName: '',
          IsThirdAccount: false,
        },
      ],
      IsSuccess: true,
      ErrCode: 200,
      ErrMsg: '',
    };

    let rtn = new ApplicationListResponse();
    rtn = jsonObj as ApplicationListResponse;
    const t = type;
    rtn.Data = rtn.Data.filter(function (x) {
      return x.IsPayment === 0 && (x.Status === t || t === '0');
    });
    return rtn;
  }

  GetInvoiceDetailById(id, customerId = 43) {
    const list = this.GetApplicationListRequest(customerId).Data;
    let invoice = new FamilyLedgerInfo();
    invoice = list.find(function (x) {
      return x.TransId === id;
    });
    return invoice;
  }
}
