import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../../carecloud/common.api';

export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class EntityDataResponse extends Response<{ [key: string]: any }> {
  Id: string;
}

// [Route("/organization", "POST")]
export class PostOrganizationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostOrganizationRequest';
  }
}

// [Route("/organization/{Id}", "GET")]
export class GetOrganizationByIdRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetOrganizationByIdRequest';
  }
}

// [Route("/organization", "PUT")]
export class PutOrganizationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutOrganizationRequest';
  }
}

// [Route("/organization/{Id}", "DELETE")]
export class DeleteOrganizationRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteOrganizationRequest';
  }
}

// [Route("/organization/restore/{Id}", "PUT")]
export class RestoreOrganizationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreOrganizationRequest';
  }
}
