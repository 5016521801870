import { Injectable } from '@angular/core';
import { JsonClientFactoryService } from '../carecloud/JsonClientFactory.service';
// import { Get } from '../models/report.api';
import { GetEntityTypeByIdRequest, GetEntityReportTypeByIdRequest } from '../models/entity.api';
import { LogService } from '../log/log.service';
import { WksReport, Report } from '../models/datamodel/report';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { EntityService } from './entityService';
import { EntityDataService } from './entityDataService';
import {
  EntityType,
  EntityField,
  DataType,
  EditorType,
  FieldType,
  DataSourceType,
} from '../models/datamodel/entityType';
import { GetDictionaryByCodeRequest } from '../models/dictionary.api';
import { ReportCacheService } from './cacheService';
import { BehaviorSubject } from 'rxjs';

import { GetDefaultQueryRequest } from '../models/report.api';
import { ToolService } from '../../shared/utils/tool.service';

export class WksEntity {
  Title: string;
  EntityType: EntityType;
  EntityFields: EntityField[];
  Fields: EntityField[];
  FieldMap: { [name: string]: EntityField };
  Columns?: any[];
  FormItems?: any[];
  PDFFormItems?: any[];
  FormOptions?: any;
  QueryOptions?: any;
  DefaultModel?: any;
  ColumnFormItems?: any[];
}
@Injectable()
export class ReportService {
  private reportMap: { [name: string]: WksEntity } = {};
  private dictMap: { [name: string]: any } = {};
  cacheData: any = {};
  public reportPdfUrl: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public reportPdfLoaded: BehaviorSubject<number> = new BehaviorSubject<number>(-1); // -1 未设置，1 成功， 2 失败
  public refreshPdf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private cacheReportService: ReportCacheService,
    private entityService: EntityService,
    private entityDataService: EntityDataService,
    private factory: JsonClientFactoryService,
    private logger: LogService,
  ) {}

  private getEditorType(type: EditorType) {
    switch (type) {
      case EditorType.CheckBox:
        return 'dxCheckBox';
      case EditorType.DateBox:
        return 'dxDateBox';
      case EditorType.NumberBox:
        return 'dxNumberBox';
      case EditorType.RadioBox:
        return 'dxRadioGroup';
      case EditorType.SelectBox:
        return 'dxSelectBox';
      case EditorType.TagBox:
        return 'dxTagBox';
      case EditorType.TextArea:
        return 'dxTextArea';
      default:
        return 'dxTextBox';
    }
  }

  private getEditorOptions(options: any) {
    if (!options) {
      return {};
    }
    for (const key in options) {
      if (options[key] && options[key].indexOf && options[key].indexOf('{') == 0) {
        options[key] = JSON.parse(options[key]);
      }
    }
    return options;
  }

  public async getReport(code: string, isCacheSchedule: any = false) {
    // let report = this.reportMap[code];
    // if (!report) {}
    let report = await this.getRemoteReport(code);
    if (report) {
      report.Columns = this.entityService.getDataGridColumns(
        report.EntityFields.filter(f => f.Disable == false && f.Apply == 0),
        null,
        isCacheSchedule,
        report,
      );
      report.ColumnFormItems = this.getDxFormItems(
        report.EntityFields.filter(f => f.Disable == false && f.Apply == 0),
        0,
      );
      report.FormOptions = report.EntityType
        ? report.EntityType.DefaultFormOptions
          ? report.EntityType.DefaultFormOptions
          : { labelLocation: 'top', colCount: 3 }
        : {};
      // debugger;
      report.FormItems = this.getDxFormItems(
        report.EntityFields.filter(f => f.Disable == false && f.Apply == 1),
        1,
      );

      report.PDFFormItems = this.getDxFormItems(
        report.EntityFields.filter(f => f.Disable == false && f.Apply == 1),
        3,
      );
      // console.log('====report.FormItems：', report.FormItems);
      report.DefaultModel = this.getDefaultModel(
        report.EntityFields.filter(f => f.Disable == false && f.Apply == 1),
        report.EntityType.ReportType,
      );
    } else {
      throw new Error('Not found report ' + code);
    }

    return report;
  }

  private getDxFormItems(fields: EntityField[], type) {
    const items = [];
    for (const item of fields) {
      const formitem: any = this.buildFormItem(item, type);
      items.push(formitem);
    }
    return items;
  }

  private buildFormItem(item: EntityField, type) {
    const vm = this;
    this.convertJsonType(item.QueryOptions);
    const formitem: any = {};
    formitem.entityField = item;
    const itemQueryOption = item.QueryOptions ? item.QueryOptions : {};
    // todo tabbed
    if (type == 1) {
      const key = item.FieldName;
      let op = key + this.getOp(item.QueryOptions.Operator);
      if (itemQueryOption && itemQueryOption.SpecialConditions == 'As of Date') {
        op = 'Report_As_of_Date';
      }
      formitem.dataField = op;
      // formitem.dataField = item.FieldName;
    } else {
      formitem.dataField = item.FieldName;
    }
    formitem.helpText = item.Description;
    formitem.editorType = this.getEditorType(EditorType[item.EditorType]);
    formitem.label = formitem.label || {};
    // if (item && item.QueryOptions) {
    //     const operatorValue = item.QueryOptions.Operator ? " (" + item.QueryOptions.Operator + ")" : ''
    //     formitem.label.text = item.Name + operatorValue;
    // } else {
    //     formitem.label.text = item.Name;
    // }
    formitem.label.text = item.Name;
    formitem.label.location = itemQueryOption.labelLocation
      ? itemQueryOption.labelLocation
      : undefined;
    formitem.editorOptions = this.getEditorOptions(item.EditorOptions);
    formitem.visibleIndex = item.VisibleIndex;
    formitem.colSpan = itemQueryOption.colSpan;
    if (typeof itemQueryOption.visible == 'undefined') {
      formitem.visible = false;
    } else {
      formitem.visible = itemQueryOption.visible;
    }

    if (itemQueryOption.Format) {
      formitem.editorOptions['format'] = itemQueryOption.Format;
    }

    if (itemQueryOption.searchEnabled) {
      formitem.editorOptions['searchEnabled'] = itemQueryOption.searchEnabled;
    }

    if (EditorType[item.EditorType] == EditorType.TagBox) {
      formitem.editorOptions.showDropDownButton = true;
      formitem.editorOptions.showSelectionControls = true;
      formitem.editorOptions.showClearButton = true;
      if (item.DataSourceType == DataSourceType.None) {
        formitem.editorOptions.acceptCustomValue = true;
      }
    }
    if (
      EditorType[item.EditorType] == EditorType.SelectBox ||
      EditorType[item.EditorType] == EditorType.TagBox ||
      EditorType[item.EditorType] == EditorType.RadioBox
    ) {
      if (item.DataSourceType == DataSourceType.Dictionary) {
        // dict
        // this.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
        formitem.editorOptions.displayExpr = 'Name';
        formitem.editorOptions.valueExpr = 'Code';

        formitem.editorOptions.dataSource = {
          store: new CustomStore({
            key: 'Code',
            loadMode: 'raw',
            load: loadOptions => {
              return vm.getDict(item.DataSource, item.EditorType);
            },
            // byKey(id) {
            //     return vm.getOrganizationById(id);
            // },
          }),
          sort: 'Order',
        };
      } else if (item.DataSourceType == DataSourceType.Array) {
        // array
        formitem.editorOptions.items = item.DataSource.split(';').map(x => x.trim());
      }
    }
    return formitem;
  }

  public getDefaultModel(fields: EntityField[], reportType = 0) {
    const items = {};
    for (const item of fields) {
      const formitem: any = this.convertJsonType(item.QueryOptions);
      const key = item.FieldName;
      let op = '';
      if (reportType == 3) {
        op = key;
      } else {
        op = key + this.getOp(item.QueryOptions.Operator);
        if (formitem && formitem.SpecialConditions == 'As of Date') {
          op = 'Report_As_of_Date';
        }
      }
      const dataField = op;
      if (formitem.DefaultValue || formitem.DefaultValue == 0) {
        if (formitem.DefaultValue == '@Today') {
          items[dataField] = new Date();
        } else if (formitem.DefaultValue == '@Today+1') {
          items[dataField] = moment(new Date()).add(1, 'day');
        } else if (formitem.DefaultValue == '@1st day of current month') {
          items[dataField] = moment(new Date()).startOf('month');
        } else if (formitem.DefaultValue == '@Last day of current month') {
          items[dataField] = moment(new Date()).endOf('month');
        } else if (formitem.DefaultValue == '@Me') {
          items[dataField] = this.factory.currentUser.UserId;
        } else {
          // items[item.BindFieldName] = item.DefaultValue;
          if (
            typeof formitem.DefaultValue != 'object' &&
            EditorType[item.EditorType] == EditorType.TagBox
          ) {
            const defaultvalue = [];
            defaultvalue.push(formitem.DefaultValue.toString());
            items[dataField] = defaultvalue;
          } else {
            items[dataField] = formitem.DefaultValue;
          }

          if (EditorType[item.EditorType] == EditorType.SelectBox) {
            items[dataField] = formitem.DefaultValue.toString();
          }
        }
      }

      if (reportType == 3) {
        if (!formitem.DefaultValue) {
          items[dataField] = null;
        }
      }

      if (EditorType[item.EditorType] == EditorType.CheckBox) {
        if (!formitem.DefaultValue) {
          items[dataField] = false;
        }
      }
    }
    return items;
  }

  public buildQueryParamsWithOp(report, queryModel) {
    if (queryModel) {
      const paramsRtn = new URLSearchParams();
      const allParamsList = [];
      // const paramMap = {};
      let repulicatedParam = null;
      const opList = {};
      const prefix = 't_op_';
      for (const key in queryModel) {
        if (Object.prototype.hasOwnProperty.call(queryModel, key)) {
          if (key.indexOf(prefix) > -1) {
            const rField = key.replace(prefix, '');
            const field = this.removeOp(rField); // this.reportService.removeOp(key);
            const op = queryModel[key]; // 操作符取值
            const newFieldName = field + this.getOp(op);
            if (['IsNull', 'IsNotNull'].indexOf(op) > -1) {
              opList[rField] = { fileName: newFieldName, value: 'is_boolean', op: op };
              // postQueryModel[newFieldName] = true;
            } else {
              if (queryModel[rField]) {
                // postQueryModel[newFieldName] = model[rField];
                opList[rField] = { fileName: newFieldName, value: queryModel[rField], op: op };
              }
            }
          }
        }
      }
      // console.log('==== oplist: ', opList);
      const queryFormItems = report.QueryFormItems ? report.QueryFormItems : report.FormItems;
      for (const item of queryFormItems) {
        let key = item.dataField;
        const entityField = item.entityField;
        let v: any = queryModel[key];
        let op = '';
        if (opList && opList[key] && opList[key]['fileName']) {
          if (opList[key]['value'] === 'is_boolean') {
            v = true;
          }
          op = opList[key]['op'];
          key = opList[key]['fileName'];
        }
        if (v) {
          // console.log('==== show field value:', entityField, v); v =true // isNull, isNotNull
          if (entityField.DataType == 'Date' && v !== true) {
            if (
              entityField.QueryOptions &&
              entityField.QueryOptions.DataTableField_DataType == 'DateTime'
            ) {
              if (op == '>=') {
                v = moment(v).startOf('D').utc().format('YYYYMMDDHHmmss');
              } else if (op == '>') {
                v = moment(v).add(1, 'day').startOf('D').utc().format('YYYYMMDDHHmmss');
              } else if (op == '<=') {
                v = moment(v).endOf('D').utc().format('YYYYMMDDHHmmss');
              } else if (op == '<') {
                v = moment(v).add(-1, 'day').endOf('D').utc().format('YYYYMMDDHHmmss');
              } else if (op == '!=') {
                key = key + 'DataTime';
                const lessThanValue = moment(v).startOf('D').utc().format('YYYYMMDDHHmmss');
                const greaterThanValue = moment(v).endOf('D').utc().format('YYYYMMDDHHmmss');
                v = lessThanValue + ',' + greaterThanValue;
              }
            } else {
              if (entityField.DefaultValue == '@Today+1') {
                v = moment(v).add(1, 'day');
              } else if (entityField.DefaultValue == '@1st day of current month') {
                v = moment(new Date()).startOf('month');
              } else if (entityField.DefaultValue == '@Last day of current month') {
                v = moment(new Date()).endOf('month');
              }
              if (entityField.ValueFormat) {
                v = moment(v).format(entityField.ValueFormat);
              } else {
                v = moment(v).format('YYYYMMDD');
              }
            }
          }

          // 参与Report特殊字段计算的条件不参与查询
          if (
            !entityField.QueryOptions ||
            (entityField.QueryOptions && !entityField.QueryOptions.SpecialConditions)
          ) {
            if (
              entityField.DataType == 'Date' &&
              entityField.QueryOptions &&
              entityField.QueryOptions.DataTableField_DataType == 'DateTime' &&
              op == '='
            ) {
              const field = this.removeOp(key);
              const addOpList = ['GreaterThanOrEqualTo', 'LessThanOrEqualTo'];
              const addValue = v;
              addOpList.forEach(additem => {
                if (additem == 'GreaterThanOrEqualTo') {
                  v = moment(addValue).startOf('D').utc().format('YYYYMMDDHHmmss');
                } else if (additem == 'LessThanOrEqualTo') {
                  v = moment(addValue).endOf('D').utc().format('YYYYMMDDHHmmss');
                }
                key = field + additem;
                if (paramsRtn.has(key)) {
                  if (!repulicatedParam) {
                    repulicatedParam = new URLSearchParams();
                  }
                  repulicatedParam.set(key, v);
                } else {
                  paramsRtn.set(key, v);
                }
              });
            } else {
              if (paramsRtn.has(key)) {
                if (!repulicatedParam) {
                  repulicatedParam = new URLSearchParams();
                }
                repulicatedParam.set(key, v);
              } else {
                paramsRtn.set(key, v);
              }
            }

            allParamsList.push({ k: key, v: v, o: op });
            // console.log('==== 特殊字段计算 convert value:', key, v, allParamsList);
          }
        }
      }
      let url = decodeURIComponent(paramsRtn.toString());
      if (repulicatedParam) {
        url += '&' + decodeURIComponent(repulicatedParam.toString());
      }
      return url;
    }
    return '';
  }

  public buildQueryParams(report, queryModel) {
    if (queryModel) {
      const params = new URLSearchParams();
      for (const item of report.FormItems) {
        let key = item.dataField;
        const entityField = item.entityField;
        let v: any = queryModel[key];
        // if (withOp) {
        //     const t = queryModel[key];
        //     if (t) { // 操作符默认值： 未被修改 直接获取后返回
        //         v = t;
        //         key = key;
        //     } else {
        //         //  操作符默认值： 已被修改 需要循环获取
        //         for (const currentKey in queryModel) {
        //             if (Object.prototype.hasOwnProperty.call(queryModel, currentKey)) {
        //                 const queryKey = this.removeOp(currentKey);
        //                 const formItemKey = this.removeOp(key);
        //                 if (queryKey === formItemKey) {
        //                     v = queryModel[currentKey];
        //                     key = currentKey;
        //                     break;
        //                 }
        //             }
        //         }
        //     }
        // }
        if (v) {
          // console.log('==== show field value:', entityField, v);
          if (entityField.DataType == 'Date') {
            if (entityField.DefaultValue == '@Today+1') {
              v = moment(v).add(1, 'day');
            } else if (entityField.DefaultValue == '@1st day of current month') {
              v = moment(new Date()).startOf('month');
            } else if (entityField.DefaultValue == '@Last day of current month') {
              v = moment(new Date()).endOf('month');
            }
            if (entityField.ValueFormat) {
              v = moment(v).format(entityField.ValueFormat);
            } else {
              v = moment(v).format('YYYYMMDD');
            }
          }
          // 参与Report特殊字段计算的条件不参与查询
          if (
            !entityField.QueryOptions ||
            (entityField.QueryOptions && !entityField.QueryOptions.SpecialConditions)
          ) {
            params.set(key, v);
            // console.log('==== 特殊字段计算 convert value:', key, v);
          }
        }
      }
      // if (withOp) {
      //     for (const key in queryModel) {
      //         if (Object.prototype.hasOwnProperty.call(queryModel, key)) {
      //             if (!params.has(key)) {
      //                 const element = queryModel[key];
      //                 params.set(key, element);
      //                 console.log('==== withOp convert value:', key, element);
      //             }
      //         }
      //     }
      // }
      const url = decodeURIComponent(params.toString());
      return url;
    }
    return '';
  }

  public buildPDFQueryParams(formItems, queryModel) {
    if (queryModel) {
      for (const item of formItems) {
        let key = item.dataField;
        const entityField = item.entityField;
        let v: any = queryModel[key];

        if (v) {
          if (entityField.DataType == 'Date') {
            if (entityField.DefaultValue == '@Today+1') {
              v = moment(v).add(1, 'day');
            } else if (entityField.DefaultValue == '@1st day of current month') {
              v = moment(new Date()).startOf('month');
            } else if (entityField.DefaultValue == '@Last day of current month') {
              v = moment(new Date()).endOf('month');
            }
            if (entityField.ValueFormat) {
              v = moment(v).format(entityField.ValueFormat);
            } else {
              v = moment(v).format('YYYYMMDD');
            }
          }
          queryModel[key] = v;
        }
      }
    }
    return queryModel;
  }

  private convertJsonType(col: any) {
    if (!col) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in col) {
      const val = col[att];
      if (val && !(val instanceof Array)) {
        if (val === 'true' || val === 'True') {
          col[att] = true;
        } else if (val === 'false' || val === 'False') {
          col[att] = false;
        } else if (!isNaN(Number(val))) {
          col[att] = Number(val);
        }
      }
    }
    return col;
  }

  public async getDict(code: string, editorType: string) {
    let dict = this.dictMap[code];
    if (!dict) {
      dict = await this.searchDictDataByCodeAndType(code, editorType);
      this.dictMap[code] = dict;
    }

    let dictionary = [];
    let isHasEmpty = false;
    for (const d in dict) {
      if (dict[d].Code == '') {
        isHasEmpty = true;
        break;
      }
    }
    if (!isHasEmpty && EditorType[editorType] == EditorType.SelectBox) {
      dictionary = [{ Code: '', Name: '', Order: 0 }].concat(dict);
    } else {
      dictionary = dict;
    }

    return dictionary;
  }

  public async searchDictDataByCodeAndType(code: string, editorType: string) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetDictionaryByCodeRequest();
    postData.Code = code;
    postData.IncludeDisable = false;
    const response = await client.get(postData);
    if (response.Dictionary) {
      response.Dictionary.forEach(data => (data.disabled = data.Disable));
    }
    // let dictionary = [];
    // console.log(editorType);
    // if(EditorType[editorType] == EditorType.SelectBox){
    //     dictionary = [{ Code: '', Name: '',Order:0 }].concat(response.Dictionary);
    // }
    // else{
    //     dictionary = response.Dictionary;
    // }
    return response.Dictionary;
  }

  public async searchDictDataByCode(code: string) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetDictionaryByCodeRequest();
    postData.Code = code;
    postData.IncludeDisable = false;
    const response = await client.get(postData);
    if (response.Dictionary) {
      response.Dictionary.forEach(data => (data.disabled = data.Disable));
    }
    return response.Dictionary;
  }

  public customerSummary(options) {
    if (options.name == 'distinctCount') {
      // let arr = [];
      switch (options.summaryProcess) {
        case 'start':
          options.arr = [];
          options.totalValue = 0;
          break;
        case 'calculate':
          if (options.value != null && options.arr.indexOf(options.value) == -1) {
            // options.totalValue = options.totalValue + options.value;
            options.totalValue++;
            options.arr.push(options.value);
          }
          break;
        case 'finalize':
          // Assigning the final value to "totalValue" here
          break;
      }
    }
    if (options.name == 'nonEmptyCount') {
      switch (options.summaryProcess) {
        case 'start':
          options.totalValue = 0;
          break;
        case 'calculate':
          if (options.value) {
            options.totalValue++;
          }
          break;
        case 'finalize':
          // Assigning the final value to "totalValue" here
          break;
      }
    }
  }

  public buildQueryItem(formItems, queryModel, queryItems, formInstance, innerThis) {
    const queryItemsRtn = [];
    const list = formItems.sort((a, b) => (a.visibleIndex > b.visibleIndex ? 1 : -1));
    const vm = this;
    const model = queryModel;
    if (list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (!element.visible) {
          continue; // 不可见查询条件不加载
        }
        const opField = 't_op_' + element.dataField;
        // debugger;
        let gp_items = [element];
        element.label.visible = false;
        element.cssClass = 'query_content_group';
        // debugger;
        const groupColSpan = element.initColSpan || element.colSpan || 1;
        if (!element.initColSpan) {
          element.initColSpan = element.colSpan || 1;
        }
        // console.log('===== parameters: ', element, element.colSpan, element.initColSpan, groupColSpan);
        const colSpan = 2;
        element.colSpan = colSpan;
        // debugger;
        model[opField] = model[opField]
          ? model[opField]
          : element.entityField.QueryOptions.Operator;
        // if (element.entityField.QueryOptions.DefaultValue) {
        //     debugger;
        //     model[element.dataField] = element.entityField.QueryOptions.DefaultValue;
        // }
        const opList = ToolService.getOpByComponent(element.editorType); // ToolService.getOpItems(); // JSON.parse(opDataSource);
        opList[0].id = element.dataField; // 为确定那个下拉框点击，手动加载的参数
        opList[0].fieldName = element.entityField.FieldName;
        if (!model[opField] && opList && opList.length > 0) {
          model[opField] = opList[0]['Code'];
        }
        element.disabled = ['IsNull', 'IsNotNull'].indexOf(model[opField]) > -1;
        const opOption = {
          dataField: opField,
          name: opField,
          label: {
            text: element.label.text,
            visible: false,
          },
          visible: true,
          editorType: 'dxSelectBox',
          editorOptions: {
            visible: true,
            dataSource: opList,
            displayExpr: 'Name',
            displayValue: 'Code',
            valueExpr: 'Code',
            onValueChanged: function (x) {
              formInstance(x, innerThis);
            },
          },
          visibleIndex: 0,
        };
        if (['CHECKBOX', 'DXCHECKBOX'].indexOf(element.editorType.toUpperCase()) > -1) {
          opOption.visible = false;
          opOption.editorOptions.visible = false;
          gp_items = [element, opOption];
        } else {
          gp_items = [opOption, element];
        }
        const group = {
          itemType: 'group',
          name: element.dataField,
          fieldName: element.entityField.FieldName,
          colCount: colSpan + 1,
          caption: element.label.text,
          items: gp_items,
          visibleIndex: element.visibleIndex, // index,
          cssClass: 'query_group',
          colSpan: groupColSpan,
          visible: element.visible,
          // formitem.label.location = itemQueryOption.labelLocation ? itemQueryOption.labelLocation : undefined;
        };
        queryItemsRtn.push(group);
      }
    }
    return queryItemsRtn;
  }

  public buildPostQueryModel(queryModel, report = null) {
    const postQueryModel = {};
    if (queryModel) {
      const model = queryModel;
      for (const key in model) {
        if (Object.prototype.hasOwnProperty.call(model, key)) {
          const prefix = 't_op_';
          if (key.indexOf(prefix) > -1) {
            const rField = key.replace(prefix, '');
            const field = this.removeOp(rField); // this.reportService.removeOp(key);
            const op = model[key]; // 操作符取值
            const newFieldName = field + this.getOp(op);
            if (['IsNull', 'IsNotNull'].indexOf(op) > -1) {
              postQueryModel[newFieldName] = true;
            } else {
              if (model[rField]) {
                postQueryModel[newFieldName] = model[rField];
              }
            }
          }
        }
      }
    }
    return postQueryModel;
  }

  public queryOpFn(queryModel, queryitem, vm) {
    if (!queryModel) {
      return;
    }

    for (const att in queryModel) {
      const v = queryModel[att];
      if (att.indexOf('t_op_') > -1) {
        const samelist = queryitem.filter(q => {
          return q.items[0].name == att;
        });
        if (samelist && samelist.length > 0) {
          samelist.filter(f => {
            const comp = f['items'][1];
            let isDisabled = false;
            if (['IsNull', 'IsNotNull'].indexOf(v) > -1) {
              isDisabled = true;
              if (Array.isArray(vm.queryModel[comp.dataField])) {
                vm.queryModel[comp.dataField] = [];
              } else {
                vm.queryModel[comp.dataField] = '';
              }
            }
            comp.disabled = isDisabled;
          });
        }
      }
    }

    vm.form.instance.repaint();
  }

  private parseJsonObject(obj, defaultValue = {}) {
    if (!obj) {
      return defaultValue;
    } else {
      return JSON.parse(obj);
    }
  }

  private updaterOptions(options: any) {
    if (!options) {
      return {};
    }
    for (const key in options) {
      if (options[key] && options[key].indexOf && options[key].indexOf('{') == 0) {
        options[key] = JSON.parse(options[key]);
      }
    }
    return options;
  }

  public getOp(opchar) {
    if (!opchar) {
      return '';
    }
    switch (opchar) {
      case '=': {
        return '';
      }
      case 'StartsWith': {
        return 'StartsWith';
      }
      case 'EndsWith': {
        return 'EndsWith';
      }
      case '!=': {
        return '!';
      }
      case '>': {
        return 'GreaterThan';
      }
      case '>=': {
        return 'GreaterThanOrEqualTo';
      }
      case '<': {
        return 'LessThan';
      }
      case '<=': {
        return 'LessThanOrEqualTo';
      }
      case 'In': {
        return 'In';
      }
      case 'Contains': {
        return 'Contains';
      }
      case 'IsNull': {
        return 'IsNull';
      }
      case 'IsNotNull': {
        return 'IsNotNull';
      }
      default:
        return '';
    }
  }

  public removeOp(fieldWithOp) {
    const opList = [
      'StartsWith',
      'EndsWith',
      '!',
      'GreaterThanOrEqualTo',
      'GreaterThan',
      'LessThanOrEqualTo',
      'LessThan',
      'In',
      'Contains',
      'IsNotNull',
      'IsNull',
    ];
    // const opList = str.replace(/(.*)【空】/,'$1测试');
    let f = '';
    if (fieldWithOp) {
      for (let index = 0; index < opList.length; index++) {
        const op = opList[index];
        const lastIndex = fieldWithOp.lastIndexOf(op);
        if (lastIndex > -1) {
          f = fieldWithOp.substring(0, lastIndex);
        }
      }
    }
    const fieldName = f ? f : fieldWithOp;
    return fieldName;
  }

  public async getRemoteReport(reportCode) {
    const response = await this.getRemoteReportByBase(reportCode);
    if (response.IsSuccess) {
      const wkentity = new WksEntity();
      wkentity.EntityType = response.EntityType;
      wkentity.EntityFields = response.Fields;
      wkentity.Fields = wkentity.EntityFields.filter(f => f.FieldName && f.Disable == false);
      wkentity.FieldMap = wkentity.Fields.reduce((map, f) => ((map[f.FieldName] = f), map), {});
      return wkentity;
    } else {
      return null;
    }
  }

  public async getRemoteReportByBase(reportCode) {
    const cacheKey = 'GetEntityReportTypeByIdRequest_' + reportCode;
    let response = this.cacheReportService.getCacheData(cacheKey);
    if (!response) {
      // response = await this.getEntityReportTypeById(reportCode);
      const client = this.factory.MctCareWaitClient;
      const postData = new GetEntityReportTypeByIdRequest();
      postData.Id = reportCode;
      const response = await client.get(postData);
      const cloneItem = JSON.parse(JSON.stringify(response));
      this.cacheReportService.setCacheData(cacheKey, cloneItem);
      return response;
    }
    return response;
  }

  public convertToWksReport(rpt: Report): WksReport {
    const report = new WksReport();
    report.Id = rpt.Id;
    report.Code = rpt.Code;
    report.Name = rpt.Name;
    report.Type = rpt.Type;
    report.Description = rpt.Description;
    report.QueryForm = this.parseJsonObject(rpt.QueryForm, {
      Form: {},
      Items: [],
    });
    report.LayoutOptions = this.parseJsonObject(rpt.LayoutOptions, {
      DataGrid: {},
      Columns: [],
    });
    report.DataSource = rpt.DataSource;
    return report;
  }

  // public saveOptions(reportId, optionName, model) {
  // }

  public async getQueryDataId(reportCode, reportType) {
    const response = await this.getQueryDataIdData(reportCode, reportType);
    if (response.IsSuccess) {
      return response.Data;
    } else {
      return null;
    }
  }

  public async getQueryDataIdData(reportCode, reportType) {
    const cacheKey = reportCode + '_' + reportType;
    let response = this.cacheReportService.getCacheData(cacheKey);
    if (!response) {
      const client = this.factory.MctCareWaitClient;
      const postData = new GetDefaultQueryRequest();
      postData.IncludeDisable = false;
      postData.Apply = 1;
      postData.ReportDataSourceCode = reportCode;
      postData.OwnerType = reportType;
      const response = await client.get(postData);
      this.cacheReportService.setCacheData(cacheKey, response);
      return response;
    }
    return response;
  }

  public getChildProviderVacancyOfferReport(data) {
    const wkentity = new WksEntity();
    wkentity.EntityType = data.EntityType;
    wkentity.EntityFields = data.Fields;
    wkentity.Fields = wkentity.EntityFields.filter(f => f.FieldName && f.Disable == false);
    wkentity.FieldMap = wkentity.Fields.reduce((map, f) => ((map[f.FieldName] = f), map), {});
    let report = wkentity;
    report.Columns = this.entityService.getDataGridColumns(
      report.EntityFields.filter(f => f.Disable == false && f.Apply == 0),
    );
    report.ColumnFormItems = this.getDxFormItems(
      report.EntityFields.filter(f => f.Disable == false && f.Apply == 0),
      0,
    );
    report.FormOptions = report.EntityType
      ? report.EntityType.DefaultFormOptions
        ? report.EntityType.DefaultFormOptions
        : { labelLocation: 'top', colCount: 3 }
      : {};
    report.FormItems = this.getDxFormItems(
      report.EntityFields.filter(f => f.Disable == false && f.Apply == 1),
      1,
    );
    report.DefaultModel = this.getDefaultModel(
      report.EntityFields.filter(f => f.Disable == false && f.Apply == 1),
    );
    return report;
  }

  public setReportPdfUrl(fileUrl): void {
    this.reportPdfUrl.next(fileUrl);
  }

  public getReportPdfUrl(): {} {
    return this.reportPdfUrl.getValue();
  }

  public setReportPdfLoaded(status: number): void {
    this.reportPdfLoaded.next(status);
  }

  public getReportPdfLoaded(): number {
    return this.reportPdfLoaded.getValue();
  }

  public setgetRefreshPdf(fileUrl): void {
    this.refreshPdf.next(fileUrl);
  }

  public getRefreshPdf(): boolean {
    return this.refreshPdf.getValue();
  }
}
