<div class="d-flex">
  <div class="mr-1">
    <label for="{{type.editorOptions.inputAttr.id+'_month'}}" class="yearmonthday">{{item.label.text}} Month</label>
    <dx-select-box [inputAttr]="{ 'id': type.editorOptions.inputAttr.id+'_month'}"
      (onValueChanged)="dateClick($event,'month',item.dataField,true)" [dataSource]="monthDataSource" valueExpr="Code"
      displayExpr="Name" [readOnly]="readOnly" placeholder="" searchEnabled="true"
      [(value)]="formData[item.dataField+'___month']">

      <dx-validator [validationGroup]="validationGroup">
        <dxi-validation-rule  *ngIf="item.isRequired"  type="required" message="{{item.label.text}} month is required"></dxi-validation-rule>
        <dxi-validation-rule  *ngIf="!item.isRequired && (!formData[item.dataField+'___month']) && (formData[item.dataField+'___year'] || formData[item.dataField+'___day'])"  type="required" message="{{item.label.text}} month is required"></dxi-validation-rule>
      </dx-validator>

    </dx-select-box>
  </div>
  <div class="flex-grow-1">
    <div class="mr-1">
      <label for="{{type.editorOptions.inputAttr.id+'_day'}}" class="yearmonthday">{{item.label.text}} Day</label>
      <dx-select-box [inputAttr]="{ 'id': type.editorOptions.inputAttr.id+'_day'}"
        (onValueChanged)="dateClick($event,'day',item.dataField,true)" [dataSource]="dayDataSource" valueExpr="Code"
        displayExpr="Name" [readOnly]="readOnly" placeholder="" searchEnabled="true"
        [(value)]="formData[item.dataField+'___day']">
        <dx-validator [validationGroup]="validationGroup">
          <dxi-validation-rule *ngIf="item.isRequired"  type="required" message="{{item.label.text}} day is required"></dxi-validation-rule>
          <dxi-validation-rule  *ngIf="!item.isRequired && (!formData[item.dataField+'___day']) && (formData[item.dataField+'___year'] || formData[item.dataField+'___month'])"  type="required" message="{{item.label.text}} day is required"></dxi-validation-rule>

          <!-- <dxi-validation-rule type="custom" [validationCallback]="validateDateByDay">
          </dxi-validation-rule> -->
        </dx-validator>
      </dx-select-box>
    </div>
  </div>
  <div class="flex-grow-1">
    <div>
      <label for="{{type.editorOptions.inputAttr.id+'_year'}}" class="yearmonthday">{{item.label.text}} Year</label>
      <dx-select-box [inputAttr]="{ 'id': type.editorOptions.inputAttr.id+'_year'}"
        (onValueChanged)="dateClick($event,'year',item.dataField,true)" [dataSource]="yearDataSource" valueExpr="Code"
        displayExpr="Name" [readOnly]="readOnly" placeholder="" searchEnabled="true" searchTimeout="100"
        [(value)]="formData[item.dataField+'___year']">
        <dx-validator [validationGroup]="validationGroup">
          <dxi-validation-rule *ngIf="item.isRequired"  type="required" message="{{item.label.text}} year is required"></dxi-validation-rule>
          <dxi-validation-rule  *ngIf="!item.isRequired && (!formData[item.dataField+'___year']) && (formData[item.dataField+'___month'] || formData[item.dataField+'___day'])"  type="required" message="{{item.label.text}} year is required"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>
  </div>

</div>