import { Component, OnInit, ViewChild } from '@angular/core';
declare var $: any;

import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { AuthService } from '../../core/auth/auth.service';

import { User } from '../../core/models/user.model';
import { catchError, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { LogService } from '../../core/log/log.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { MockService } from '../../core/models/billing.api';
import { Router, ActivatedRoute } from '@angular/router';
import { sfWkCodeList } from '../../core/models/common.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isAuthenticated: boolean;
  currentUser: User;

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  hlayout = false;
  selectedLanguageName: any;
  langList: { code: string; text: string }[];
  mockService: MockService;
  // isNavSearchVisible: boolean;
  // @ViewChild('fsbutton') fsbutton;  // the fullscreen button
  containHome = true;
  wkCode: any;
  isStanfordWkCode: boolean;

  constructor(
    public menu: MenuService,
    public logger: LogService,
    public auth: AuthService,
    public settings: SettingsService,
    public translator: TranslatorService,
    mockService: MockService,
    public route: ActivatedRoute,
  ) {
    // debugger;
    this.mockService = mockService;
    // this.menu.getNav().then(menus => {
    //     this.menuItems = menus
    // });
    // this.menuItems = menu.getNav();
    auth.currentUser.subscribe(u => this.loadUser(u));
    this.hlayout = settings.getLayoutSetting('horizontal');
    this.selectedLanguageName = translator.getLanguageName();
    // this.wkCode = route.snapshot.params.wkcode;
    menu.changeMenu.subscribe(u => {
      if (u) {
        this.menu.getNav(true).then(menus => {
          this.menuItems = menus;
        });
      }
    });

    this.getLangs();
  }

  getLangs() {
    this.langList = this.translator.getAvailableLanguages();
    return this.langList;
  }

  setLang(item) {
    const value = item.code;
    this.selectedLanguageName = item.text;
    this.translator.useLanguage(value);
  }

  ngOnInit() {
    this.wkCode = this.route.snapshot.params.wkcode
      ? this.route.snapshot.params.wkcode
      : this.route.firstChild.snapshot.params.wkcode;
    this.isStanfordWkCode = sfWkCodeList.filter(f => f == this.wkCode.toLowerCase()).length > 0;
    this.logger.debug('heard nav component init');
    const homeUrl = window.location.href;
    if (homeUrl.indexOf('home') > 0 || homeUrl.indexOf('parent') > 0) {
      this.containHome = true;
    } else {
      this.containHome = false;
    }
  }

  logout() {
    this.logger.debug('logout');
    this.isAuthenticated = false;
    this.auth.logout(this.wkCode);
    this.currentUser = null;
  }

  loadUser(u: User) {
    const user = this.mockService.GetCurrentUser();
    if (user && user.Data) {
      u = user.Data as User;
    }
    if (u) {
      this.logger.debug('Heard: load user');
      this.isAuthenticated = true;
      this.currentUser = u;
      const path =
        this.route.snapshot.url.length > 0
          ? this.route.snapshot.url[1].path
          : this.route.snapshot.firstChild.url[1].path;
      if (path != 'parent') {
        this.menu.getNav(true).then(menus => {
          this.menuItems = menus;
        });
      } else if (path == 'parent') {
        this.menu.getParentNav().then(menus => {
          this.menuItems = menus;
        });
      }
    } else {
      this.logger.debug('Heard: User is null');
      this.isAuthenticated = false;
      this.currentUser = null;
    }
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  // toggleUserBlock(event) {
  //     event.preventDefault();
  //     // this.userblockService.toggleVisibility();
  // }

  // openNavSearch(event) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     this.setNavSearchVisible(true);
  // }

  // setNavSearchVisible(stat: boolean) {
  //     // console.log(stat);
  //     this.isNavSearchVisible = stat;
  // }

  // getNavSearchVisible() {
  //     return this.isNavSearchVisible;
  // }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  getLinkUrl(url) {
    const isParent = location.href.indexOf('/parent') > -1;
    const istodashboard = url.indexOf('/dashboard') > -1;
    let addUrl = '';
    if (istodashboard) {
      addUrl = isParent ? '/parent' : url;
    } else {
      addUrl = isParent ? '/parent/login' : url;
    }

    const wkcodeurl = this.wkCode ? '/' + this.wkCode : '';
    url = wkcodeurl + addUrl;
    return [url];
  }
}
