import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.h.html',
  styleUrls: ['./layout.component.scss'],
})
export class HLayoutComponent implements OnInit {
  constructor(private router: Router, public settings: SettingsService) {
    settings.setLayoutSetting('horizontal', true);
  }

  ngOnInit() {}
}
