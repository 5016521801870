/*
Common method class for called everywhere
*/
import moment from 'moment';

export class ToolService {
  public static isFunction(fn: any): boolean {
    if (fn instanceof Function) {
      return true;
    }
    return false;
  }

  public static getEnvironment(): string {
    let env = 'live';
    const url = location.host;
    if (url.indexOf('localhost:') > -1) {
      env = 'dev';
    } else if (url.indexOf('shtest-carewait.mcttechnology.cn') > -1) {
      env = 'shtest';
    } else if (url.indexOf('ustest-carewait-v2.carecloud.io') > -1) {
      env = 'ustest';
    } else {
      env = 'live';
    }
    return env;
  }

  public static isMobile(): Boolean {
    const exg = /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i;
    // const isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
    const isMobile = exg.test(navigator.userAgent) || window.innerWidth <= 500;
    return isMobile;
  }

  public static getfilename(filename: any): string {
    return filename + moment().format('YYYYMMDDHHmmss');
  }

  public static isArray(fn: any): boolean {
    if (fn instanceof Array) {
      return true;
    }
    return false;
  }

  public static isString(fn: any): boolean {
    if (fn instanceof String) {
      return true;
    }
    return false;
  }

  public static getGuid(): string {
    return Guid.newGuid().toString();
  }
  public static trimAll(str, symbol = ''): string {
    symbol = symbol ? symbol : '';
    return str ? str.replace(/\s+/g, symbol) : '';
  }
  public static trimBoth(str, symbol = ''): string {
    symbol = symbol ? symbol : '';
    return str ? str.replace(/^\s+|\s+$/g, symbol) : '';
  }

  public static trimLeft(str, symbol = ''): string {
    symbol = symbol ? symbol : '';
    return str ? str.replace(/^\s*/, symbol) : '';
  }

  public static trimRight(str, symbol = ''): string {
    symbol = symbol ? symbol : '';
    return str ? str.replace(/(\s*$)/g, symbol) : '';
  }

  public static getValidateConfig(): any {
    const config = {
      phone: {
        mask: '(000) 000-0000 x 999999999999999',
        pattern: /(^\s*$)|(^\(\d{3}\)\s*\d{3}\s*-\s*\d{4}(\s*x\s*\d{0,15})*$)/,
        maskInvalidMessage: 'PHONE_IS_INVALID',
        // this.translater.transform('PHONE_NUMBER_IS_INVALID'), // 'Phone number is invalid.',
        useMaskedValue: true,
        showMaskMode: 'onFocus',
        validationMessageMode: 'always',
        maskChar: '0',
      },
      fax: {
        mask: '(000) 000-0000',
        pattern: /(^\s*$)|(^\(\d{3}\)\s*\d{3}\s*-\s*\d{4}$)/,
        maskInvalidMessage: 'FAX_NUMBER_IS_INVALID',
        // this.translater.transform('NUMBER_FORMAT_IS_INVALID'), // 'Number format is invalid.',
        useMaskedValue: true,
        showMaskMode: 'onFocus',
        validationMessageMode: 'always',
        maskChar: '0',
      },
      zip: {
        mask: '00000-9999',
        pattern: /(^\s-*$)|(^\d{5}(-\d{0,4})*$)/,
        maskInvalidMessage: 'ZIP_IS_INVALID',
        // this.translater.transform('ZIP_CODE_IS_INVALID'), // 'Zip Code is invalid.',
        useMaskedValue: true,
        showMaskMode: 'onFocus',
        validationMessageMode: 'always',
        maskChar: '0',
      },
      cell: {
        mask: '(000) 000-0000',
        pattern: /(^\s*$)|(^\(\d{3}\)\s*\d{3}\s*-\s*\d{4}$)/,
        maskInvalidMessage: 'CELL_IS_INVALID',
        // this.translater.transform('NUMBER_FORMAT_IS_INVALID'), // 'Number format is invalid.',
        useMaskedValue: true,
        showMaskMode: 'onFocus',
        validationMessageMode: 'always',
        maskChar: '0',
      },
      email: {
        mask: '',
        pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{1,5}$/,
        maskInvalidMessage: 'Email is invalid',
        // this.translater.transform('EMAIL_ADDRESS_IS_INVALID'), // 'Email address is invalid.',
        useMaskedValue: true,
        showMaskMode: 'onFocus',
        validationMessageMode: 'always',
        maskChar: '0',
      },
    };
    return config;
  }

  public static isArrayRepeate(arr: any[]): boolean {
    // tslint:disable-next-line:prefer-const
    let hash = {};
    if (arr && arr.length > 0) {
      // tslint:disable-next-line:forin
      for (let i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        hash[arr[i]] = true;
      }
    }
    return false;
  }

  public static uniqArray(arr: any[]) {
    if (!this.isArray(arr)) {
      return [];
    }
    // tslint:disable-next-line:prefer-const
    let temp = {},
      len = arr.length;
    for (let i = 0; i < len; i++) {
      if (typeof temp[arr[i]] === 'undefined') {
        temp[arr[i]] = 1;
      }
    }
    arr.length = 0;
    len = 0;
    // tslint:disable-next-line:forin
    for (let i in temp) {
      arr[len++] = i;
    }
    return arr;
  }

  public static fieldSorter(fields: any[]) {
    return function (a, b) {
      return fields
        .map(function (o) {
          let dir = 1;
          if (o[0] === '-') {
            dir = -1;
            o = o.substring(1);
          }
          if (a[o] > b[o]) {
            return dir;
          }
          if (a[o] < b[o]) {
            return -dir;
          }
          return 0;
        })
        .reduce(function firstNonZeroValue(p, n) {
          return p ? p : n;
        }, 0);
    };
  }

  public static convertLogonName(email: string): string {
    let logonName = email; // for +1-3156281288, geminzhang@126.com
    if (email.indexOf('@') === -1) {
      if (email.indexOf('+1-') === -1) {
        // for 3156281288
        logonName = '+1-' + email;
      }
      if (email.indexOf('1-') === 0) {
        // for 1-3156281288
        logonName = '+' + email;
      }
      if (email.indexOf('-') === 0) {
        // for -3156281288
        logonName = '+1' + email;
      }
    }
    return logonName;
  }

  public static convertUpperJson(data: any) {
    const vm = this;
    if (data) {
      for (let key in data) {
        // console.log(key);
        let upKey = key.slice(0, 1).toUpperCase() + key.slice(1); // key.toLocaleUpperCase();
        data[upKey] = data[key];
        let upData = data[upKey];
        if (!(data instanceof Array)) {
          delete data[key];
        }
        if (upData == '[object Object]' || upData instanceof Array) {
          upData = vm.convertUpperJson(upData);
        }
      }
    }
    return data;
  }

  public static getAge(date1, date2, intervals = null) {
    if (!date1 || !date2) {
      return '';
    }
    const b = moment(date1),
      a = moment(date2);
    intervals = intervals || ['y', 'M']; // ['years', 'months', 'weeks', 'days'];
    const out = [];
    intervals.forEach(function (interval) {
      const diff = a.diff(b, interval);
      b.add(diff, interval);
      // out.push(diff + ' ' + interval.singularize(diff));
      out.push(diff + ' ' + String(interval).toLowerCase());
    });
    return out.join(' - ');
  }

  public static getMutilpleValue(values) {
    var rtn = 0;
    if (values && values.length > 0) {
      for (var i = 0; i < values.length; i++) {
        var v = parseInt(values[i]);
        rtn += v;
      }
    }
    return rtn;
  }

  public static paraseMutilpleValue(number, source) {
    // var weekSchedule = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    var length = source ? source.length : 9;
    var values = [];

    if (number) {
      for (var j = 0; j < length; j++) {
        var v = Math.pow(2, j);
        if ((number & v) == v) {
          values.push(v);
        }
      }
    } else {
      return [];
    }
    return values;
  }

  public static paraseMutilpleItems(number, source) {
    // var weekSchedule = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    var length = source ? source.length : 9;
    var values = [];

    if (number) {
      for (var j = 0; j < length; j++) {
        var v = Math.pow(2, j);
        if ((number & v) == v) {
          values.push(source[j]);
        }
      }
    } else {
      return [];
    }
    return values;
  }

  private static op = [
    { name: '=', code: '=', type: 2 },
    { name: '!=', code: '!=', type: 2 },
    { name: '>=', code: '>=', type: 3 },
    { name: '>', code: '>', type: 3 },
    { name: '<=', code: '<=', type: 3 },
    { name: '<', code: '<', type: 3 },
    { name: 'In', code: 'In', type: 1 },
    { name: 'Contains', code: 'Contains', type: 4 },
    { name: 'Start With', code: 'StartsWith', type: 4 },
    { name: 'End With', code: 'EndsWith', type: 4 },
    { name: 'Blank', code: 'IsNull', type: 0 },
    { name: 'Not Blank', code: 'IsNotNull', type: 0 },
  ];
  /*
    type: 根据type获取,要列出的操作符；
    expect: 特意排除某些不需要的操作符；
  */
  private static getOpItems(type = [0, 1, 2, 3], expect = []) {
    const items = [];
    // this.op = ['=', '>=', '>', '<=', '<', '!=', 'In', 'IsNull', 'IsNotNull', 'Contains', 'StartsWith', 'EndsWith'];
    this.op.forEach(x => {
      if (type.indexOf(x.type) > -1) {
        if (expect && expect.length > 0 && expect.indexOf(x.code) > -1) {
        } else {
          items.push({ Name: x.name, Code: x.code });
        }
      }
    });
    return items;
  }

  public static getOpByComponent(value) {
    let items = [];
    let type = [];
    let excpt = [];
    if (value) {
      value = value.toUpperCase();
      if (value.indexOf('DX') === 0) {
        value = value.replace('DX', ''); // 合并dxDateBox, DateBox 2种不同类型表示
      }
      if (value == 'TEXTBOX') {
        type = [0, 2, 4];
      } else if (value == 'DATEBOX') {
        type = [0, 2, 3];
      } else if (value == 'NUMBERBOX') {
        type = [2, 3];
      } else if (value == 'SELECTBOX') {
        type = [0, 1];
      } else if (value == 'CHECKBOX') {
        type = [2];
        excpt = ['!='];
      } else if (value == 'TAGBOX') {
        type = [0, 1];
      }
    }
    items = this.getOpItems(type, excpt);
    return items;
  }

  public static formatOp(value) {
    if (value) {
      const t = this.op.find(x => {
        return x.code === value;
      });
      if (t && t.name) {
        return t.name;
      }
    }
    return '';
  }
}

//#region Third Part Class
// https://gist.github.com/emptyother/1fd97db034ef848f38eca3354fa9ee90
export class Guid {
  public static newGuid(): Guid {
    return new Guid(
      'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        // tslint:disable-next-line:no-bitwise
        const r = (Math.random() * 16) | 0;
        // tslint:disable-next-line:triple-equals
        // tslint:disable-next-line:no-bitwise
        // tslint:disable-next-line:triple-equals
        // tslint:disable-next-line:no-bitwise
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }),
    );
  }
  public static get empty(): string {
    return '00000000-0000-0000-0000-000000000000';
  }
  public get empty(): string {
    return Guid.empty;
  }
  public static isValid(str: string): boolean {
    const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return validRegex.test(str);
  }
  // tslint:disable-next-line:member-ordering
  private value: string = this.empty;
  constructor(value?: string) {
    if (value) {
      if (Guid.isValid(value)) {
        this.value = value;
      }
    }
  }
  public toString() {
    return this.value;
  }

  public toJSON(): string {
    return this.value;
  }
}
//#endregion
