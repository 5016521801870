<tabset [justified]="true">
  <tab>
    <ng-template tabHeading>
      <em class="icon-equalizer fa-lg"></em>
    </ng-template>
    <h3
      class="text-center text-thin mt-4"
      [innerHTML]="('SETTINGS' | translate) || 'Settings'"
    ></h3>
    <div class="p-2">
      <h4 class="text-muted text-thin">
        <a class="text-muted" style="display: block" href [routerLink]="getProfileUrl()">
          {{ ('LOGIN_INFO' | translate) || 'Login Info' }}
          <span class="float-right text-muted"> ></span>
        </a>
      </h4>
      <p *ngIf="!isStanford">{{ ('EMAIL' | translate) || 'Email' }}: {{ currentUser?.Email }}</p>
      <p *ngIf="!isStanford">{{ ('PHONE' | translate) || 'Phone' }}: {{ currentUser?.Phone }}</p>
    </div>
    <div class="p-2 mt-3">
      <h4 class="text-muted text-thin">
        <a class="text-muted" style="display: block" href [routerLink]="getPassWordUrl()">
          {{ ('CHANGE_PASSWORD' | translate) || 'Change Password' }}
          <span class="float-right text-muted"> ></span>
        </a>
      </h4>
    </div>

    <div class="p-2 mt-3" *ngIf="!isStanford">
      <h4 class="text-muted text-thin">{{ ('LANGUAGE' | translate) || 'Language' }}</h4>
      <select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
        <option [value]="lang.code" *ngFor="let lang of getLangs()">{{ lang.text }}</option>
      </select>
    </div>
    <!-- <div class="p-2 mt-3">
            <h4 class="text-muted text-thin">Themes</h4>
            <div class="row row-flush mb-2">
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="A" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-info"></span>
                                <span class="color bg-info"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="B" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-primary"></span>
                                <span class="color bg-primary"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="C" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-purple"></span>
                                <span class="color bg-purple"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="D" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-success"></span>
                                <span class="color bg-success"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row row-flush mb-2">
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="E" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-cyan-500"></span>
                                <span class="color bg-cyan-500"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="F" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-pink-500"></span>
                                <span class="color bg-pink-500"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="G" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-blue-grey-500"></span>
                                <span class="color bg-blue-grey-500"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="setting-color">
                        <label>
                            <input type="radio" name="setting-theme" [(ngModel)]="currentTheme"
                                (ngModelChange)="setTheme()" value="H" />
                            <span class="icon-check"></span>
                            <span class="split">
                                <span class="color bg-blue-grey-900"></span>
                                <span class="color bg-blue-grey-900"></span>
                            </span>
                            <span class="color bg-white"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="p-2 mt-3" *ngIf="!isStanford">
      <h4 class="text-muted text-thin">{{ ('THEMES' | translate) || 'Themes' }}</h4>
      <div class="row row-flush mb-2">
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Normal"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-success"></span>
                <span class="color bg-success"></span>
              </span>
              <span class="color bg-white text-center"><small>Normal</small></span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Normal.Dark"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-blue-grey-900"></span>
                <span class="color bg-blue-grey-900"></span>
              </span>
              <span class="color bg-white text-center"><small>Normal</small></span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Normal.Blue"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-info"></span>
                <span class="color bg-info"></span>
              </span>
              <span class="color bg-white text-center"><small>Normal</small></span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Stanford"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-red-A800"></span>
                <span class="color bg-red-A800"></span>
              </span>
              <span class="color bg-white text-center"><small>Normal</small></span>
            </label>
          </div>
        </div>
      </div>
      <div class="row row-flush mb-2">
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Compact"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-success"></span>
                <span class="color bg-success"></span>
              </span>
              <span class="color bg-white text-center"><small>Compact</small></span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Compact.Dark"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-blue-grey-900"></span>
                <span class="color bg-blue-grey-900"></span>
              </span>
              <span class="color bg-white text-center"><small>Compact</small></span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Compact.Blue"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-info"></span>
                <span class="color bg-info"></span>
              </span>
              <span class="color bg-white text-center"><small>Compact</small></span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input
                type="radio"
                name="setting-theme"
                [(ngModel)]="currentTheme"
                (ngModelChange)="setTheme()"
                value="Compact.Stanford"
              />
              <span class="icon-check"></span>
              <span class="split">
                <span class="color bg-red-A800"></span>
                <span class="color bg-red-A800"></span>
              </span>
              <span class="color bg-white text-center"><small>Compact</small></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="p-2">
            <h4 class="text-muted text-thin">Language</h4>
            <select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
                <option [value]="lang.code" *ngFor="let lang of getLangs()">{{lang.text}}</option>
            </select>
        </div> -->
    <!-- <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated chang">
                <a class="dropdown-item" *ngFor="let item of langList" dropdown (click)="setLang(item)">
                    <span>{{item.text}}</span>
                </a>
        </div>
        <div class="p-2 text-center">
                <button mat-raised-button class="btn btn-outline-danger" (click)="viewProfile()" >View Profile</button>
            </div>
        <div class="p-2 text-center">
            <button mat-raised-button class="btn btn-outline-danger" (click)="resetPassword()" >Change Password</button>
        </div> -->
    <div class="p-2 text-center" style="margin-top: 20px">
      <dx-button icon="clearformat" text="Clear Cache" width="100%" (onClick)="clearCache()">
      </dx-button>
      <!-- <button mat-raised-button class="btn btn-outline-danger" (click)="clearCache()">{{('CLEAR_CACHE' | translate) || 'Clear Cache'}}</button> -->
    </div>
    <div class="p-2 text-center" style="margin-top: 150px">
      <dx-button
        width="100%"
        type="default"
        text="{{ ('LOG_OUT' | translate) || 'Log Out' }}"
        (onClick)="logOut()"
      >
      </dx-button>
      <!-- <button mat-raised-button class="btn btn-outline-danger" (click)="logOut()">{{('LOG_OUT' | translate) || 'Log Out'}}</button> -->
    </div>
  </tab>
  <tab *ngIf="!isStanford">
    <ng-template tabHeading>
      <em class="icon-user fa-lg"></em>
    </ng-template>
    <h3 class="text-center text-thin mt-4">{{ ('CONTACTS' | translate) || 'Contacts' }}</h3>
  </tab>
  <!-- <tab>
        <ng-template tabHeading>
            <em class="icon-user fa-lg"></em>
        </ng-template>
        <h3 class="text-center text-thin mt-4">Connections</h3>
        <div class="list-group">
            <div class="list-group-item border-0">
                <small class="text-muted">ONLINE</small>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/customer/logo.jpg"
                        alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                            <strong>OECE</strong>
                        </a>
                        <br>
                        <small class="text-muted"><em class="fa fa-phone"></em>: (123) 123 - 1234</small>
                        <br>
                        <small class="text-muted"><em class="fa fa-envelope"></em>: abc@school.com</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-success circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/customer/logo.jpg"
                        alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                            <strong>ABC School</strong>
                        </a>
                        <br>
                        <small class="text-muted"><em class="fa fa-phone"></em>: (123) 123 - 1234</small>
                        <br>
                        <small class="text-muted"><em class="fa fa-envelope"></em>: abc@school.com</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-success circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/customer/logo.jpg"
                        alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                            <strong>BFE Agency</strong>
                        </a>
                        <br>
                        <small class="text-muted"><em class="fa fa-phone"></em>: (123) 123 - 1234</small>
                        <br>
                        <small class="text-muted"><em class="fa fa-envelope"></em>: abc@school.com</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-success circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item border-0">
                <small class="text-muted">OFFLINE</small>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/customer/logo.jpg"
                        alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                            <strong>After School</strong>
                        </a>
                        <br>
                        <small class="text-muted"><em class="fa fa-phone"></em>: (123) 123 - 1234</small>
                        <br>
                        <small class="text-muted"><em class="fa fa-envelope"></em>: abc@school.com</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-gray circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/customer/logo.jpg"
                        alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                            <strong>CBO</strong>
                        </a>
                        <br>
                        <small class="text-muted"><em class="fa fa-phone"></em>: (123) 123 - 1234</small>
                        <br>
                        <small class="text-muted"><em class="fa fa-envelope"></em>: abc@school.com</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-gray circle-lg"></span>
                    </div>
                </div>
            </div>
        </div>
    </tab> -->
</tabset>
