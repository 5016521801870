import { Injectable } from '@angular/core';
import { Menu } from '../models/menu.model';
import { JsonClientFactoryService } from '../../core/carecloud/JsonClientFactory.service';
import { WorkspaceGuardService } from '../auth/workspace.guard.service';
import { EntityCacheService } from '../../core/services/cacheService';
import { GetAllDefaultQueryRequest } from '../../core/models/report.api';
import { BehaviorSubject } from 'rxjs';
import { CacheService } from '../services/cacheService';

@Injectable()
export class MenuService {
  public changeMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  menuMap: { [name: string]: Array<Menu> } = {};
  menuNav: Menu[] = [];
  promiseReturn: any;
  promiseMenuReturn: any;

  // menuItems: Array<Menu>;

  constructor(
    private factory: JsonClientFactoryService,
    private workspaceService: WorkspaceGuardService,
    private cacheService: EntityCacheService,
  ) {
    // this.menuItems = [];
    this.cacheService = new CacheService();
    this.menuMap = {};
  }

  public setChangeMenu(isChange): void {
    this.removeMenuLocalStorage();
    this.changeMenu.next(isChange);
  }

  public getChangeMenu(): boolean {
    return this.changeMenu.getValue();
  }

  addMenu(name: string, items: Menu[]) {
    // items.forEach((item) => {
    //     this.menuItems.push(item);
    // });
    this.menuMap[name] = items;
  }

  addNav(menu: Menu) {
    this.menuNav.push(menu);
  }

  public async getMenu(name: string, isForceRefresh = false) {
    // return this.menuItems;
    let menus = isForceRefresh ? null : this.menuMap[name];
    if (menus) {
      return menus;
    } else {
      // const workspace = this.workspaceService.getCurrentWorkspace();
      // if (workspace) {}
      const menu = {
        text: name,
        name: name,
        heading: false,
        submenu: [],
      };
      const workspace = this.workspaceService.getCurrentWorkspace();
      const workspacecode = workspace && workspace.Code ? workspace.Code : '';
      menus = await this.getMenus(workspacecode);
      for (const i in menus) {
        if (menus[i].name == name) {
          menu.submenu = menus[i].submenu;
          break;
        }
      }
      this.addMenu(menu.name, menu.submenu);
      return this.menuMap[name];
    }
  }

  public async getParentNav() {
    let menuNav = this.menuNav;
    if (menuNav && menuNav.length > 0) {
      return menuNav;
    } else {
      const workspace = this.workspaceService.getCurrentWorkspace();
      const workspacecode = workspace && workspace.Code ? workspace.Code : '';
      menuNav = [
        {
          text: 'My Application',
          translate: 'MY_APPLICATION',
          heading: true,
          link: '/' + workspacecode + '/parent',
        },
      ];
      menuNav.forEach(item => {
        this.addNav(item);
      });
      return menuNav;
    }
  }

  public async getNav(isNewLoad = false) {
    // return this.menuItems;
    if (isNewLoad) {
      this.menuNav = [];
    }
    let menuNav = this.menuNav;
    if (menuNav && menuNav.length > 0) {
      return menuNav;
    } else {
      const workspace = this.workspaceService.getCurrentWorkspace();
      const workspacecode = workspace && workspace.Code ? workspace.Code : '';
      const menus = await this.getMenus(workspacecode);
      menuNav = menus.filter(f => f.IsHead);
      menuNav.forEach(item => {
        this.addNav(item);
      });
      return menuNav;
    }
  }

  private async getMenuAsync(client, req) {
    if (!this.promiseMenuReturn) {
      // console.log('==== loginAsync data: none ');
      this.promiseMenuReturn = Promise.resolve(client.get(req));
      return await this.promiseMenuReturn;
    } else {
      // console.log('==== loginAsync: ');
      return await this.promiseMenuReturn;
    }
  }

  public async getMenus(code) {
    let menuLists = this.cacheService.getCacheData('menuLists'); // localStorage.getItem('menuLists');
    if (!menuLists) {
      let url = '/navigation/item/query?IncludeDisable=false';
      const client = this.factory.getMctCarewaitV2ApiClient();

      const response = await this.getMenuAsync(client, url); // await client.get<any>(url);
      this.promiseMenuReturn = null;
      if (response && response.Data) {
        // localStorage.setItem('menuLists', JSON.stringify(response.Data));
        this.cacheService.setCacheData('menuLists', JSON.stringify(response.Data));
        const list = response.Data;
        let menus = this.list_to_tree(list, code);
        return menus;
      }
      return [];
    } else {
      const list = menuLists; // JSON.parse(localStorage.getItem('menuLists'));
      let menus = this.list_to_tree(list, code);
      return menus;
    }
  }

  public async list_to_tree(list, code) {
    // const list = data[0];
    // const code = data[1];
    const map = {};
    let node: any = {};
    const roots = [];
    let i = 0;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].Id] = i; // initialize the map
      // list[i].submenu = []; // initialize the children
      list[i].text = list[i].Label;
      const hrefname = list[i].Href
        ? list[i].Href.replace('http://', '').replace('//', '').split('/')
        : '';
      list[i].name = hrefname && hrefname.length > 1 ? hrefname[1] : list[i].Href;
      list[i].heading = list[i].IsHead;
      if (list[i].Href) {
        if (list[i].Href.startsWith('http')) {
          list[i].link = list[i].Href;
        } else if (list[i].Href.startsWith('//')) {
          list[i].link = list[i].Href;
        } else if (list[i].Href.startsWith('/')) {
          list[i].link = '/' + code + list[i].Href;
        } else {
          list[i].link = '/' + code + '/' + list[i].Href;
        }
      }
      // list[i].link = list[i].Href ?  '/workspace/' + code + list[i].Href :  undefined;
      list[i].permissions = list[i].PermissionCode ? list[i].PermissionCode.split(',') : undefined;
      list[i].icon = list[i].IconClass;
    }

    const smartListHref = [];
    for (const i in list) {
      const helfValue = list[i].Href;
      if (helfValue) {
        if (helfValue.indexOf('/smartlist/viewer') > -1) {
          const splitHref = helfValue.split('/');
          if (splitHref && splitHref.length > 2) {
            const length = splitHref.length;
            const reportType = splitHref[length - 1];
            const reportCode = splitHref[length - 2];
            if (reportType && reportCode) {
              smartListHref.push({
                Apply: 1,
                ReportDataSourceCode: reportCode,
                OwnerType: reportType,
              });
            }
          }
        }
      }
    }
    const allDefaultQuery = await this.getDefaultQueryId(smartListHref);
    for (const i in list) {
      const helfValue = list[i].Href;
      if (helfValue) {
        if (helfValue.indexOf('/smartlist/viewer') > -1) {
          const splitHref = helfValue.split('/');
          if (splitHref && splitHref.length > 2) {
            const length = splitHref.length;
            const reportType = splitHref[length - 1];
            const reportCode = splitHref[length - 2];
            if (allDefaultQuery && allDefaultQuery.Data && allDefaultQuery.Data.length > 0) {
              this.promiseReturn = null;
              // localStorage.setItem('allDefaultQueryList', JSON.stringify(allDefaultQuery.Data));
              this.cacheService.setCacheData(
                'allDefaultQueryList',
                JSON.stringify(allDefaultQuery.Data),
              );
              allDefaultQuery.Data.forEach(item => {
                if (item.ReportDataSourceCode == reportCode && item.OwnerType == reportType) {
                  list[i].queryId = item.QueryId;
                }
              });
            }
          }
        }
      }
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.ParentItemId && node.ParentItemId != '00000000-0000-0000-0000-000000000000') {
        // if you have dangling branches check that map[node.ParentItemId] exists
        if (list[map[node.ParentItemId]]) {
          if (!list[map[node.ParentItemId]].submenu) {
            list[map[node.ParentItemId]].submenu = [];
          }
          // 判断menu 菜单被重复push
          const subMenus = list[map[node.ParentItemId]].submenu;
          let curM = null;
          if (subMenus && subMenus.length > 0) {
            curM = subMenus.find(x => {
              return x && x.Href === node.Href && x.Id == node.Id;
            });
          }
          if (!curM) {
            list[map[node.ParentItemId]].submenu.push(node);
          }
        }
      } else {
        roots.push(node);
      }
    }
    // console.log('==== roots:', roots);
    return roots;
  }

  async getDefaultQueryId(smartListHref) {
    // let allDefaultQueryList = localStorage.getItem('allDefaultQueryList');
    // if(!allDefaultQueryList){
    //     const client = this.factory.getMctCarewaitV2ApiClient();
    //     const postData = new GetAllDefaultQueryRequest();
    //     postData.DefaultQueryParam = smartListHref;
    //     const response = await client.post(postData);
    //     localStorage.setItem('allDefaultQueryList', JSON.stringify(response.Data))
    // }
    // const list = JSON.parse(localStorage.getItem('allDefaultQueryList'));
    // return list;

    if (!this.promiseReturn) {
      const client = this.factory.getMctCarewaitV2ApiClient();
      const postData = new GetAllDefaultQueryRequest();
      postData.DefaultQueryParam = smartListHref;
      // localStorage.getItem('allDefaultQueryList');
      let allDefaultQueryList = this.cacheService.getCacheData('allDefaultQueryList');
      if (!allDefaultQueryList) {
        this.promiseReturn = Promise.resolve(client.post(postData));
        return await this.promiseReturn;
      } else {
        const list = allDefaultQueryList; // JSON.parse(localStorage.getItem('allDefaultQueryList'));
        return await Promise.resolve({ Data: list });
      }
    } else {
      return await this.promiseReturn;
    }
  }

  public removeMenuLocalStorage() {
    // localStorage.removeItem('menuLists');
    // localStorage.removeItem('allDefaultQueryList');
    this.cacheService.clearCacheDataByKey('menuLists');
    this.cacheService.clearCacheDataByKey('allDefaultQueryList');
  }
}
