import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslatorService {
  private defaultLanguage = 'en';

  private availablelangs = [
    { code: 'en', text: 'English' },
    { code: 'es_AR', text: 'Español' },
    { code: 'zh_hk', text: '繁體中文' },
  ];

  translateKey = 'cc_translate_lang_key';

  constructor(public translate: TranslateService) {
    if (!translate.getDefaultLang()) {
      translate.setDefaultLang(this.defaultLanguage);
    }

    const key = localStorage.getItem(this.translateKey);
    this.useLanguage(key, true);
  }

  useLanguage(lang: string = null, isRefresh: boolean = false) {
    const key = lang || this.translate.getDefaultLang();
    localStorage.setItem(this.translateKey, key);
    this.translate.use(key);
    if (!isRefresh) {
      window.location.reload();
    }
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

  getDefaultLanguage() {
    const key = localStorage.getItem(this.translateKey);
    if (key) {
      return key;
    }
    return this.defaultLanguage;
  }

  getLanguageName(lang = null) {
    const key = lang || this.getDefaultLanguage();
    const curLang = this.availablelangs.find(x => {
      return x.code === key;
    });
    return curLang.text;
  }
}
