<!-- START Top Navbar-->
<nav class="navbar topnavbar navbar-expand-lg navbar-light" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header" [ngClass]="{ 'stanford-header': isStanfordWkCode }">
    <a class="navbar-brand" [routerLink]="getLinkUrl('/home/dashboard')">
      <div *ngIf="!isStanfordWkCode">
        <div class="brand-logo brand-logo-title text-white" id="fpTxt">
          <img src="assets/img/mct/mct-logo-small.png" height="40" />
        </div>
        <div class="brand-logo-collapsed">
          <img src="assets/img/mct/mct-logo-white.png" alt="carecloud" class="img-fluid" />
        </div>
      </div>
      <div *ngIf="isStanfordWkCode">
        <div class="brand-logo brand-logo-title text-white stanford-logo" id="fpTxt">
          <img src="assets/img/mct/stanford-white.png" height="50" />
        </div>
        <div class="brand-logo-collapsed">
          <img src="assets/img/mct/mct-logo-white.png" alt="carecloud" class="img-fluid" />
        </div>
      </div>
    </a>
  </div>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul [hidden]="!isAuthenticated" class="navbar-nav mr-auto flex-row">
    <li class="nav-item" *ngIf="!containHome">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a
        class="nav-link d-none d-md-block d-lg-block d-xl-block"
        trigger-resize=""
        (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()"
      >
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a
        class="nav-link sidebar-toggle d-md-none"
        (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()"
      >
        <em class="fas fa-bars"></em>
      </a>
    </li>
  </ul>
  <!-- END Left navbar-->

  <!-- START Nav wrapper-->
  <div class="navbar-collapse collapse" [collapse]="navCollapsed">
    <ul [hidden]="!isAuthenticated" class="nav navbar-nav mr-auto flex-column flex-md-row">
      <li
        class="nav-item"
        *ngFor="let item of menuItems"
        [ngClass]="{ dropdown: item.submenu }"
        dropdown
      >
        <div *ngxPermissionsOnly="item.permissions; else noPermissionBolck; then: menuBlock"></div>
        <ng-template #menuBlock>
          <a
            class="nav-link"
            *ngIf="item.link"
            [routerLink]="item.link"
            [queryParams]="{ QueryId: item.queryId }"
            [title]="item.text"
          >
            <span>{{ item.text }}</span>
          </a>
          <a class="nav-link" *ngIf="!item.link" href="#" [title]="item.text">
            <span>{{ item.text }}</span>
          </a>
        </ng-template>
        <ng-template #noPermissionBolck> </ng-template>
      </li>
    </ul>
  </div>
  <!-- END Nav wrapper-->

  <!-- START Right Navbar-->
  <ul class="navbar-nav flex-row">
    <!-- lang -->
    <!-- <li class="nav-item lang pt-2" dropdown>
      
            <a class="nav-link dropdown-toggle dropdown-toggle-caret" style="cursor: pointer;" dropdownToggle>
                <span class="p2">{{selectedLanguageName}}</span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated chang">
                <a class="dropdown-item" *ngFor="let item of langList" dropdown (click)="setLang(item)">
                    <span>{{item.text}}</span>
                </a>
            </div>
        </li> -->

    <!-- <li [hidden]="!isAuthenticated" class="nav-item lang pt-2 pl-3">
                <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle href
                [routerLink]="['/home/profile']">
                <em class="icon-user"></em>
            </a>
        </li> -->
    <!-- Lgoin -->
    <li [hidden]="isAuthenticated" class="nav-item">
      <a class="nav-link" [routerLink]="getLinkUrl('/home/login')">
        <span>{{ ('LOG_IN' | translate) || 'Log In' }}</span>
      </a>
    </li>

    <!-- <li [hidden]="isAuthenticated" class="nav-item">
                <a class="nav-link" [routerLink]="['/home/signup']" >
                    <span>Sign Up</span>
              </a>
         </li>    -->
    <!-- START Alert menu-->
    <!-- <li [hidden]="!isAuthenticated" class="nav-item dropdown dropdown-list" dropdown>
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle>
                <em class="icon-bell"></em>
                <span class="badge badge-danger">11</span>
            </a>
         
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
                <div class="dropdown-item">
               
                    <div class="list-group">
                     
                        <div class="list-group-item list-group-item-action">
                            <div class="media">
                                <div class="align-self-start mr-2">
                                    <em class="fa fa-credit-card fa-2x text-info"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New billings</p>
                                    <p class="m-0 text-muted text-sm">1 new billing</p>
                                </div>
                            </div>
                        </div>
                   
                        <div class="list-group-item list-group-item-action">
                            <div class="media">
                                <div class="align-self-start mr-2">
                                    <em class="fa fa-folder-open fa-2x text-warning"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New Documents</p>
                                    <p class="m-0 text-muted text-sm">You have 10 new documents</p>
                                </div>
                            </div>
                        </div>
                   
                        <div class="list-group-item list-group-item-action">
                            <div class="media">
                                <div class="align-self-start mr-2">
                                    <em class="fa fa-chalkboard fa-2x text-success"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New Notifications</p>
                                    <p class="m-0 text-muted text-sm">11 new notifications</p>
                                </div>
                            </div>
                        </div>
           
                    </div>
             
                </div>
            </div>
     
        </li> -->
    <!-- END Alert menu-->
    <!-- START Offsidebar button-->
    <!-- <li [hidden]="isAuthenticated" class="nav-item">
            <div class="nav-link dropdown-toggle dropdown-toggle-caret" style="cursor: pointer;"
                (click)="toggleOffsidebar(); $event.stopPropagation()">
                <span>Tomothy Roberts-mason</span>
            </div>
        </li> -->
    <li [hidden]="!isAuthenticated" class="nav-item">
      <div
        class="nav-link dropdown-toggle dropdown-toggle-caret"
        style="cursor: pointer"
        (click)="toggleOffsidebar(); $event.stopPropagation()"
        id="userList"
      >
        <span>{{ currentUser?.FirstName }} {{ currentUser?.LastName }}</span>
      </div>
    </li>

    <!-- END Offsidebar menu-->

    <!-- <li  class="nav-item  navbar-toggler dropdown" dropdown>
            <a class="nav-link navbar-toggler-icon" dropdownToggle>

            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated fadeIn">
                <a class="dropdown-item" *ngFor="let item of menuItems"  [routerLink]="item.link" [title]="item.text" dropdown>
                        <span>{{(item.translate | translate) || item.text}}</span>
                </a>
            </div>
         </li> -->
  </ul>
  <!-- END Right Navbar-->
</nav>
<!-- END Top Navbar-->
