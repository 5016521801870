<div
  class="pdf-viewer"
  style="
    width: 100%;
    overflow-x: hidden;
    margin-top: 25px;
    background-color: #f6f6f6;
    border: 1px solid #e4eaec;
  "
  id="pdfViewer"
>
  <div
    class="fixed"
    *ngIf="pdfFullPath && isPdfLoaded && !queryFailed"
    id="footCon"
    [ngClass]="{'appopacity':ccmCase}"
  >
    <dx-button icon="plus" class="mr-3" type="normal" text="" (onClick)="zoom_in()"> </dx-button>
    <dx-button icon="minus" class="" type="normal" text="" (onClick)="zoom_out()"> </dx-button>
    <span class="mr-3 ml-3">|</span>
    <dx-button
      class="ml-3"
      type="normal"
      text="Save"
      (onClick)="downloadDefault($event)"
    ></dx-button>
    <!-- <dx-button [splitButton]="true" class="bg-white" [useSelectMode]="false" text="{{( 'SAVE' | translate) || 'Save'}}" [items]="profileSettings" displayExpr="name" keyExpr="id" (onButtonClick)="downloadDefault($event)" (onItemClick)="download($event)"></dx-drop-down-button> -->
  </div>

  <div>
    <div
      class="image-box"
      id="pdf-content"
      [style.height.px]="pdfHeight"
      style="overflow-y: auto; margin-bottom: 20px"
    >
      <div class="clearfix" style="clear: both; float: none; height: 1px; overflow: hidden"></div>
      <div class="">
        <div style="display: block" class="">
          <div style="margin: 0 auto">
            <div
              style="text-align: center; margin-top: 70px"
              class="{{(pdfFullPath && isPdfLoaded)?'rp-hide':'rp-show'}}"
            >
              <dx-load-indicator
                class="button-indicator"
                [elementAttr]="{ id: 'elementId', class: 'border-color-white' }"
              >
              </dx-load-indicator>
              <div
                style="width: 420px; margin: 0 auto; line-height: 2em"
                class="dx-field-item-label-text mt-4 mb-4"
              >
                <!-- {{( 'REPORT_IS_GENERATING' | translate) }} -->
                {{( 'REPORT_IS_BEING_GENERATED_IT_MAY_TAKE_A_FEW_MINUTES' | translate) }}
              </div>
            </div>
            <div class="text-center pl-3 pr-3">
              <div
                class="{{(pdfFullPath && isPdfLoaded && !queryFailed)?'rp-show':'rp-hide'}}"
                title=""
              >
                <pdf-viewer
                  (error)="onPdfError()"
                  (after-load-complete)="callBackFn()"
                  [src]="pdfFullPath"
                  [show-all]="true"
                  [(page)]="pageVariable"
                  [original-size]="false"
                  [fit-to-page]="true"
                  [autoresize]="true"
                  [render-text]="true"
                  [zoom]="zoom_to"
                  [style.height.px]="pdfHeight-10"
                  style="display: block"
                ></pdf-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <div id="footZoom" *ngIf="pdfFullPath && isPdfLoaded" class="fixedZoom">
      <div (click)="zoom_in()" class="" style="width: 32px;height: 32px;color: rgba(0, 0, 0, 0.87);box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);border:1px solid #ccc;padding:3px;background: #ffffff;"><i class="dx-icon fa fa-plus"></i></div>
      <div (click)="zoom_out()" style="width: 32px;height: 32px;color: rgba(0, 0, 0, 0.87);box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);margin-top:10px;border:1px solid #ccc;padding:3px;background: #ffffff;"><i class="dx-icon fa fa-minus"></i></div>
  </div>
  -->
</div>
