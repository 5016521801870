import { Component, OnInit, OnDestroy } from '@angular/core';
declare var $: any;

import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { AuthService } from '../../core/auth/auth.service';
import { User } from '../../core/models/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { EntityCacheService, ReportCacheService } from '../../core/services/cacheService';
import { NotifyService } from '../../shared/message/notify.service';
import { TranslationPipe } from '../../core/pipe/translationPipe';
import { wkCodeList } from '../../core/models/common.model';

@Component({
  selector: 'app-offsidebar',
  templateUrl: './offsidebar.component.html',
  styleUrls: ['./offsidebar.component.scss'],
})
export class OffsidebarComponent implements OnInit, OnDestroy {
  currentUser: User;
  currentTheme: any;
  selectedLanguage: string;
  clickEvent = 'click.offsidebar';
  $doc: any = null;
  wkCode: any;
  isStanford = false;

  constructor(
    public settings: SettingsService,
    private auth: AuthService,
    public themes: ThemesService,
    public translator: TranslatorService,
    private router: Router,
    public route: ActivatedRoute,
    public entityCacheService: EntityCacheService,
    public reportCacheService: ReportCacheService,
    private message: NotifyService,
    private translater: TranslationPipe,
  ) {
    this.currentTheme = themes.getDefaultTheme();
    this.selectedLanguage = translator.getDefaultLanguage();
    if (route.snapshot.params.wkcode) {
      this.wkCode = route.snapshot.params.wkcode;
    } else if (route.snapshot.firstChild.params.wkcode) {
      this.wkCode = route.snapshot.firstChild.params.wkcode;
    }
    this.auth.currentUser.subscribe(u => (this.currentUser = u));
  }

  ngOnInit() {
    // console.log('offsidebar');
    this.isStanford = wkCodeList.filter(f => f == this.wkCode.toLowerCase()).length > 0;
    this.anyClickClose();
  }

  setTheme() {
    this.themes.setTheme(this.currentTheme);
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.selectedLanguage = value;
    this.translator.useLanguage(value);
  }

  anyClickClose() {
    this.$doc = $(document).on(this.clickEvent, e => {
      if (!$(e.target).parents('.offsidebar').length) {
        this.settings.setLayoutSetting('offsidebarOpen', false);
        $('app-root').removeClass('offsidebar-open');
      }
    });
  }

  ngOnDestroy() {
    if (this.$doc) {
      this.$doc.off(this.clickEvent);
    }
  }

  logOut() {
    console.log(this.wkCode);
    this.settings.toggleLayoutSetting('offsidebarOpen');
    this.auth.logout(this.wkCode);
  }

  resetPassword() {
    this.router.navigate(['home/resetpassword']);
  }

  viewProfile() {
    this.router.navigate(['home/profile']);
  }

  getProfileUrl() {
    const wkcodeurl = this.wkCode ? '/' + this.wkCode : '';
    const path =
      this.route.snapshot.url.length > 0
        ? this.route.snapshot.url[1].path
        : this.route.snapshot.firstChild.url[1].path;
    const url = path != 'parent' ? wkcodeurl + '/home/profile' : wkcodeurl + '/parent/home/profile';
    const parmas = this.currentUser ? this.currentUser.UserId : 0;
    return [url, parmas];
  }

  getPassWordUrl() {
    const wkcodeurl = this.wkCode ? '/' + this.wkCode : '';
    const path =
      this.route.snapshot.url.length > 0
        ? this.route.snapshot.url[1].path
        : this.route.snapshot.firstChild.url[1].path;
    const url =
      path != 'parent'
        ? wkcodeurl + '/home/resetpassword'
        : wkcodeurl + '/parent/home/resetpassword';
    return [url];
  }

  clearCache() {
    this.entityCacheService.clearCacheData();
    this.reportCacheService.clearCacheData();
    this.auth.clearOtherSessionStorage();
    this.message.showSuccess(this.translater.transform('CLEAR_CACHE_SUCCESSFULLY'));
  }
}
