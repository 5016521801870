import { UntypedFormGroup } from '@angular/forms';
import DevExpress from 'devextreme';
import { exportDataGrid, exportPivotGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import ExportingEvent = DevExpress.ui.dxDataGrid.ExportingEvent;

export const checkIsMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

/*
common for (onExporting)="onExporting($event)
options.customizeCell change export cell format;

 */
export function dataGridCommonExport(e: ExportingEvent, options = {}) {
  e.component.beginUpdate();
  const { fileName = 'exportName' } = e;
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  const exportOptions: any = {
    component: e.component,
    worksheet: worksheet,
    ...options,
  };
  exportDataGrid(exportOptions).then(function () {
    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${fileName}${fileName.endsWith('.xlsx') ? '' : '.xlsx'}`,
      );
    });
    e.component.endUpdate();
  });
  e.cancel = true;
}

export function pivotGridCommonExport(e: ExportingEvent, options = {}) {
  e.component.beginUpdate();
  const { fileName = 'exportName' } = e;
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  const exportOptions: any = {
    component: e.component,
    worksheet: worksheet,
    ...options,
  };
  exportPivotGrid(exportOptions).then(function () {
    workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${fileName}${fileName.endsWith('.xlsx') ? '' : '.xlsx'}`,
      );
    });
    e.component.endUpdate();
  });
  e.cancel = true;
}

export function markFormDirty(form: UntypedFormGroup) {
  Object.values(form.controls).forEach(control => {
    if (control.invalid) {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    }
  });
}

export function createGaSession(settings: { trackingId: string; domain: string }) {
  document.write(
    '<script>' +
      '(function(i,s,o,g,r,a,m){i["GoogleAnalyticsObject"]=r;i[r]=i[r]||function(){' +
      '(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),' +
      'm=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)' +
      '})(window,document,"script","https://www.google-analytics.com/analytics.js","ga");' +
      'ga("create", "' +
      settings.trackingId +
      '" , "' +
      settings.domain +
      '");' +
      '</script>',
  );
}

export const czoneFormat = data => {
  if (data) {
    const offset = -new Date().getTimezoneOffset() / 60;
    const hour = Math.floor(offset);
    const min = (Math.abs(offset) % 1) * 60;
    const time =
      (hour >= 0 ? '+' : '-') +
      ('000' + Math.abs(hour)).substr(-2) +
      ':' +
      ('000' + min).substr(-2);
    return data + ' (UTC' + time + ')';
  }
};
