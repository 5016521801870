import { Injectable } from '@angular/core';
import themes from 'devextreme/ui/themes';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { sfWkCodeList } from '../../core/models/common.model';

const themeD = 'theme-d.css';
const themeStanford = 'theme-stanford.css';

@Injectable()
export class ThemesService {
  userThemeKey = 'cc_theme';
  styleTag: any;
  defaultTheme = 'Compact.Stanford';
  wkCode: any;
  constructor(public route: ActivatedRoute) {
    if (route.snapshot.params.wkcode) {
      this.wkCode = route.snapshot.params.wkcode;
    } else if (route.snapshot.firstChild.params.wkcode) {
      this.wkCode = route.snapshot.firstChild.params.wkcode;
    } else if (route.snapshot.firstChild.firstChild.params.wkcode) {
      this.wkCode = route.snapshot.firstChild.firstChild.params.wkcode;
    }
    this.userThemeKey = this.wkCode + '_theme';
    this.createStyle();
    if (environment.showDevModule) {
      this.defaultTheme = 'Compact';
    } else {
      this.defaultTheme = 'Compact';
    }

    if (this.wkCode) {
      if (sfWkCodeList.filter(f => f == this.wkCode.toLowerCase()).length > 0) {
        this.defaultTheme = 'Compact.Stanford';
      } else {
        this.defaultTheme = 'Compact';
      }
    }

    const dt = this.getDefaultTheme();
    this.setTheme(dt);
  }

  private createStyle() {
    const head = document.head || document.getElementsByTagName('head')[0];
    this.styleTag = document.createElement('link');
    this.styleTag.rel = 'stylesheet';
    this.styleTag.id = 'appthemes';
    head.appendChild(this.styleTag);
  }

  //  themeE: green; themeA: blue; themeH: black
  setTheme(name) {
    this.saveLocal(name);
    switch (name) {
      // case 'Stanford':
      //     this.injectStylesheet(themeStanford);
      //     // themes.current('dx.light');
      //     themes.current('dx.material.CWA-8c1515.Normal');
      //     break;
      case 'Compact.Stanford':
        this.injectStylesheet(themeStanford);
        // themes.current('dx.light');
        themes.current('dx.light.CWA-8c1515.Compact');
        break;
      case 'Compact':
        this.injectStylesheet(themeD);
        themes.current('dx.material.CWA-85CD50.Compact');
        break;

      default:
        this.injectStylesheet(themeStanford);
        themes.current('dx.light.CWA-8c1515.Compact');
        break;
    }
  }

  injectStylesheet(css) {
    this.styleTag.href = css;
  }

  getDefaultTheme() {
    const key = localStorage.getItem(this.userThemeKey);
    if (key) {
      return key;
    }
    return this.defaultTheme;
  }

  saveLocal(name) {
    localStorage.setItem(this.userThemeKey, name);
  }
}
