import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../../carecloud/common.api';

export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class EntityDataResponse extends Response<{ [key: string]: any }> {
  Id: string;
}

// [Route("/organization/member", "POST")]
export class PostOrganizationMemberRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostOrganizationMemberRequest';
  }
}

// [Route("/organization/member/{Id}", "GET")]
export class GetOrganizationMemberByIdRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetOrganizationMemberByIdRequest';
  }
}

// [Route("/organization/member", "PUT")]
export class PutOrganizationMemberRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutOrganizationMemberRequest';
  }
}

// [Route("/organization/member/{Id}", "DELETE")]
export class DeleteOrganizationMemberRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteOrganizationMemberRequest';
  }
}

// [Route("/organization/member/restore/{Id}", "PUT")]
export class RestoreOrganizationMemberRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreOrganizationMemberRequest';
  }
}
