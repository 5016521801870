import { NgModule } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { LayoutComponent } from './layout.component';
import { HLayoutComponent } from './layout.component.h';

import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';

import { SharedModule } from '../shared/shared.module';
import { CustomerListComponent } from './sidebar/customerlist/component';
import { MockService } from '../core/models/billing.api';
import { SettingAutoPayComponent } from './autopay/component';
import { ValidatePasswordComponent } from './validatePassword/validatePassword.component';
import { PDFReportComponent } from './pdfreport/component';
import { DateTemplateComponent } from './template/date-template/component';

@NgModule({
  imports: [SharedModule, PdfViewerModule],
  providers: [UserblockService, MockService],
  declarations: [
    LayoutComponent,
    HLayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    NavsearchComponent,
    OffsidebarComponent,
    FooterComponent,
    CustomerListComponent,
    SettingAutoPayComponent,
    ValidatePasswordComponent,
    PDFReportComponent,
    DateTemplateComponent
  ],
  exports: [
    LayoutComponent,
    HLayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    NavsearchComponent,
    OffsidebarComponent,
    FooterComponent,
    SettingAutoPayComponent,
    ValidatePasswordComponent,
    PDFReportComponent,
    DateTemplateComponent
  ],
})
export class LayoutModule {}
