import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare var $: any;

@Injectable()
export class FocusService {
  public offsidebarOpenClose: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor() {}

  setFirstChildFocus() {
    setTimeout(() => {
      $('input:visible:first').focus();
    }, 0);
  }

  setFirstFormFocus() {
    setTimeout(() => {
      $('dx-form:first input:visible:first').focus();
    }, 0);
  }

  setSecondLastFormFocus() {
    setTimeout(() => {
      const formlength = $('dx-form').length;
      if (formlength > 1) {
        const form = $('dx-form').eq(formlength - 2);
        $(form).find('input:visible:first').focus();
      }
    }, 0);
  }

  setLastFormFocus() {
    setTimeout(() => {
      $('dx-form:last input:visible:first').focus();
    }, 0);
  }

  setFormSiteFocus() {
    setTimeout(() => {
      $('.siteoptions:last input:visible:first').focus();
    }, 500);
  }

  setFormSiteDeleteFocus(e) {
    const form = $(e.element).parents('dx-form')[0];
    setTimeout(() => {
      $(form).find('.siteoptions:last input:visible:first').focus();
    }, 500);
  }

  setDetailFormSiteFocus() {
    setTimeout(() => {
      $('.detailSiteoptions:last input:visible:first').focus();
    }, 500);
  }

  setDetailFormSiteDeleteFocus(e) {
    const form = $(e.element).parents('dx-form')[0];
    setTimeout(() => {
      $(form).find('.detailSiteoptions:last input:visible:first').focus();
    }, 500);
  }

  setDetailTabFocus() {
    setTimeout(() => {
      $('dx-tabs').focus();
    }, 0);
  }

  setFirstProfileFocus() {
    setTimeout(() => {
      $('.detailProfile:first input:visible:first').focus();
    }, 0);
  }

  setFormFirstValidFocus() {
    setTimeout(() => {
      $('dx-form .dx-invalid:first input:visible:first').focus();
    }, 0);
  }

  setSiteFormFirstValidFocus() {
    setTimeout(() => {
      $('.editsiteform .dx-invalid:first input:visible:first').focus();
    }, 0);
  }

  setAddSiteFormFirstValidFocus() {
    setTimeout(() => {
      $('.addsiteform .dx-invalid:first input:visible:first').focus();
    }, 0);
  }


  setOffsidebarType(value) {
    this.offsidebarOpenClose.next(value)
  }

  getOffsidebarType() {
    this.offsidebarOpenClose.getValue();
  }

}
