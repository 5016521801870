import { Injectable } from '@angular/core';
import { CCService } from './carecloud.api.service';
import moment from 'moment';
import { JsonClientFactoryService } from './JsonClientFactory.service';
import { GetEntityLogListRequest } from './/../../core/models/log.api';

@Injectable()
export class EntityLogService {
  constructor(private clientFactory: JsonClientFactoryService) {}
  // private getServiceUrl(url: string): string {
  //     return this.getUrl(url, 'admin/');
  // }

  getLogs(customerid?, entity?, entityId?, source?, beginDate?, endDate?, start?, limit?) {
    let url = 'system/logger/list';
    const client = this.clientFactory.AdminClient;
    // url = this.getServiceUrl(url);
    if (!beginDate) {
      beginDate = moment().startOf('month').format('YYYYMMDD');
    }
    if (!endDate) {
      endDate = moment().endOf('month').format('YYYYMMDD');
    }
    const postData = {
      RequestCustomerId: customerid,
      Entity: entity,
      EntityId: entityId,
      Source: source,
      LogStartDate: beginDate,
      LogEndDate: endDate,
      StartIndex: start,
      PageSize: limit,
      createResponse() {
        return {} as any;
      },
    };
    return client.postToUrl(url, postData);
    // const test = {
    //     Entity: 'user', EntityId: '5',
    //     RequestCustomerId: 0, Source: 'user.pm', LogStartDate: '20180101', LogEndDate: '20180921', StartIndex: '', PageSize: l
    // };
    // return this.post<any>(url, postData);
  }

  getEntityLogs(entity?, entityId?, source?, beginDate?, endDate?, start?, limit?) {
    const postData = new GetEntityLogListRequest();
    const client = this.clientFactory.MctCareWaitClient;
    // if (!beginDate) {
    //     beginDate = moment().add(-3, 'M').format('YYYYMMDD');
    // }
    // if (!endDate) {
    //     endDate = moment().format('YYYYMMDD');
    // }
    postData.Entity = 'cw#' + entity;
    postData.EntityId = entityId;
    postData.Source = source;
    postData.LogStartDate = beginDate;
    postData.LogEndDate = endDate;
    postData.StartIndex = start;
    postData.PageSize = limit;
    return client.get(postData);
  }
}
