export interface SysReportDataSource {
  Id: string;
  WorkspaceId: string;
  CustomerId: number;
  Code: string;
  Name: string;
  TableName: string;
  ShowOrder: number;
  ContainEntities: { [key: string]: any }[];
  Status: number;
  Disable: boolean;
  CreateTime: Date | string;
  UpdateTime: Date | string;
  CreateUserId: number;
  UpdateUserId: number;
  Version: number;
}

export class WksReport {
  Id: number;
  Code: string;
  Name: string;
  Type: number;
  Description: string;
  QueryForm?: {
    Form: any;
    Items: any[];
  };
  LayoutOptions?: {
    DataGrid: any;
    Columns: any[];
    TotalSummary: any[];
    GroupSummary: any[];
  };
  DataSource: string;

  DefaultModel?: any;
}

export class Report {
  Id: number;
  WorkspaceId: number;
  CustomerId: number;
  Disable: boolean;
  CreateTime: Date;
  CreateUserId: number;
  UpdateUserId: number;
  UpdateTime: Date;
  RowVersion: number;

  /**/ //  */
  Code: string;
  /**/ //  */
  Name: string;

  Description: string;
  /**/ //  */
  GroupName: string;

  /**/ //  */
  DataSource: any;
  /**Grid, Pivot, Report */
  Type: number;

  /**/ //  */
  ReportOptions?: any;

  QueryForm: string;
  /**/ //  */
  LayoutOptions?: any;
  /**layout template file, such as REPX, Excel */
  LayoutFile?: string;
  /**/ //  */
  OwnerUserId: number;
  /**/ //  */
  OwnerOrgId: number;
  /**  Workspace,
        Organization,
        Private,
        Restricted = 9 */
  SharedScope: number;

  UserGroups: string;
}
