/*
 Base entity, such as Family, Provider , Care, Claim
*/
import { Injectable } from '@angular/core';
import { JsonClientFactoryService } from '../carecloud/JsonClientFactory.service';
import { ConvertService } from '../../shared/utils/convert.service';
import { WksEntity, WksContract, EntityService } from './entityService';
import { DataType, EditorType, DataSourceType } from '../models/datamodel/entityType';
import { EntityCacheService } from './cacheService';
import moment from 'moment';

import {
  GetEntityInfoRequest,
  GetEntityInfoResponse,
  PutEntityInfoRequest,
} from '../models/getEntityData.api';
import {
  GetApplicationRequest,
  PostApplicationRequest,
  PutApplicationRequest,
  DeleteApplicationRequest,
  GetApplicationListByAutoQueryRequest,
  RestoreApplicationRequest,
  GetApplicationParentRequest,
  PostApplicationParentRequest,
  PutApplicationParentRequest,
  DeleteApplicationParentRequest,
  RestoreApplicationParentRequest,
  GetApplicationChildRequest,
  PostApplicationChildRequest,
  PutApplicationChildRequest,
  DeleteApplicationChildRequest,
  RestoreApplicationChildRequest,
  GetApplicationProviderCriteriaRequest,
  PostApplicationProviderCriteriaRequest,
  PutApplicationProviderCriteriaRequest,
  DeleteApplicationProviderCriteriaRequest,
  RestoreApplicationProviderCriteriaRequest,
  GetApplicationSummaryRequest,
  GetApplicationParentApplyResultRequest,
  CheckApplicationRequest,
  CheckApplicationParentRequest,
  CheckApplicationChildRequest,
  CheckApplicationProviderCriteriaRequest,
  ApplyFinishApplicationRequest,
  GetApplicationRankRequest,
  PostApplicationRankRequest,
  PutApplicationRankRequest,
  DeleteApplicationRankRequest,
  RestoreApplicationRankRequest,
  GetApplicationStatusWorkflowRequest,
  ChangeApplicationStatusRequest,
  GetApplicantProviderStatusWorkflowRequest,
  ChangeApplicantProviderStatusRequest,
  GetMappingByCodeRequest,
} from '../models/applicationdetail.api';

import {
  GetProviderRequest,
  PostProviderRequest,
  DeleteProviderRequest,
  PutProviderRequest,
  RestoreProviderRequest,
  GetProviderVacancyRequest,
  PostProviderVacancyRequest,
  DeleteProviderVacancyRequest,
  PutProviderVacancyRequest,
  RestoreProviderVacancyRequest,
  GetProviderVacancyChildRequest,
  PostProviderVacancyChildRequest,
  PutProviderVacancyChildRequest,
  DeleteProviderVacancyChildRequest,
  RestoreProviderVacancyChildRequest,
  PostChildProviderRequest,
  PutChildProviderRequest,
  DeleteChildProviderRequest,
  GetProviderVacancyChildForSiteRequest,
  GetProviderVacancyChildForParentRequest,
  GetChildProviderRequest,
} from '../models/provider.api';

import {
  PostOrganizationRequest,
  GetOrganizationByIdRequest,
  PutOrganizationRequest,
  DeleteOrganizationRequest,
  RestoreOrganizationRequest,
} from '../models/servicemodel/organization.api';

import {
  PostOrganizationMemberRequest,
  GetOrganizationMemberByIdRequest,
  PutOrganizationMemberRequest,
  DeleteOrganizationMemberRequest,
  RestoreOrganizationMemberRequest,
} from '../models/servicemodel/organizationmember.api';
import { GetAffiliationDataRequest, GetStudentDataRequest } from '../models/application.api';
import { GetJobRequest } from '../models/job.api';
import { GetBatchSendApplicationRequest } from '../models/reminder.api';
import {
  DeleteTemplateRequest,
  GetTemplateRequest,
  PostTemplateRequest,
  PutTemplateRequest,
  RestoreTemplateRequest,
} from '../models/template.api';
import { U2ldatePipe } from '../pipe/u2ldate.pipe';
import { CZonePipe } from '../pipe/currentZone.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { NotifyService } from '../../shared/message/notify.service';

@Injectable()
export class EntityDataService {
  public forceRepaintGrid: BehaviorSubject<string> = new BehaviorSubject<string>(''); // add by wjw to update site tab's grid column align

  crudlist = [
    { id: 1, name: 'View' },
    { id: 2, name: 'Edit' },
    { id: 4, name: 'Add' },
    { id: 8, name: 'Delete' },
  ];

  constructor(
    private entityService: EntityService,
    private factory: JsonClientFactoryService,
    private convert: ConvertService,
    private cacheService: EntityCacheService,
    private message: NotifyService,
  ) {}

  public repaintDataGrid(dataGrid) {
    setTimeout(() => {
      try {
        // console.log('==== force refresh data: vm.dataGrid.instance.repaint', vm.dataGrid.instance.getDataSource());
        if (
          dataGrid &&
          dataGrid.instance &&
          dataGrid.instance.getDataSource() &&
          dataGrid.instance.getDataSource().reload
        ) {
          // vm.isGridRepainted = true;
          dataGrid.instance.repaint();
        }
      } catch (e) {
        dataGrid.instance.getDataSource().reload();
        // console.error('==== force refresh error: ', e);
      }
    }, 300);
  }

  public setForceRepaintGrid(data = ''): void {
    this.forceRepaintGrid.next(data);
  }

  public getForceRepaintGrid() {
    return this.forceRepaintGrid.getValue();
  }

  private async getApiCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return 'application';
      case 'cw_app_providercriteria':
        return 'application/providercriteria';
      case 'cw_app_parent':
        return 'application/parent';
      case 'cw_app_child':
        return 'application/child';
      case 'cw_app_summary':
        return 'application/summary';
      case 'cw_app_rank':
        return 'application/rank';
      case 'cw_app_child_provider_vacancy_referral':
        return 'application/child/provider';
      case 'cw_pro_provider':
        return 'provider';
      case 'cw_pro_vacancy':
        return 'provider/vacancy';
      case 'cw_pro_vacancy_child':
        return 'provider/vacancy/child';
      case 'cw_pro_vacancy_child_forwlo':
        return 'application/vacancychild/wlo';
      case 'cw_pro_vacancy_child_forparent':
        return 'application/vacancychild/parent';
      case 'cw_app_child_provider_forsite':
        return 'application/child/provider/forsite';
      case 'cw_sys_organization':
        return 'organization';
      case 'cw_msg_batch':
        return 'reminder/batchsendapplication';
      case 'cw_msg_batchdetail':
        return 'reminder/batchsendapplication/message';
      case 'cw_sys_user':
        return 'organization/member';
      case 'cw_sys_role':
        return 'role';
      case 'cw_tp_Affiliation':
        return 'thirdpart/affiliationdata';
      case 'cw_tp_AffiliationTermination':
        return 'thirdpart/affiliationterminationdata';
      case 'cw_tp_Student':
        return 'thirdpart/studentdata';
      case 'cw_tp_StudentTermination':
        return 'thirdpart/studentterminationdata';
      case 'cw_sys_job':
        return 'job';
      case 'cw_sys_template':
        return 'template';
      case 'cw_app_vacancy_child_forsite':
        return 'application/vacancychild/forsite';
      default:
        const type = await this.entityService.getEntity(code);
        const tablename = type.EntityType.TableName;
        switch (tablename) {
          case 'wat_app_application':
            return 'application';
        }
        return code;
    }
  }

  private async getListApiByCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return new GetApplicationListByAutoQueryRequest();
      default:
        return new GetApplicationListByAutoQueryRequest();
    }
  }

  private async getAddApiByCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return new PostApplicationRequest();
      case 'cw_app_parent':
        return new PostApplicationParentRequest();
      case 'cw_app_child':
        return new PostApplicationChildRequest();
      case 'cw_app_providercriteria':
        return new PostApplicationProviderCriteriaRequest();
      case 'cw_app_rank':
        return new PostApplicationRankRequest();
      case 'cw_app_child_provider_vacancy_referral':
        return new PostChildProviderRequest();
      case 'cw_pro_provider':
        return new PostProviderRequest();
      case 'cw_pro_vacancy':
        return new PostProviderVacancyRequest();
      case 'cw_pro_vacancy_child':
        return new PostProviderVacancyChildRequest();
      case 'cw_sys_organization':
        return new PostOrganizationRequest();
      case 'cw_sys_user':
        return new PostOrganizationMemberRequest();
      case 'cw_sys_template':
        return new PostTemplateRequest();
      default:
        return new PostApplicationRequest();
    }
  }

  private async getDeleteApiByCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return new DeleteApplicationRequest();
      case 'cw_app_parent':
        return new DeleteApplicationParentRequest();
      case 'cw_app_child':
        return new DeleteApplicationChildRequest();
      case 'cw_app_providercriteria':
        return new DeleteApplicationProviderCriteriaRequest();
      case 'cw_app_rank':
        return new DeleteApplicationRankRequest();
      case 'cw_app_child_provider_vacancy_referral':
        return new DeleteChildProviderRequest();
      case 'cw_pro_provider':
        return new DeleteProviderRequest();
      case 'cw_pro_vacancy':
        return new DeleteProviderVacancyRequest();
      case 'cw_pro_vacancy_child':
        return new DeleteProviderVacancyChildRequest();
      case 'cw_sys_organization':
        return new DeleteOrganizationRequest();
      case 'cw_sys_user':
        return new DeleteOrganizationMemberRequest();
      case 'cw_sys_template':
        return new DeleteTemplateRequest();
      default:
        return new DeleteApplicationRequest();
    }
  }

  private async getUpdateApiByCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return new PutApplicationRequest();
      case 'cw_app_parent':
        return new PutApplicationParentRequest();
      case 'cw_app_child':
        return new PutApplicationChildRequest();
      case 'cw_app_providercriteria':
        return new PutApplicationProviderCriteriaRequest();
      case 'cw_app_rank':
        return new PutApplicationRankRequest();
      case 'cw_app_child_provider_vacancy_referral':
        return new PutChildProviderRequest();
      case 'cw_pro_provider':
        return new PutProviderRequest();
      case 'cw_pro_vacancy':
        return new PutProviderVacancyRequest();
      case 'cw_pro_vacancy_child':
        return new PutProviderVacancyChildRequest();
      case 'cw_sys_organization':
        return new PutOrganizationRequest();
      case 'cw_sys_user':
        return new PutOrganizationMemberRequest();
      case 'cw_sys_template':
        return new PutTemplateRequest();
      default:
        return new PutApplicationRequest();
    }
  }

  private async getSearchApiByCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return new GetApplicationRequest();
      case 'cw_app_parent':
        return new GetApplicationParentRequest();
      case 'cw_app_child':
        return new GetApplicationChildRequest();
      case 'cw_app_providercriteria':
        return new GetApplicationProviderCriteriaRequest();
      case 'cw_app_rank':
        return new GetApplicationRankRequest();
      case 'cw_app_summary':
        return new GetApplicationSummaryRequest();
      case 'cw_pro_provider':
        return new GetProviderRequest();
      case 'cw_pro_vacancy':
        return new GetProviderVacancyRequest();
      case 'cw_pro_vacancy_child':
        return new GetProviderVacancyChildForSiteRequest(); // new GetProviderVacancyChildRequest;
      case 'cw_sys_organization':
        return new GetOrganizationByIdRequest();
      case 'cw_sys_user':
        return new GetOrganizationMemberByIdRequest();
      case 'cw_app_parentapply_result':
        return new GetApplicationParentApplyResultRequest();
      case 'cw_pro_vacancy_child_forparent':
        return new GetProviderVacancyChildForParentRequest();
      case 'cw_app_child_provider_vacancy_referral':
        return new GetChildProviderRequest();
      case 'cw_tp_Affiliation':
        return new GetAffiliationDataRequest();
      case 'cw_tp_Student':
        return new GetStudentDataRequest();
      case 'cw_sys_job':
        return new GetJobRequest();
      case 'cw_msg_batch':
        return new GetBatchSendApplicationRequest();
      case 'cw_sys_template':
        return new GetTemplateRequest();
      default:
        return new GetApplicationRequest();
    }
  }

  private async getRestoreApiByCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return new RestoreApplicationRequest();
      case 'cw_app_parent':
        return new RestoreApplicationParentRequest();
      case 'cw_app_child':
        return new RestoreApplicationChildRequest();
      case 'cw_app_providercriteria':
        return new RestoreApplicationProviderCriteriaRequest();
      case 'cw_app_rank':
        return new RestoreApplicationRankRequest();
      case 'cw_pro_provider':
        return new RestoreProviderRequest();
      case 'cw_pro_vacancy':
        return new RestoreProviderVacancyRequest();
      case 'cw_pro_vacancy_child':
        return new RestoreProviderVacancyChildRequest();
      case 'cw_sys_organization':
        return new RestoreOrganizationRequest();
      case 'cw_sys_user':
        return new RestoreOrganizationMemberRequest();
      case 'cw_sys_template':
        return new RestoreTemplateRequest();
      default:
        return new RestoreApplicationRequest();
    }
  }

  private async getCheckApplicationApiByCode(code: string) {
    switch (code) {
      case 'cw_app_application':
        return new CheckApplicationRequest();
      case 'cw_app_parent':
        return new CheckApplicationParentRequest();
      case 'cw_app_child':
        return new CheckApplicationChildRequest();
      case 'cw_app_providercriteria':
        return new CheckApplicationProviderCriteriaRequest();
      default:
        return new CheckApplicationRequest();
    }
  }

  public getShowBtn(CRUD) {
    const number = CRUD;
    const source = this.crudlist;
    var length = source.length;
    var values = [];
    if (number) {
      for (var j = 0; j < length; j++) {
        var v = source[j].id;
        if ((number & v) == v) {
          values.push(v);
        }
      }
    } else {
      return [];
    }
    const crud = values && values.length > 0 ? values.join(',') : '';
    return crud;
  }

  // for import validate
  public validateEntityData(entityType: WksEntity, data: any, required = true) {
    let error = '';
    if (entityType && data) {
      entityType.Fields.forEach(field => {
        if (data[field.FieldName]) {
          data[field.FieldName] = String(data[field.FieldName]).trim();
          if (data[field.FieldName] === '' || data[field.FieldName] === '-') {
            data[field.FieldName] = null;
          }
        }
        // else {
        //     data[field.FieldName] = null;
        // }
        const obj = data[field.FieldName];
        const dataType = DataType[field.DataType];
        if (obj) {
          if (dataType == DataType.Int || dataType == DataType.Number) {
            if (isNaN(Number(obj))) {
              error += `[${field.Name}] should be number. `;
            } else {
              data[field.FieldName] = Number(obj);
            }
          } else if (dataType == DataType.Date) {
            const d = moment(obj);
            if (d.isValid()) {
              data[field.FieldName] = d.format('YYYY/MM/DD');
            } else {
              data[field.FieldName] = null;
              // error += `[${field.Name}] should be date. ` ;
            }
          } else if (dataType == DataType.DateTime) {
            const d = moment(obj);
            if (d.isValid()) {
              data[field.FieldName] = d.format('YYYY/MM/DD HH:mm:ss');
            } else {
              data[field.FieldName] = null;
              // error += field.Name + `[${field.Name}] should be date and time. ` ;
            }
          }
        }
        if (dataType == DataType.Boolean) {
          if (obj) {
            const v = String(obj).toLowerCase();
            if (v === 'true' || v === 'yes' || v === 'y' || v === 't' || v === '1') {
              data[field.FieldName] = true;
            } else {
              data[field.FieldName] = false;
            }
          } else {
            data[field.FieldName] = false;
          }
        }
        if (required && field.IsRequired) {
          if (!data[field.FieldName] || data[field.FieldName] === '') {
            error += `[${field.Name}] is required. `;
          }
        }
      });
      data.Error = error;
    }
    return {
      data: data,
      error: error,
    };
  }

  public convertToLocalData(data: any, item: any) {
    if (!data) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
      const val = data[att];
      if (val || val == 0) {
        if (val === 'true' || val === 'True') {
          data[att] = true;
        } else if (val === 'false' || val === 'False') {
          data[att] = false;
        } else {
          let v = val.toString();
          if (v.startsWith('[') && v.endsWith(']')) {
            v = v.replace('[', '').replace(']', '');
            data[att] = v.split(',');
          }
        }
        for (var k in item) {
          const itemtype = item[k];
          if (itemtype.editorType == 'dxTagBox') {
            if (att == itemtype.entityField.BindFieldName) {
              if (
                itemtype.entityField.DataSourceType == DataSourceType.Entity &&
                (itemtype.entityField.DataSource == 'cw_pro_provider' ||
                  itemtype.entityField.DataSource == 'cw_sys_role' ||
                  itemtype.entityField.DataSource == 'cw_sys_organization' ||
                  itemtype.entityField.DataSource == 'cw_sys_user')
              ) {
                let v = val.toString();
                const dataValue = v ? v.split(',') : [];
                const changeValue = [];
                dataValue.forEach(attData => {
                  changeValue.push(parseInt(attData));
                });
                data[att] = changeValue;
              } else {
                let v = val.toString();
                data[att] = v ? v.split(',') : [];
              }
            }
          }

          if (
            itemtype.editorType == 'dxSelectBox' &&
            (itemtype.entityField.DataType == 'Int' || itemtype.entityField.DataType == 'String')
          ) {
            if (att == itemtype.entityField.BindFieldName) {
              if (
                itemtype.entityField.DataSourceType == DataSourceType.Entity &&
                (itemtype.entityField.DataSource == 'cw_pro_provider' ||
                  itemtype.entityField.DataSource == 'cw_sys_role' ||
                  itemtype.entityField.DataSource == 'cw_sys_organization' ||
                  itemtype.entityField.DataSource == 'cw_sys_user')
              ) {
                let v = parseInt(val);
                data[att] = v;
              } else {
                let v = val.toString();
                data[att] = v;
              }
            }
          }

          // if (itemtype.editorType == 'dxSelectBox' && (itemtype.entityField.DataType == "Int" || itemtype.entityField.DataType == "String")) {
          //     if (att == itemtype.entityField.BindFieldName) {
          //         let v = val.toString();
          //         data[att] = v;
          //     }
          // }

          if (itemtype.editorOptions && itemtype.editorOptions.type == 'time') {
            if (att == itemtype.entityField.BindFieldName) {
              data[att] = moment(data[att], 'HHmm');
              break;
            }
          }
        }
      }
    }
    return data;
  }

  public async converToSelectEntityDic(itemList) {
    const titleEntityDicList = [];
    if (itemList) {
      for (const item in itemList) {
        const itemtype = itemList[item];
        if (
          (itemtype.editorType == 'dxSelectBox' ||
            itemtype.editorType == 'dxRadioBox' ||
            itemtype.editorType == 'dxTagBox') &&
          itemtype.entityField.DataSourceType == DataSourceType.Entity
        ) {
          let isEntityHasCode = false;
          for (const c in titleEntityDicList) {
            const entityDic = titleEntityDicList[c];
            if (entityDic.Code == itemtype.entityField.DataSource) {
              isEntityHasCode = true;
              break;
            }
          }
          if (!isEntityHasCode) {
            let getEntitydic = await this.getDataList(
              itemtype.entityField.DataSource,
              {},
              false,
              false,
            );
            const datasource = itemtype.entityField.DataSource;
            let entitydic = { Code: datasource, Response: getEntitydic };
            titleEntityDicList.push(entitydic);
          }
        }
      }
    }
    return titleEntityDicList;
  }

  public async converToEntityDic(itemList) {
    const titleEntityDicList = [];
    if (itemList) {
      for (const item in itemList) {
        const itemtype = itemList[item];
        if (
          itemtype.editorType == 'dxTagBox' &&
          itemtype.entityField.DataSourceType == DataSourceType.Entity
        ) {
          let isEntityHasCode = false;
          for (const c in titleEntityDicList) {
            const entityDic = titleEntityDicList[c];
            if (entityDic.Code == itemtype.entityField.DataSource) {
              isEntityHasCode = true;
              break;
            }
          }
          if (!isEntityHasCode) {
            let getEntitydic = await this.getDataList(
              itemtype.entityField.DataSource,
              {},
              false,
              false,
            );
            const datasource = itemtype.entityField.DataSource;
            let entitydic = { Code: datasource, Response: getEntitydic };
            titleEntityDicList.push(entitydic);
          }
        }
      }
    }
    return titleEntityDicList;
  }

  public async convertToColsDic(itemList) {
    const columnDicList = [];
    if (itemList) {
      for (const item in itemList) {
        const itemtype = itemList[item];
        if (
          itemtype.editorType == 'dxTagBox' &&
          itemtype.entityField.DataSourceType == DataSourceType.Dictionary
        ) {
          let isHasCode = false;
          for (const c in columnDicList) {
            const columnDic = columnDicList[c];
            if (columnDic.Code == itemtype.entityField.DataSource) {
              isHasCode = true;
              break;
            }
          }
          if (!isHasCode) {
            let getdic = await this.entityService.searchDictDataByCode(
              itemtype.entityField.DataSource,
            );
            let dic = { Code: itemtype.entityField.DataSource, DicList: getdic };
            columnDicList.push(dic);
          }
        }
      }
    }
    return columnDicList;
  }

  // add by wjw for utc time to local date, base on: 1, datetime; 2, data format 'YYYYMMDDHHmmss'(length=14)
  public converU2lDateTime(date, gridOptions) {
    if (
      date &&
      date.length === 14 &&
      gridOptions &&
      ((gridOptions.initDataType && gridOptions.initDataType.toLowerCase() === 'datetime') ||
        (gridOptions.dataType && gridOptions.dataType.toLowerCase() === 'datetime'))
    ) {
      const t1 = new U2ldatePipe().transform(date, 'MM/DD/YYYY hh:mm:ss A,YYYYMMDDHHmmss');
      // const t = new CZonePipe(this.domSanitizer).transform(t1, true);
      // // const t2 = moment(t1);
      const rtn = t1;
      // console.log('===== u2l date', date, rtn);
      // gridOptions.format = 'dd/MM/yyyy'
      return rtn;
      // return date;
    } else {
      return date;
    }
  }

  public async convertToListLocalData(data: any, item: any, dicList: any, entitydiclist: any) {
    if (!data) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
      const val = data[att];
      if (val) {
        for (var k in item) {
          const itemtype = item[k];
          if (att != itemtype.entityField.FieldName) {
            continue;
          }

          if (data[att] && itemtype.entityField && itemtype.entityField.GridColumnsOptions) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = this.converU2lDateTime(
                data[att],
                itemtype.entityField.GridColumnsOptions,
              );
            }
          }
          if (itemtype.editorOptions && itemtype.editorOptions.type == 'time') {
            if (att == itemtype.entityField.FieldName) {
              data[att] = moment(data[att], 'HHmm').format('HH:mm A');
              break;
            }
          }
          if (
            itemtype.editorType == 'dxTagBox' &&
            itemtype.entityField.DataSourceType == DataSourceType.Dictionary
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getRenturData = [];
              // let getdic = await this.entityService.searchDictDataByCode(itemtype.entityField.DataSource);
              // for(const d in getdic){
              //     for(const dv in dvalue){
              //         if(dvalue[dv]==getdic[d].Code){
              //             getRenturData.push(getdic[d].Name);
              //         }
              //     }
              // }
              if (dicList) {
                for (const dic in dicList) {
                  const dicValue = dicList[dic];
                  if (itemtype.entityField.DataSource == dicValue.Code) {
                    for (const d in dicValue.DicList) {
                      const getdic = dicValue.DicList;
                      for (const dv in dvalue) {
                        if (dvalue[dv] == getdic[d].Code) {
                          getRenturData.push(getdic[d].Name);
                        }
                      }
                    }
                  }
                }
              }

              if (getRenturData) {
                data[att] = getRenturData.join(', ');
              }
            }
          }

          if (
            itemtype.editorType == 'dxTagBox' &&
            itemtype.entityField.DataSourceType == DataSourceType.Entity
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getEntityRenturData = [];
              if (entitydiclist) {
                for (const entitydic in entitydiclist) {
                  const entitydicValue = entitydiclist[entitydic];
                  if (itemtype.entityField.DataSource == entitydicValue.Code) {
                    const result = entitydicValue.Response.Results;
                    for (const d in result) {
                      for (const dv in dvalue) {
                        if (dvalue[dv] == result[d].Id) {
                          if (itemtype.entityField.DataSource == 'cw_app_child') {
                            getEntityRenturData.push(
                              result[d].FirstName + ' ' + result[d].LastName,
                            );
                          } else if (itemtype.entityField.DataSource == 'cw_sys_organization') {
                            getEntityRenturData.push(result[d].Name);
                          } else if (itemtype.entityField.DataSource == 'cw_pro_provider') {
                            getEntityRenturData.push(result[d].ProviderName);
                          } else if (itemtype.entityField.DataSource == 'cw_sys_user') {
                            getEntityRenturData.push(
                              result[d].FirstName + ' ' + result[d].LastName,
                            );
                          } else if (itemtype.entityField.DataSource == 'cw_sys_role') {
                            getEntityRenturData.push(result[d].RoleName);
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (getEntityRenturData) {
                data[att] = getEntityRenturData.join(', ');
              }
            }
          }
        }
      }
    }
    return data;
  }

  public async convertToSmartListLocalData(data: any, item: any, dicList: any) {
    if (!data) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
      const val = data[att];
      if (val) {
        for (var k in item) {
          const itemtype = item[k];
          if (att != itemtype.entityField.FieldName) {
            continue;
          }
          if (data[att] && itemtype.entityField && itemtype.entityField.GridColumnsOptions) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = this.converU2lDateTime(
                data[att],
                itemtype.entityField.GridColumnsOptions,
              );
            }
          }
          if (
            itemtype.entityField.GridColumnsOptions &&
            itemtype.entityField.GridColumnsOptions.format == 'HH:mm'
          ) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = moment(data[att], 'HHmm').format('HH:mm A');
              break;
            }
          }
          if (
            itemtype.editorType == 'dxTagBox' &&
            itemtype.entityField.DataSourceType == DataSourceType.Dictionary
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getRenturData = [];
              // let getdic = await this.entityService.searchDictDataByCode(itemtype.entityField.DataSource);
              if (dicList) {
                for (const dic in dicList) {
                  const dicValue = dicList[dic];
                  if (itemtype.entityField.DataSource == dicValue.Code) {
                    for (const d in dicValue.DicList) {
                      const getdic = dicValue.DicList;
                      for (const dv in dvalue) {
                        if (dvalue[dv] == getdic[d].Code) {
                          getRenturData.push(getdic[d].Name);
                        }
                      }
                    }
                  }
                }
              }
              if (getRenturData) {
                data[att] = getRenturData.join(', ');
              }
            }
          }
        }
      }
    }
    return data;
  }

  public async convertToApplicationSmartListLocalData(data: any, item: any, dicList: any) {
    if (!data) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
      if (att == 'CP__Schedule') {
        data['CP__ScheduleName'] = data[att] ? data[att].split(',') : [];
      }

      if (att != 'CP__Schedule' && att != 'CP__ScheduleName') {
        const val = data[att];
        if (val) {
          for (var k in item) {
            const itemtype = item[k];
            if (itemtype.entityField && att != itemtype.entityField.FieldName) {
              continue;
            }
            if (data[att] && itemtype.entityField && itemtype.entityField.GridColumnsOptions) {
              if (att == itemtype.entityField.FieldName) {
                data[att] = this.converU2lDateTime(
                  data[att],
                  itemtype.entityField.GridColumnsOptions,
                );
              }
            }
            if (itemtype.entityField) {
              if (
                itemtype.entityField.GridColumnsOptions &&
                itemtype.entityField.GridColumnsOptions.format == 'HH:mm'
              ) {
                if (att == itemtype.entityField.FieldName) {
                  data[att] = moment(data[att], 'HHmm').format('HH:mm A');
                  break;
                }
              }
              if (
                itemtype.editorType == 'dxTagBox' &&
                itemtype.entityField.DataSourceType == DataSourceType.Dictionary
              ) {
                if (att == itemtype.entityField.FieldName) {
                  let v = val.toString();
                  let dvalue = v.split(',');
                  let getRenturData = [];
                  // let getdic = await this.entityService.searchDictDataByCode(itemtype.entityField.DataSource);
                  if (dicList) {
                    for (const dic in dicList) {
                      const dicValue = dicList[dic];
                      if (itemtype.entityField.DataSource == dicValue.Code) {
                        for (const d in dicValue.DicList) {
                          const getdic = dicValue.DicList;
                          for (const dv in dvalue) {
                            if (dvalue[dv] == getdic[d].Code) {
                              getRenturData.push(getdic[d].Name);
                            }
                          }
                        }
                      }
                    }
                  }
                  if (getRenturData) {
                    data[att] = getRenturData.join(', ');
                  }
                }
              }
            }
          }
        }
      }
    }
    return data;
  }

  public async convertToApplicationListLocalData(
    data: any,
    item: any,
    dicList: any,
    entitydiclist: any,
  ) {
    if (!data) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
      const val = data[att];
      if (val) {
        for (var k in item) {
          const itemtype = item[k];
          if (att != itemtype.entityField.FieldName) {
            continue;
          }
          if (data[att] && itemtype.entityField && itemtype.entityField.GridColumnsOptions) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = this.converU2lDateTime(
                data[att],
                itemtype.entityField.GridColumnsOptions,
              );
            }
          }
          if (itemtype.editorOptions && itemtype.editorOptions.type == 'time') {
            if (att == itemtype.entityField.FieldName) {
              data[att] = moment(data[att], 'HHmm').format('HH:mm A');
              break;
            }
          }
          if (
            itemtype.editorType == 'dxTagBox' &&
            itemtype.entityField.DataSourceType == DataSourceType.Dictionary
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getRenturData = [];
              // let getdic = await this.entityService.searchDictDataByCode(itemtype.entityField.DataSource);
              // for(const d in getdic){
              //     for(const dv in dvalue){
              //         if(dvalue[dv]==getdic[d].Code){
              //             getRenturData.push(getdic[d].Name);
              //         }
              //     }
              // }
              if (dicList) {
                for (const dic in dicList) {
                  const dicValue = dicList[dic];
                  if (itemtype.entityField.DataSource == dicValue.Code) {
                    for (const d in dicValue.DicList) {
                      const getdic = dicValue.DicList;
                      for (const dv in dvalue) {
                        if (dvalue[dv] == getdic[d].Code) {
                          getRenturData.push(getdic[d].Name);
                        }
                      }
                    }
                  }
                }
              }

              if (getRenturData) {
                data[att] = getRenturData.join(', ');
              }
            }
          }

          if (
            (itemtype.editorType == 'dxSelectBox' ||
              itemtype.editorType == 'dxRadioBox' ||
              itemtype.editorType == 'dxTagBox') &&
            itemtype.entityField.DataSourceType == DataSourceType.Entity
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getEntityRenturData = [];
              if (entitydiclist) {
                for (const entitydic in entitydiclist) {
                  const entitydicValue = entitydiclist[entitydic];
                  if (itemtype.entityField.DataSource == entitydicValue.Code) {
                    const result = entitydicValue.Response.Results;
                    for (const d in result) {
                      for (const dv in dvalue) {
                        if (dvalue[dv] == result[d].Id.toString()) {
                          if (itemtype.entityField.DataSource == 'cw_app_child') {
                            getEntityRenturData.push(
                              result[d].FirstName + ' ' + result[d].LastName,
                            );
                          } else if (itemtype.entityField.DataSource == 'cw_sys_organization') {
                            getEntityRenturData.push(result[d].Name);
                          } else if (itemtype.entityField.DataSource == 'cw_pro_provider') {
                            getEntityRenturData.push(result[d].ProviderName);
                          } else if (itemtype.entityField.DataSource == 'cw_sys_user') {
                            getEntityRenturData.push(
                              result[d].FirstName + ' ' + result[d].LastName,
                            );
                          } else if (itemtype.entityField.DataSource == 'cw_sys_role') {
                            getEntityRenturData.push(result[d].RoleName);
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (getEntityRenturData) {
                data[att] = getEntityRenturData.join(', ');
              }
            }
          }
        }
      }
    }
    return data;
  }

  public async convertToReportListLocalData(
    data: any,
    item: any,
    dicList: any,
    specialConditionsValue = null,
  ) {
    if (!data) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
      const val = data[att];
      if (val) {
        for (var k in item) {
          const itemtype = item[k];
          if (att != itemtype.entityField.FieldName) {
            continue;
          }
          if (data[att] && itemtype.entityField && itemtype.entityField.GridColumnsOptions) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = this.converU2lDateTime(
                data[att],
                itemtype.entityField.GridColumnsOptions,
              );
            }
          }
          if (
            itemtype.entityField.GridColumnsOptions &&
            itemtype.entityField.GridColumnsOptions.format == 'HH:mm'
          ) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = moment(data[att], 'HHmm').format('HH:mm A');
              break;
            }
          }
          if (
            itemtype.editorType == 'dxTagBox' &&
            itemtype.entityField.DataSourceType == DataSourceType.Dictionary
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getRenturData = [];
              // let getdic = await this.entityService.searchDictDataByCode(itemtype.entityField.DataSource);
              if (dicList) {
                for (const dic in dicList) {
                  const dicValue = dicList[dic];
                  if (itemtype.entityField.DataSource == dicValue.Code) {
                    for (const d in dicValue.DicList) {
                      const getdic = dicValue.DicList;
                      for (const dv in dvalue) {
                        if (dvalue[dv] == getdic[d].Code) {
                          getRenturData.push(getdic[d].Name);
                        }
                      }
                    }
                  }
                }
              }
              if (getRenturData) {
                data[att] = getRenturData.join(', ');
              }
            }
          }
        }
        //处理特殊字段显示
        if (specialConditionsValue && specialConditionsValue.hasAsOfData) {
          if (att == 'CD__DOB_AsOfDateAge') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeAgeByChildDOB(data[att], specialConditionsValue.Value, 'age');
            } else {
              data[att] = '';
            }
          } else if (att == 'CD__DOB_AsOfDateAgeGroup') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeAgeByChildDOB(
                data[att],
                specialConditionsValue.Value,
                'agegroup',
              );
            } else {
              data[att] = '';
            }
          } else if (att == 'CP__IdealStartDate_AsOfDateDaysAway') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeDataByDaysAway(
                data[att],
                specialConditionsValue.Value,
                'daysaway',
              );
            } else {
              data[att] = null;
            }
          } else if (att == 'CP__IdealStartDate_AsOfDateDaysAwayGroup') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeDataByDaysAway(
                data['CP__IdealStartDate'],
                specialConditionsValue.Value,
                'daysawaygroup',
              );
            } else {
              data[att] = '';
            }
          }
        } else {
          // data[att] = '';
        }
      } else {
        if (att == 'CP__IdealStartDate_AsOfDateDaysAway') {
          data[att] = null;
        }
      }
    }
    return data;
  }

  public async convertToReportPivotListLocalData(
    data: any,
    item: any,
    dicList: any,
    entitydiclist: any,
    specialConditionsValue = null,
  ) {
    if (!data) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in data) {
      const val = data[att];
      if (val || val == 0) {
        for (var k in item) {
          const itemtype = item[k];
          if (att != itemtype.entityField.FieldName) {
            continue;
          }

          if (data[att] && itemtype.entityField && itemtype.entityField.GridColumnsOptions) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = this.converU2lDateTime(
                data[att],
                itemtype.entityField.GridColumnsOptions,
              );
            }
          }
          if (
            itemtype.entityField.GridColumnsOptions &&
            itemtype.entityField.GridColumnsOptions.format == 'HH:mm'
          ) {
            if (att == itemtype.entityField.FieldName) {
              data[att] = moment(data[att], 'HHmm').format('HH:mm A');
              break;
            }
          }

          if (
            (itemtype.editorType == 'dxSelectBox' ||
              itemtype.editorType == 'dxRadioBox' ||
              itemtype.editorType == 'dxTagBox') &&
            itemtype.entityField.DataSourceType == DataSourceType.Dictionary
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getRenturData = [];
              // let getdic = await this.entityService.searchDictDataByCode(itemtype.entityField.DataSource);
              if (dicList) {
                for (const dic in dicList) {
                  const dicValue = dicList[dic];
                  if (itemtype.entityField.DataSource == dicValue.Code) {
                    for (const d in dicValue.DicList) {
                      const getdic = dicValue.DicList;
                      for (const dv in dvalue) {
                        if (dvalue[dv] == getdic[d].Code) {
                          getRenturData.push(getdic[d].Name);
                        }
                      }
                    }
                  }
                }
              }
              if (getRenturData) {
                data[att] = getRenturData.join(', ');
              }
            }
          }

          if (
            (itemtype.editorType == 'dxSelectBox' ||
              itemtype.editorType == 'dxRadioBox' ||
              itemtype.editorType == 'dxTagBox') &&
            itemtype.entityField.DataSourceType == DataSourceType.Entity
          ) {
            if (att == itemtype.entityField.FieldName) {
              let v = val.toString();
              let dvalue = v.split(',');
              let getEntityRenturData = [];
              if (entitydiclist) {
                for (const entitydic in entitydiclist) {
                  const entitydicValue = entitydiclist[entitydic];
                  if (itemtype.entityField.DataSource == entitydicValue.Code) {
                    const result = entitydicValue.Response.Results;
                    for (const d in result) {
                      for (const dv in dvalue) {
                        if (dvalue[dv] == result[d].Id.toString()) {
                          if (itemtype.entityField.DataSource == 'cw_app_child') {
                            getEntityRenturData.push(
                              result[d].FirstName + ' ' + result[d].LastName,
                            );
                          } else if (itemtype.entityField.DataSource == 'cw_sys_organization') {
                            getEntityRenturData.push(result[d].Name);
                          } else if (itemtype.entityField.DataSource == 'cw_pro_provider') {
                            getEntityRenturData.push(result[d].ProviderName);
                          } else if (itemtype.entityField.DataSource == 'cw_sys_user') {
                            getEntityRenturData.push(
                              result[d].FirstName + ' ' + result[d].LastName,
                            );
                          } else if (itemtype.entityField.DataSource == 'cw_sys_role') {
                            getEntityRenturData.push(result[d].RoleName);
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (getEntityRenturData) {
                data[att] = getEntityRenturData.join(', ');
              }
            }
          }
        }

        //处理特殊字段显示
        if (specialConditionsValue && specialConditionsValue.hasAsOfData) {
          if (att == 'CD__DOB_AsOfDateAge') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeAgeByChildDOB(data[att], specialConditionsValue.Value, 'age');
              // console.log('====CD__DOB_AsOfDateAge', data );
            } else {
              data[att] = '';
            }
          } else if (att == 'CD__DOB_AsOfDateAgeGroup') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeAgeByChildDOB(
                data[att],
                specialConditionsValue.Value,
                'agegroup',
              );
              // console.log('====CD__DOB_AsOfDateAgeGroup', data );
            } else {
              data[att] = '';
            }
          } else if (att == 'CP__IdealStartDate_AsOfDateDaysAway') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeDataByDaysAway(
                data[att],
                specialConditionsValue.Value,
                'daysaway',
              );
              // console.log('====CP__IdealStartDate_AsOfDateDaysAway', data );
            } else {
              data[att] = '';
            }
          } else if (att == 'CP__IdealStartDate_AsOfDateDaysAwayGroup') {
            if (specialConditionsValue.Value) {
              data[att] = this.changeDataByDaysAway(
                data['CP__IdealStartDate'],
                specialConditionsValue.Value,
                'daysawaygroup',
              );
              // console.log('====CP__IdealStartDate_AsOfDateDaysAwayGroup', data );
            } else {
              data[att] = '';
            }
          }
        } else {
          // data[att] = '';
        }
      } else {
        if (att == 'CP__IdealStartDate_AsOfDateDaysAway') {
          data[att] = '';
        }
      }
    }
    return data;
  }

  public convertToEntityNewData(entityType: WksEntity, entityData: any, formitem: any) {
    // tslint:disable-next-line:forin
    for (const att in entityData) {
      const field = entityType.FieldMap[att];
      if (
        field &&
        DataType[field.DataType] == DataType.Date &&
        !(field.EditorOptions && field.EditorOptions.type == 'time')
      ) {
        const d = moment(entityData[att]);
        console.log('date');
        if (d.isValid()) {
          const formatDate = d.format('YYYYMMDD');
          //输入的日期格式年份不是四位的日期
          if (formatDate && formatDate.startsWith('0')) {
            entityData[att] = formatDate.replace('0', '2');
          } else {
            entityData[att] = formatDate;
          }
        } else {
          entityData[att] = null;
        }
      }
      if (field && DataType[field.DataType] == DataType.Time) {
        console.log('time');
        const d = moment(entityData[att]);
        if (d.isValid()) {
          entityData[att] = d.format('HHmm');
        } else {
          entityData[att] = null;
        }
      }

      if (
        field &&
        DataType[field.DataType] == DataType.DateTime &&
        !(field.EditorOptions && field.EditorOptions.type == 'time')
      ) {
        const d = moment(entityData[att]);
        console.log('datetime');
        if (d.isValid()) {
          entityData[att] = d;
        } else {
          entityData[att] = null;
        }
      }

      if (
        field &&
        (DataType[field.DataType] == DataType.Date ||
          DataType[field.DataType] == DataType.DateTime ||
          DataType[field.DataType] == DataType.Text ||
          DataType[field.DataType] == DataType.String)
      ) {
        if (field.EditorOptions && field.EditorOptions.type == 'time') {
          console.log('ssdate');
          const d = moment(entityData[att]);
          if (d.isValid()) {
            entityData[att] = d.format('HHmm');
          } else {
            entityData[att] = null;
          }
        }
      }

      for (var k in formitem) {
        const itemtype = formitem[k];
        if (itemtype.editorType == 'dxTagBox') {
          if (att == itemtype.entityField.BindFieldName) {
            if (entityData[att] && typeof entityData[att] == 'object') {
              let v = entityData[att].join(',');
              entityData[att] = v;
            }
          }
        }

        if (itemtype.editorType == 'dxSelectBox') {
          if (att == itemtype.entityField.BindFieldName) {
            if (field && DataType[field.DataType] == DataType.Int) {
              if (!entityData[att]) {
                entityData[att] = 0;
              }
            }
          }
        }
      }
    }
    return entityData;
  }

  public convertToEntityData(
    entityType: WksEntity,
    formData: any,
    entityData: any,
    entityTypeCode: any,
    entityId: any,
    formitem: any,
    dicList: any,
  ) {
    // tslint:disable-next-line:forin
    const tablelist = [];
    for (const att in entityData) {
      const field = entityType.FieldMap[att];

      const table = att.split('__');
      let hasExitTable = false;
      for (const t in tablelist) {
        if (tablelist[t] == table[0]) {
          hasExitTable = true;
        }
      }

      if (!hasExitTable) {
        tablelist.push(table[0]);
      }

      if (
        field &&
        DataType[field.DataType] == DataType.Date &&
        !(field.EditorOptions && field.EditorOptions.type == 'time')
      ) {
        const d = moment(entityData[att]);
        console.log('date');
        if (d.isValid()) {
          const formatDate = d.format('YYYYMMDD');
          //输入的日期格式年份不是四位的日期
          if (formatDate && formatDate.startsWith('0')) {
            entityData[att] = formatDate.replace('0', '2');
          } else {
            entityData[att] = formatDate;
          }
        } else {
          entityData[att] = null;
        }
      }
      if (field && DataType[field.DataType] == DataType.Time) {
        console.log('time');
        const d = moment(entityData[att]);
        if (d.isValid()) {
          entityData[att] = d.format('HHmm');
        } else {
          entityData[att] = null;
        }
      }

      if (
        field &&
        DataType[field.DataType] == DataType.DateTime &&
        !(field.EditorOptions && field.EditorOptions.type == 'time')
      ) {
        const d = moment(entityData[att]);
        console.log('datetime');
        if (d.isValid()) {
          entityData[att] = d;
        } else {
          entityData[att] = null;
        }
      }

      if (
        field &&
        (DataType[field.DataType] == DataType.Date ||
          DataType[field.DataType] == DataType.DateTime ||
          DataType[field.DataType] == DataType.Text ||
          DataType[field.DataType] == DataType.String)
      ) {
        if (field.EditorOptions && field.EditorOptions.type == 'time') {
          console.log('ssdate');
          const d = moment(entityData[att]);
          if (d.isValid()) {
            entityData[att] = d.format('HHmm');
          } else {
            entityData[att] = null;
          }
        }
      }

      for (var k in formitem) {
        const itemtype = formitem[k];
        if (itemtype.editorType == 'dxTagBox') {
          if (att == itemtype.entityField.BindFieldName) {
            //Exclude deleted Dictionary
            if (
              itemtype.entityField.DataSourceType == DataSourceType.Dictionary &&
              dicList &&
              dicList.length > 0
            ) {
              let dvalue = entityData[att];
              let getRenturData = [];
              for (const dic in dicList) {
                const dicValue = dicList[dic];
                if (itemtype.entityField.DataSource == dicValue.Code) {
                  if (dicValue.DicList && dicValue.DicList.length > 0) {
                    for (const dv in dvalue) {
                      const containLength = dicValue.DicList.filter(
                        f => f.Code == dvalue[dv],
                      ).length;
                      if (containLength > 0) {
                        getRenturData.push(dvalue[dv]);
                      }
                    }
                  }
                }
              }
              if (getRenturData) {
                entityData[att] = getRenturData.join(',');
              } else {
                if (entityData[att]) {
                  let v = entityData[att].join(',');
                  entityData[att] = v;
                }
              }
            } else {
              if (entityData[att]) {
                let v = entityData[att].join(',');
                entityData[att] = v;
              }
            }
          }
        }

        if (itemtype.editorType == 'dxSelectBox') {
          if (att == itemtype.entityField.BindFieldName) {
            if (field && DataType[field.DataType] == DataType.Int) {
              console.log(field, entityData[att]);
              if (!entityData[att]) {
                entityData[att] = 0;
              }
            }
          }
        }
      }
    }

    for (const f in formData) {
      for (const t in tablelist) {
        const tn = tablelist[t] + '__Id';
        if (f == tn) {
          entityData[f] = formData[f];
        }
      }
    }

    const id = entityTypeCode + '__Id';
    entityData[id] = entityId;

    return entityData;
  }

  private calculateAge(now, bod, item = null) {
    const mstr = ' Months';
    const fmt = 'YYYYMMDD';
    const ns = now.format(fmt);
    const bs = bod.format(fmt);
    if (ns == bs) {
      return '0' + mstr;
    }
    let isRevert = false;
    if (ns < bs) {
      // 生日位于As of Date 之前，负的日期
      isRevert = true;
      now = [now, bod];
      bod = now[0];
      now = now[1];
    }
    let age = ' 0 ' + mstr,
      yrs = 0,
      mons = 0;
    // item.birth = "ok";
    // item.isError = false;
    // $scope.isMinDateError = false;
    // var bod = moment(item.DOB, "MM/DD/YYYY");
    // if (!bod.isValid() && item.DOB.indexOf('/') == 4) {
    //     bod = moment(item.DOB, "YYYY/MM/DD");
    // }
    // var now = moment();
    // if (!bod.isValid() || bod.format("YYYYMMDD") > now.format("YYYYMMDD")) {
    //     item.ageStr = age;
    //     item.isError = true;
    //     item.birth = "";
    //     return false;
    // };
    // var firstDate = moment("01/01/1990", "MM/DD/YYYY");
    // if (!bod.isValid() || bod.format("YYYYMMDD") < firstDate.format("YYYYMMDD")) {
    //     item.ageStr = age;
    //     // $scope.isMinDateError = true;
    //     item.birth = "";
    //     return false;
    // } else {
    //     // $scope.isMinDateError = false;
    // }
    var by = bod.year(),
      bm = bod.month(),
      bd = bod.date();
    var ny = now.year(),
      nm = now.month(),
      nd = now.date();

    var ageDay = bd > nd ? -1 : 0;
    var isPatchMon = false;
    if (by == ny) {
      if (bm < nm) {
        age = nm - bm + ageDay + mstr;
        isPatchMon = true;
      }
    } else if (by < ny) {
      yrs = ny - by;
      if (bm <= nm) {
        isPatchMon = true;
        mons = nm - bm + ageDay;
      } else {
        yrs = yrs - 1;
        isPatchMon = true;
        mons = 12 - bm + nm + ageDay;
      }
      if (mons < 0) {
        yrs = yrs > 0 ? yrs - 1 : 0;
        mons = 12 + mons;
      }
      if (yrs == 0) {
        if (mons == 0) {
          age = ' 0 ' + mstr;
        } else {
          if (isPatchMon) {
            ageDay = 0;
          }
          age = mons + ageDay + mstr;
        }
      } else {
        if (isPatchMon) {
          ageDay = 0;
        }
        mons = mons + ageDay;
        age = yrs + ' Years ' + (mons > 0 ? mons + mstr : '');
      }
    }
    // console.info("calculate age,", age);
    if (isRevert) {
      age = age.trim();
      if (age.indexOf('0') === 0) {
      } else {
        age = '-' + age;
      }
    }
    return age;
  }

  public changeAgeByChildDOB(dob, asofdate, type) {
    const asOfDateisValid = moment(asofdate).isValid();
    const dobisValid = moment(dob).isValid();
    let returnValue = '';
    if (asOfDateisValid && dobisValid) {
      let daysdiffer = moment(asofdate).diff(dob, 'days');
      if (daysdiffer >= 0) {
      }
      //计算时间差
      // let duration = moment.duration(moment(asofdate).diff(dob));
      // let years = duration.years();
      // let months= duration.months();
      // let days= duration.days();
      let a = moment(asofdate);
      let b = moment(dob);

      let years = a.diff(b, 'year');
      b.add(years, 'years');

      let months = a.diff(b, 'months');
      b.add(months, 'months');

      let days = a.diff(b, 'days');
      // console.log(years + ' years ' + months + ' months ' + days + ' days');
      if (type == 'age') {
        returnValue = this.calculateAge(moment(asofdate), moment(dob));
        return returnValue;
        let yearsValue = '';
        if (years != 0) {
          yearsValue = years + ' Years';
        }
        if (years < 0 && months < 0) {
          months = months * -1;
        }
        returnValue = yearsValue + (months !== 0 ? ' ' + months + ' Months' : '');
        // if (!returnValue) {
        //     if (days <= 30) {
        //         returnValue = '1 Months';
        //     }
        //     if (days > 30) {
        //         returnValue = '-1 Months';
        //     }
        // }
      } else if (type == 'agegroup') {
        if (daysdiffer >= 0 && daysdiffer < 56) {
          returnValue = '0';
        } else if (daysdiffer >= 56 && years < 2) {
          returnValue = 'I/T';
        } else if (years >= 2 && years < 3) {
          returnValue = '2s';
        } else if (years >= 3 && years < 6) {
          returnValue = '3-5';
        } else {
          returnValue = '';
        }
        // fix: CWM-1198:
        // SF - Report - age group - if the age is < 0 (unborn), mark the category as 0. If it is over 5, leave it blank
        if (daysdiffer <= 0) {
          returnValue = '0';
        }
      }
    }
    return returnValue;
  }

  public changeDataByDaysAway(startdate, asofdate, type) {
    const asOfDateisValid = moment(asofdate).isValid();
    const dobisValid = moment(startdate).isValid();
    let returnValue = '';
    if (asOfDateisValid && dobisValid) {
      let daysdiffer = moment(startdate).diff(asofdate, 'days');
      if (daysdiffer >= 0) {
      }
      if (type == 'daysaway') {
        returnValue = daysdiffer.toString();
      } else if (type == 'daysawaygroup') {
        if (daysdiffer <= 0) {
          return (returnValue = 'Past');
        } else if (daysdiffer > 0 && daysdiffer <= 182) {
          return (returnValue = 'Immediate');
        } else if (daysdiffer > 182 && daysdiffer <= 365) {
          return (returnValue = 'Intermediate');
        } else if (daysdiffer > 365) {
          return (returnValue = 'Future');
        }
      }
    }
    return returnValue;
  }

  // entity datalist and dataitem list component
  public async getDataList(
    code: string,
    loadOptions: any,
    isAdvanced = false,
    includeVoid = false,
    exParams: string = null,
  ) {
    const client = this.factory.MctCareWaitClient;
    const entityType = await this.entityService.getEntity(code);
    const querycode = await this.getApiCode(code);
    let url = `/${querycode}/autoquery/or?`;
    if (!loadOptions.filter || isAdvanced) {
      url = `/${querycode}/autoquery/and?`;
    }
    // url += `EntityTypeId=${entityType.EntityType.Id}`;
    if (exParams) {
      url += exParams;
    }
    if (entityType && url.length > 0) {
      const params = this.convert.convertAutoOption(loadOptions);
      if (params) {
        url += '&' + params;
      }
      if (url.indexOf('&fields=') < 0) {
        let fields = entityType.Fields.map(x => x.FieldName).join(',');
        fields = fields + ',Id,Disable';
        url += '&fields=' + fields;
      }
      if (includeVoid) {
        url += '&IncludeDisable=true';
      }
      return client.get<any>(url);
    }
  }

  public async getEntityByRowId(code: string, rowId: any) {
    const client = this.factory.MctCareWaitClient;
    const postData = await this.getSearchApiByCode(code);
    const isStanfordParent =
      location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    postData.Id = rowId;
    postData.ActionUserType = isStanfordParent ? 0 : 1; //0:parent 1: agency
    return client.get(postData);
  }

  public async postEntity(entityCode: string, entityTable: any, parentApply = false) {
    const client = this.factory.MctCareWaitClient;
    const postData = await this.getAddApiByCode(entityCode);
    const isStanfordParent =
      location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    postData.Entity = entityTable;
    postData.ParentApply = parentApply;
    postData.ActionUserType = isStanfordParent ? 0 : 1; //0:parent 1: agency
    return client.post(postData);
  }

  public async putEntity(code: string, entity: any, parentApply = false) {
    const client = this.factory.MctCareWaitClient;
    const postData = await this.getUpdateApiByCode(code);
    const isStanfordParent =
      location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    postData.Entity = entity;
    postData.ParentApply = parentApply;
    postData.ActionUserType = isStanfordParent ? 0 : 1; //0:parent 1: agency
    return client.put(postData);
  }

  public async deleteEntity(code: string, rowId: any, parentApply = false) {
    const client = this.factory.MctCareWaitClient;
    const postData = await this.getDeleteApiByCode(code);
    const isStanfordParent =
      location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    postData.Id = rowId;
    postData.ParentApply = parentApply;
    postData.ActionUserType = isStanfordParent ? 0 : 1; //0:parent 1: agency
    return client.delete(postData);
  }

  public async restoreEntity(code: string, rowId: any, parentApply = false) {
    const client = this.factory.MctCareWaitClient;
    const postData = await this.getRestoreApiByCode(code);
    const isStanfordParent =
      location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    postData.Id = rowId;
    postData.ParentApply = parentApply;
    postData.ActionUserType = isStanfordParent ? 0 : 1; //0:parent 1: agency
    return client.put(postData);
  }

  public async checkApplicationEntity(entityCode: string, entityTable: any) {
    const client = this.factory.MctCareWaitClient;
    const postData = await this.getCheckApplicationApiByCode(entityCode);
    postData.Entitys = entityTable;
    return client.post(postData);
  }

  public async getApplicationStatusWorkflow(type: number, rowId: any) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetApplicationStatusWorkflowRequest();
    postData.ApplicationId = rowId;
    postData.UserType = type;
    return client.get(postData);
  }

  public async changeApplicationStatus(rowId: any, status: number) {
    const client = this.factory.MctCareWaitClient;
    const postData = new ChangeApplicationStatusRequest();
    postData.Id = rowId;
    postData.NewStatus = status;
    return client.put(postData);
  }

  public async getApplicantProviderStatusWorkflow(type: number, rowId: any) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetApplicantProviderStatusWorkflowRequest();
    postData.ApplicantProviderId = rowId;
    postData.UserType = type;
    return client.get(postData);
  }

  public async changeApplicantProviderStatus(rowId: any, status: number) {
    const client = this.factory.MctCareWaitClient;
    const postData = new ChangeApplicantProviderStatusRequest();
    postData.Id = rowId;
    postData.NewStatus = status;
    return client.put(postData);
  }

  async searchPaymentApplication(applicationId, reportCode) {
    const client = this.factory.MctCareWaitClient;
    let exParams = 'ApplicationId=' + applicationId + '&CP__Status=42';
    let url = '/autoquery/' + reportCode + '?';
    if (exParams) {
      url += exParams;
    }
    url += '&Disable=false';
    url += '&fields=ChildProviderId';
    return client.get<any>(url);
  }

  async getMappingByCode(code) {
    let cacheKey = 'getApplicationAllChild_' + code;
    let cacheData = this.cacheService.getCacheData(cacheKey);
    if (!cacheData) {
      const client = this.factory.MctCareWaitClient;
      const postData = new GetMappingByCodeRequest();
      postData.Code = code;
      postData.IncludeDisable = false;
      const response = await client.get(postData);
      this.cacheService.setCacheData(cacheKey, response);
      console.log(response);
      return response;
    }
    return cacheData;
  }

  // Workspace View
  public async getViewList(code, fields, exParams) {
    code = await this.getApiCode(code);
    const client = this.factory.MctCareWaitClient;
    let url = `/workspace/autoquery/${code}?`;
    url += '&fields=' + fields;
    if (exParams) {
      url += '&' + exParams;
    }
    return client.get<any>(url);
  }

  // System Core Entity
  public async getCoreDictList(code) {
    code = await this.getApiCode(code);
    const client = this.factory.MctCareWaitClient;
    const url = `/core/${code}/all`;
    return client.get<any>(url).then(res => {
      if (res.Results) {
        return res.Results;
      } else {
        return res.Data;
      }
    });
  }

  public async getCoreDictById(code, id) {
    code = await this.getApiCode(code);
    const client = this.factory.MctCareWaitClient;
    const url = `/core/${code}/get/${id}`;
    return client.get<any>(url).then(res => {
      return res.Data;
    });
  }

  //     // only used by Family and Provider component
  //     public async getEntityAutoQuery(code: string, entityType, loadOptions: any,
  //         isAdvanced = false, includeVoid = false) {
  //         code = await this.getApiCode(code);
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/${code}/query/or?` ;
  //         if (!loadOptions.filter || isAdvanced) {
  //             url = `/workspace/${code}/query?` ;
  //         }
  //         const params = this.convert.convertAutoOption(loadOptions);
  //         url +=  params;
  //         // url += '&fields=' + entityType.Fields.map(x => x.FieldName).join(',');
  //         if (url.indexOf('&Fields=') < 0) {
  //             url += '&Fields=' + entityType.Fields.map(x => x.FieldName).join(',');
  //         }
  //         if (includeVoid) {
  //             url += '&IncludeDisable=true';
  //         }
  //         return client.get<any>(url);
  //     }
  //     // used by Family Detail
  //     public async getEntityAllList(code: string, entityType: WksEntity, exParams: string) {
  //         code = await this.getApiCode(code);
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/${code}/query` ;
  //         url += '?' + exParams;
  //         url += '&fields=' + entityType.Fields.map(x => x.FieldName).join(',');
  //         return client.get<any>(url);
  //     }
  //     // only used by Family and Provider component
  //     public async getEntityErrorList(code: string, entityType: WksEntity) {
  //         code = await this.getApiCode(code);
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/${code}/query` ;
  //         url += '?ErrorContains= ';
  //         url += '&fields=' + entityType.Fields.map(x => x.FieldName).join(',') + ',Error';
  //         return client.get<any>(url);
  //     }

  //     // List Data
  //     // ccala
  //     public getListDataAutoQuery(entityType:WksEntity, loadOptions: any,
  //         isAdvanced = false, includeVoid = false) {
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/list/data/or?EntityTypeId=` ;
  //         if (!loadOptions.filter || isAdvanced) {
  //             url = `/workspace/list/data?EntityTypeId=` ;
  //         }
  //         url += entityType.EntityType.Id;
  //         const params = this.convert.convertAutoOption(loadOptions);
  //         url += '&' + params;
  //         if (url.indexOf('&Fields=') < 0) {
  //             url += '&fields=' + entityType.Fields.map(x => x.FieldName).join(',');
  //         }
  //         if (includeVoid) {
  //             url += '&IncludeDisable=true';
  //         }
  //         // for header filter
  //         if (loadOptions.dataField && loadOptions.group) {
  //             const key  = loadOptions.dataField;
  //             return client.get<any>(url).then(r => {
  //                 const data = [];
  //                 for (const item of r.Results) {
  //                     if (key in item) {
  //                         data.push({key: item[key] || '', items: [] , count:1});
  //                     }
  //                 }
  //                 r.Results = data;
  //                 if (r.Meta && r.Meta.Total) {
  //                     r.Total =  r.Meta.Total;
  //                 }
  //                 return r;
  //             });
  //         } else {
  //             return client.get<any>(url);
  //         }
  //     }
  //     // ccala
  //     public async getListDataAllList(entityType: WksEntity, exParams: string) {
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/list/data?EntityTypeId=` ;
  //         url += entityType.EntityType.Id;
  //         url += '&' + exParams;
  //         url += '&fields=' + entityType.Fields.map(x => x.FieldName).join(',');
  //         return client.get<any>(url);
  //     }
  //     // ccala
  //     public async getListDataErrorList(entityType: WksEntity) {
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/list/data?EntityTypeId=` ;
  //         url += entityType.EntityType.Id;
  //         url += '&ErrorContains= ';
  //         url += '&fields=' + entityType.Fields.map(x => x.FieldName).join(',') + ',Error';
  //         return client.get<any>(url);
  //     }

  //     // Track service
  //     // ccala and service
  //     public getSerivceDataAutoQuery(contract: WksContract, loadOptions: any,
  //         isAdvanced = false, includeVoid = false) {

  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/trackservices/query/or?ContractId=` ;
  //         if (!loadOptions.filter || isAdvanced) {
  //             url = `/workspace/trackservices/query?ContractId=` ;
  //         }
  //         url += contract.Id;
  //         const params = this.convert.convertAutoOption(loadOptions);
  //         url += '&' + params;
  //         if (url.indexOf('&fields=') < 0) {
  //             url += '&fields=' + contract.ServiceEntityType.Fields.map(x => x.FieldName).join(',');
  //         }
  //         if (includeVoid) {
  //             url += '&IncludeDisable=true';
  //         }
  //         // for header filter
  //         if (loadOptions.dataField && loadOptions.group) {
  //             const key  = loadOptions.dataField;
  //             return client.get<any>(url).then(r => {
  //                 const data = [];
  //                 for (const item of r.Results) {
  //                     if (key in item) {
  //                         data.push({key: item[key] || '', items: [] , count:1});
  //                     }
  //                 }
  //                 r.Results = data;
  //                 if (r.Meta && r.Meta.Total) {
  //                     r.Total =  r.Meta.Total;
  //                 }
  //                 return r;
  //             });
  //         } else {
  //             return client.get<any>(url);
  //         }
  //     }
  //     // ccala and service
  //     public async getServiceDataErrorList(contract: WksContract) {
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/trackservices/query?ContractId=` ;
  //         url += contract.Id;
  //         url += '&ErrorContains= ';
  //         url += '&fields=' + contract.ServiceEntityType.Fields.map(x => x.FieldName).join(',') + ',Error';
  //         return client.get<any>(url);
  //     }
  // // ccala and service
  //     public async getServiceDataAllList(contract: WksContract, exParams:string) {
  //         const client = this.factory.CareTrackClient;
  //         let url = `/workspace/trackservices/query?` ;
  //         url += exParams;
  //         if (contract) {
  //             url += '&fields=' + contract.ServiceEntityType.Fields.map(x => x.FieldName).join(',');
  //         }
  //         return client.get<any>(url);
  //     }
  // // ccala and service
  //     public getServiceByRowId(rowId: string) {
  //         const client = this.factory.CareTrackClient;
  //         const url = `/workspace/trackservices/${rowId}` ;
  //         return client.get<any>(url);
  //     }

  //     public postService(contractId, entity: any, exParams: any = null) {
  //         const client = this.factory.CareTrackClient;
  //         const url = `/workspace/trackservices/` ;
  //         let postData: any = {
  //             ContractId: contractId,
  //             Entity: entity
  //         };
  //         if (exParams) {
  //             postData = exParams;
  //             postData.Entity = entity;
  //             postData.ContractId = contractId;
  //         }
  //         return client.postToUrl<any>(url, postData);
  //     }

  //     public putService(rowId:string, updateFields: any) {
  //         const client = this.factory.CareTrackClient;
  //         const url = `/workspace/trackservices/${rowId}` ;
  //         const postData: any = {
  //             RowId: rowId,
  //             UpdateFields: updateFields
  //         };
  //         return client.putToUrl<any>(url, postData);
  //     }

  //     public deleteService(rowId:string) {
  //         const client = this.factory.CareTrackClient;
  //         const url = `/workspace/trackservices/${rowId}` ;
  //         return client.delete<any>(url);
  //     }
}
