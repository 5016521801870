import { EntityType, EntityField, EntityTableReportFieldItem } from './datamodel/entityType';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../carecloud/common.api';

export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class GetAllEntityTypeRequest extends BaseRequest implements IReturn<Response<any>> {
  IncludeDisable: boolean;
  Apply: number; //0:Entity 1:SmartList 2:Report
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetAllEntityTypeRequest';
  }
}

export class GetEntityTypeByIdRequest extends BaseRequest implements IReturn<EntityTypeResponse> {
  Id: any;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'GetEntityTypeByIdRequest';
  }
}

export class EntityTypeResponse extends BaseResponse {
  EntityType: EntityType;
  Fields: EntityField[];
}

export class GetEntityTableFieldByEntityTypeIdRequest
  extends BaseRequest
  implements IReturn<Response<any>>
{
  EntityTypeId: any;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetEntityTableFieldByEntityTypeIdRequest';
  }
}

export class PutEntityFieldRequest extends BaseRequest implements IReturn<Response<any>> {
  Id: any;
  UpdateFields: any;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'PutEntityFieldRequest';
  }
}

// [Route("/entity/type", "Post")]
export class PostEntityTypeRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  Entity: { [key: string]: any };
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'PostEntityTypeRequest';
  }
}

// [Route("/entity/type/{Id}", "DELETE")]
export class DeleteEntityTypeRequest extends CareWaitBaseRequest implements IReturn<BaseResponse> {
  Id: string;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteEntityTypeRequest';
  }
}

// [Route("/entity/type/restore/{Id}", "PUT")]
export class RestoreEntityTypeRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  Id: string;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'RestoreEntityTypeRequest';
  }
}

export class PostEntityFieldRequest extends BaseCareWaitRequest implements IReturn<Response<any>> {
  EntityTypeId: number;
  Entity: EntityField;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'PostEntityFieldRequest';
  }
}

export class DeleteEntityFieldRequest extends BaseCareWaitRequest implements IReturn<BaseResponse> {
  Id: any;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteEntityFieldRequest';
  }
}

export class PutEntityTypeRequest
  extends BaseCareWaitRequest
  implements IReturn<EntityTypeResponse>
{
  Id: any;
  UpdateFields: any;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'PutEntityTypeRequest';
  }
}

export class GetEntityTypeByCodeRequest
  extends BaseCareWaitRequest
  implements IReturn<EntityTypeResponse>
{
  EntityTypeCode: string;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'GetEntityTypeByCodeRequest';
  }
}

//[Route("/entity/field/restore/{Id}", "PUT")]
export class RestoreEntityFieldRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<EntityField>>
{
  Id: string;
  createResponse() {
    return new Response<EntityField>();
  }
  getTypeName() {
    return 'RestoreEntityFieldRequest';
  }
}

// [Route("/entity/table/reportfield/query/by-entitytableid/{EntityTableId}", "GET")]
export class GetEntityTableReportFieldByEntityTableIdRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<EntityTableReportFieldItem[]>>
{
  EntityTableId: string;
  createResponse() {
    return new Response<EntityTableReportFieldItem[]>();
  }
  getTypeName() {
    return 'GetEntityTableReportFieldByEntityTableIdRequest';
  }
}

// [Route("/entity/field/{Id}", "GET")]
export class GetEntityFieldByIdRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  Id: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetEntityFieldByIdRequest';
  }
}

// [Route("/entity/reporttype/{Id}", "GET")]
export class GetEntityReportTypeByIdRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  Id: string;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'GetEntityReportTypeByIdRequest';
  }
}

// [Route("/entity/table/reportfield/query/by-reportdatasourcecode/{ReportDataSourceCode}", "GET")]
export class GetEntityTableReportFieldByReportDataSourceCodeRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<EntityTableReportFieldItem[]>>
{
  ReportDataSourceCode: string;
  createResponse() {
    return new Response<EntityTableReportFieldItem[]>();
  }
  getTypeName() {
    return 'GetEntityTableReportFieldByReportDataSourceCodeRequest';
  }
}

// [Route("/entity/type/userdparent/by-entitytypecode/{EntityTypeCode}", "GET")]
export class GetUsedParentEntityTypeByEntityTypeCodeRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  EntityTypeCode: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetUsedParentEntityTypeByEntityTypeCodeRequest';
  }
}

// [Route("/entity/type/import/{EntityTypeId}", "PUT")]
export class ImportEntityTypeRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  EntityTypeId: string;
  EntityTypeItem: any;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'ImportEntityTypeRequest';
  }
}

// [Route("/entity/table/additional/query/by-entitytypeid/{EntityTypeId}", "GET")]
export class GetAdditionalEntityTableByEntityTypeIdRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  EntityTypeId: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetAdditionalEntityTableByEntityTypeIdRequest';
  }
}

// [Route("/setting/query", "GET")]
export class GetAllWorkspaceSettingRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  Category: number;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetAllWorkspaceSettingRequest';
  }
}

// [Route("/setting", "PUT")]
export class PutWorkspaceSettingRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Setting: SysWorkspaceSetting[];
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'PutWorkspaceSettingRequest';
  }
}

export interface SysWorkspaceSetting {
  Id: string;
  WorkspaceId: string;
  CustomerId: number;
  Name: string;
  SettingKey: string;
  SettingValue: string;
  Description: string;
  Disable: boolean;
  CreateTime: Date | string;
  UpdateTime: Date | string;
  CreateUserId: number;
  UpdateUserId: number;
  Version: number;
}

// [Route("/thirdpart/affiliationdata/uploadfilepath", "GET")]
export class GetAffiliationDataUploadFilePathRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<string>>
{
  FileName: string;
  Type: string;
  EntityTypeCode: string;
  createResponse() {
    return new Response<string>();
  }
  getTypeName() {
    return 'GetAffiliationDataUploadFilePathRequest';
  }
}

// [Route("/entity/type/cleardefault/{EntityTypeId}", "PUT")]
export class ClearEntityTypeDefaultRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityTypeResponse>
{
  EntityTypeId: string;
  createResponse() {
    return new EntityTypeResponse();
  }
  getTypeName() {
    return 'ClearEntityTypeDefaultRequest';
  }
}
