export interface SysEntityTypeItem {
  EntityType: EntityType;
  Fields: EntityField[];
}

export interface IHasId<T> {
  Id: T;
}

export interface ITraceEntity {
  CreateTime: Date | string;
}

export interface MultiLanguageText {}

export interface EntityType extends IHasId<string>, ITraceEntity {
  Id: string;
  WorkspaceId: string;
  CustomerId: number;
  Code: string;
  Name: string;
  Name_OtherLan: { [key: string]: any };
  TableName: string;
  RootNodeKey: string;
  ParentNodeKey: string;
  ShowOrder: number;
  IsSystem: boolean;
  IsList: boolean;
  IsShared: boolean;
  IsOnlyView: boolean;
  RelatedEntities: string;
  ContainEntities: string;
  RelatedTab: string;
  RelatedModules: string;
  Description: string;
  Description_OtherLan: { [key: string]: any };
  Status: number;
  Disable: boolean;
  CreateTime: Date | string;
  UpdateTime: Date | string;
  CreateUserId: number;
  UpdateUserId: number;
  Version: number;
  DefaultFormOptions: string;
  Icon: string;
  RelatedTemplates: string[];
  CRUD: number;
  FieldEntities: any;
  DataSource: any;
  OwnerUserId: any;
  AdditionalEntities: any;
  GridOptions: any;
  ReportType: any;
}

export interface EntityField extends IHasId<string>, ITraceEntity {
  Id: string;
  WorkspaceId: string;
  CustomerId: number;
  EntityTypeId: string;
  Name: string;
  Name_OtherLan: { [key: string]: any };
  TableName: string;
  FieldName: string;
  BindFieldName: string;
  FieldType: number;
  DataType: string;
  IsRequired: boolean;
  CompositeKey: boolean;
  IsAutoValue: boolean;
  DataSourceType: number;
  DataSource: string;
  DefaultValue: string;
  EditorType: string;
  VisibleIndex: number;
  EditorOptions: { [key: string]: any };
  ViewOptions: { [key: string]: any };
  GridOptions: { [key: string]: any };
  QueryOptions: { [key: string]: any };
  SmartListOptions: { [key: string]: any };
  Validations: string;
  IsSystem: boolean;
  Description: string;
  Description_OtherLan: { [key: string]: any };
  InfoBubble: string;
  InfoBubble_OtherLan: { [key: string]: any };
  Status: number;
  Disable: boolean;
  CreateTime: Date | string;
  UpdateTime: Date | string;
  CreateUserId: number;
  UpdateUserId: number;
  Version: number;
  GridColumnsOptions: any;
  Format: string;
  FormItemOptions: any;
  ParentFieldId: any;
  Apply: any;
  TitleOptions: any;
}

export interface EntityTableReportFieldItem {
  EntityTable_TableName: string;
  EntityTableField_FieldName: string;
  EntityTableField_Name: string;
  EntityTableField_IsAutoValue: boolean;
  EntityTableField_EntityFieldId: string;
}

export interface SysDictionaryItem {
  DictionaryCategory: SysDictionaryCategory;

  Dictionarys: SysDictionary[];
}

export interface SysDictionaryCategory extends IHasId<string>, ITraceEntity {
  Id: string;
  WorkspaceId: string;
  CustomerId: number;
  Name: string;
  Code: string;
  Description: string;
  Disable: boolean;
  CreateTime: Date | string;
  UpdateTime: Date | string;
  CreateUserId: number;
  UpdateUserId: number;
  Version: number;
}

export interface SysDictionary extends IHasId<string>, ITraceEntity {
  Id: string;
  WorkspaceId: string;
  CustomerId: number;
  DictionaryCategoryId: string;
  Name: string;
  Name_OtherLan: MultiLanguageText;
  Code: string;
  Description: string;
  Description_OtherLan: MultiLanguageText;
  Disable: boolean;
  CreateTime: Date | string;
  UpdateTime: Date | string;
  CreateUserId: number;
  UpdateUserId: number;
  Version: number;
}

export interface UpdateEntityInfo {
  EntityCode: string;
  EntityId: string;
  EntityInfo: { [key: string]: any };
}

export enum DataType {
  String,
  Int,
  Boolean,
  Date,
  DateTime,
  Number,
  Text,
  Array = 9,
  Image,
  Time,
}

export enum EditorType {
  TextBox,
  NumberBox,
  DateTimeBox,
  TagBox,
  SelectBox,
  CheckBox,
  RadioBox,
  PictureBox,
  TextArea,
  DateBox,
}

export enum FieldType {
  Simple,
  Empty,
  Group,
  Tabbed,
  Button,
}

export enum DataSourceType {
  None,
  Array,
  Dictionary,
  Entity,
}
