import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpHandler } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToolService } from '../../shared/utils/tool.service';

declare var $: any;
@Injectable()
export class GatewayService {
  constructor(private http: HttpClient) {}

  public getClient(url = '', postData = {}, type = 'get', url2 = '') {
    const xApiKey = environment.XApiKey
      ? environment.XApiKey
      : 'tqHZHOJKRn6D8YkoGSVVS6uULHuETGOb7BEUDIf0';
    url =
      (environment.MsgGatewayBaseUrl
        ? environment.MsgGatewayBaseUrl
        : 'https://test-api-gateway.mcttechnology.com/') + url;
    const header = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
      'x-api-key': xApiKey,
    });
    if (url2) {
      url = url2;
    }
    if (type === 'get') {
      return this.http.get(url, {
        headers: header,
        params: postData,
      });
    } else {
      return this.http.post(url, postData, {
        headers: header,
      });
    }
  }
}
