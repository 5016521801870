import {
  ApplicationDetail_SummaryInfo,
  CCLanguageItem,
} from './application/ApplicationDetailSummaryInfo';
import {
  ApplicationDetail_IncomeInfo,
  AppContactIncome,
} from './application/ApplicationDetailIncomeInfo';
import {
  CreateOrUpdateEntityTableDataInfo,
  EntityData_Applicaiton,
} from './application/ApplicationDetailInfo';
import {
  ApplicationDetail_FamilyInfo,
  AppApplication,
  AppApplicationSubsidy,
  AppApplicationFieldData,
} from './application/ApplicationDetailFamilyInfo';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse } from '../carecloud/common.api';
export class Response<T> extends BaseResponse {
  Data: T;
}

export class MappingResponse<T> extends BaseResponse {
  MappingCategory: T;
  Mapping: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class ICreateMultiTableEntityRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class GetApplicationDetail_SummaryRequest
  extends CareWaitBaseRequest
  implements IReturn<GetApplicationDetail_SummaryResponse>
{
  ApplicationId: string;
  Really: boolean;
  createResponse() {
    return new GetApplicationDetail_SummaryResponse();
  }
  getTypeName() {
    return 'GetApplicationDetail_SummaryRequest';
  }
}

export class GetApplicationDetail_SummaryResponse extends Response<ApplicationDetail_SummaryInfo> {}

export class GetCCLanguageListRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<CCLanguageItem[]>>
{
  createResponse() {
    return new Response<CCLanguageItem[]>();
  }
  getTypeName() {
    return 'GetCCLanguageListRequest';
  }
}

export class GetUserPictureRequest
  extends CareWaitBaseRequest
  implements IReturn<UserPictureResponse>
{
  RequestUserId: number;
  createResponse() {
    return new UserPictureResponse();
  }
  getTypeName() {
    return 'GetUserPictureRequest';
  }
}

export class UserPictureResponse extends BaseResponse {
  public PictureUrl: string;
  public PictureFile: string;
  public RequestUserId: number;
}

export class GetApplicationDetail_FamilyRequest
  extends CareWaitBaseRequest
  implements IReturn<GetApplicationDetail_FamilyResponse>
{
  ApplicationId: string;
  createResponse() {
    return new GetApplicationDetail_FamilyResponse();
  }
  getTypeName() {
    return 'GetApplicationDetail_FamilyRequest';
  }
}

export class GetApplicationDetail_FamilyResponse extends Response<ApplicationDetail_FamilyInfo> {}

export class UpdateApplication_FamilyRequest
  extends CareWaitBaseRequest
  implements IReturn<UpdateApplication_FamilyResponse>
{
  Application: AppApplication;
  ApplicationSubsidy: AppApplicationSubsidy;
  ApplicationFieldData: AppApplicationFieldData;
  Incomes: AppContactIncome[];
  createResponse() {
    return new UpdateApplication_FamilyResponse();
  }
  getTypeName() {
    return 'UpdateApplication_FamilyRequest';
  }
}

export class UpdateApplication_FamilyResponse extends BaseResponse {
  ApplicationId: string;
  IsEligible: boolean;
  Status: number;
}

export class GetApplicationDetail_IncomeRequest
  extends CareWaitBaseRequest
  implements IReturn<GetApplicationDetail_IncomeResponse>
{
  ApplicationId: string;
  createResponse() {
    return new GetApplicationDetail_IncomeResponse();
  }
  getTypeName() {
    return 'GetApplicationDetail_IncomeRequest';
  }
}

export class GetApplicationDetail_IncomeResponse extends Response<ApplicationDetail_IncomeInfo> {}

//Entity Application

//[Route("/application/{Id}", "GET")]
export class EntityDataResponse extends Response<{ [key: string]: any }> {
  Id: string;
}

export class GetApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetApplicationRequest';
  }
}

//[Route("/application", "POST")]
export class PostApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostApplicationRequest';
  }
}

//[Route("/application", "PUT")]
export class PutApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutApplicationRequest';
  }
}

export class DeleteApplicationRequest extends CareWaitBaseRequest implements IReturn<BaseResponse> {
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteApplicationRequest';
  }
}

//[Route("/application/restore/{Id}", "PUT")]
export class RestoreApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreApplicationRequest';
  }
}

//[Route("/application/autoquery", "GET")]
export class GetApplicationListByAutoQueryRequest extends CareWaitBaseRequest {
  IncludeDisable: boolean;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'GetApplicationListByAutoQueryRequest';
  }
}

// [Route("/application/parent/{Id}", "GET")]
export class GetApplicationParentRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetApplicationParentRequest';
  }
}

// [Route("/application/parent", "POST")]
export class PostApplicationParentRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostApplicationParentRequest';
  }
}

// [Route("/application/parent/{Id}", "DELETE")]
export class DeleteApplicationParentRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteApplicationParentRequest';
  }
}

// [Route("/application/parent/restore/{Id}", "PUT")]
export class RestoreApplicationParentRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreApplicationParentRequest';
  }
}

// [Route("/application/parent", "PUT")]
export class PutApplicationParentRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutApplicationParentRequest';
  }
}

// [Route("/application/child/{Id}", "GET")]
export class GetApplicationChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetApplicationChildRequest';
  }
}

// [Route("/application/child", "POST")]
export class PostApplicationChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostApplicationChildRequest';
  }
}

// [Route("/application/child/{Id}", "DELETE")]
export class DeleteApplicationChildRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteApplicationChildRequest';
  }
}

// [Route("/application/child/restore/{Id}", "PUT")]
export class RestoreApplicationChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreApplicationChildRequest';
  }
}

// [Route("/application/child", "PUT")]
export class PutApplicationChildRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutApplicationChildRequest';
  }
}

//Provider Criteria
// [Route("/application/providercriteria/{Id}", "GET")]
export class GetApplicationProviderCriteriaRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetApplicationProviderCriteriaRequest';
  }
}

// [Route("/application/providercriteria", "POST")]
export class PostApplicationProviderCriteriaRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostApplicationProviderCriteriaRequest';
  }
}

// [Route("/application/providercriteria/{Id}", "DELETE")]
export class DeleteApplicationProviderCriteriaRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteApplicationProviderCriteriaRequest';
  }
}

// [Route("/application/providercriteria/restore/{Id}", "PUT")]
export class RestoreApplicationProviderCriteriaRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreApplicationProviderCriteriaRequest';
  }
}

// [Route("/application/providercriteria", "PUT")]
export class PutApplicationProviderCriteriaRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutApplicationProviderCriteriaRequest';
  }
}

// [Route("/application/summary/{Id}", "GET")]
export class GetApplicationSummaryRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetApplicationSummaryRequest';
  }
}

// [Route("/application/result/parentapply/{Id}", "GET")]
export class GetApplicationParentApplyResultRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: any;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetApplicationParentApplyResultRequest';
  }
}

// [Route("/application/applyfinish", "POST")]
export class ApplyFinishApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: string;
  Apply: number; //1: Parent Apply 2: Parent Edit
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'ApplyFinishApplicationRequest';
  }
}

export class CheckEntityDataResponse extends BaseResponse {}

// [Route("/application/check", "POST")]
export class CheckApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<CheckEntityDataResponse>
{
  Entitys: { [key: string]: any }[];
  createResponse() {
    return new CheckEntityDataResponse();
  }
  getTypeName() {
    return 'CheckApplicationRequest';
  }
}

// [Route("/application/parent/check", "POST")]
export class CheckApplicationParentRequest
  extends CareWaitBaseRequest
  implements IReturn<CheckEntityDataResponse>
{
  Entitys: { [key: string]: any }[];
  createResponse() {
    return new CheckEntityDataResponse();
  }
  getTypeName() {
    return 'CheckApplicationParentRequest';
  }
}

// [Route("/application/child/check", "POST")]
export class CheckApplicationChildRequest
  extends CareWaitBaseRequest
  implements IReturn<CheckEntityDataResponse>
{
  Entitys: { [key: string]: any }[];
  createResponse() {
    return new CheckEntityDataResponse();
  }
  getTypeName() {
    return 'CheckApplicationChildRequest';
  }
}

// [Route("/application/providercriteria/check", "POST")]
export class CheckApplicationProviderCriteriaRequest
  extends CareWaitBaseRequest
  implements IReturn<CheckEntityDataResponse>
{
  Entitys: { [key: string]: any }[];
  createResponse() {
    return new CheckEntityDataResponse();
  }
  getTypeName() {
    return 'CheckApplicationProviderCriteriaRequest';
  }
}

// [Route("/application/rank/{Id}", "GET")]
export class GetApplicationRankRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: string;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetApplicationRankRequest';
  }
}

// [Route("/application/rank", "POST")]
export class PostApplicationRankRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostApplicationRankRequest';
  }
}

// [Route("/application/rank", "PUT")]
export class PutApplicationRankRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entity: { [key: string]: any };
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PutApplicationRankRequest';
  }
}

// [Route("/application/rank/{Id}", "DELETE")]
export class DeleteApplicationRankRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: string;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteApplicationRankRequest';
  }
}

// [Route("/application/rank/restore/{Id}", "PUT")]
export class RestoreApplicationRankRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: string;
  ParentApply: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreApplicationRankRequest';
  }
}

// [Route("/thirdpart/getentity-bykeyid/{KeyId}", "GET")]
export class GetThirdPartData4EntityRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  KeyId: string;
  DataType: number; //1
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetThirdPartData4EntityRequest';
  }
}

// [Route("/setting/affiliation/query", "GET")]
export class GetAffiliationSettingRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetAffiliationSettingRequest';
  }
}

// [Route("/application/parent/changeparentab", "POST")]
export class SwichApplicationParentRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<boolean>>
{
  ApplicationId: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'SwichApplicationParentRequest';
  }
}

// [Route("/mapping/by-mappingcategorycode/{Code}", "GET")]
export class GetMappingByCodeRequest
  extends CareWaitBaseRequest
  implements IReturn<MappingResponse<any>>
{
  Code: string;
  IncludeDisable: boolean;
  createResponse() {
    return new MappingResponse<any>();
  }
  getTypeName() {
    return 'GetMappingByCodeRequest';
  }
}

// [Route("/application/nouploaddocument/{Id}", "GET")]
export class ApplicationNoUploadDocumentRequest
  extends CareWaitBaseRequest
  implements IReturn<ApplicationNoUploadDocumentResponse>
{
  Id: string;
  createResponse() {
    return new ApplicationNoUploadDocumentResponse();
  }
  getTypeName() {
    return 'ApplicationNoUploadDocumentRequest';
  }
}

export class ApplicationNoUploadDocumentResponse extends BaseResponse {
  NoUploadDocumentTypeCodes: string[];
}

// [Route("/application/status/getapplicationstatusworkflow", "GET")]
export class GetApplicationStatusWorkflowRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  ApplicationId: string;
  UserType: number; //WLO: 1   Parent: 2
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetApplicationStatusWorkflowRequest';
  }
}

// [Route("/application/changestatus/{Id}", "PUT")]
export class ChangeApplicationStatusRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: string;
  NewStatus: number;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'ChangeApplicationStatusRequest';
  }
}

// [Route("/application/status/getapplicantprovidertatusworkflow", "GET")]
export class GetApplicantProviderStatusWorkflowRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  ApplicantProviderId: string;
  UserType: number; //WLO: 1   Parent: 2
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetApplicantProviderStatusWorkflowRequest';
  }
}

// [Route("/application/status/getapplicationallapplicantprovidertatusworkflow", "GET")]
export class GetApplicationAllApplicantProviderStatusWorkflowRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  ApplicationId: string;
  UserType: number; //WLO: 1   Parent: 2
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetApplicationAllApplicantProviderStatusWorkflowRequest';
  }
}

// [Route("/application/child/provider/changestatus/{Id}", "PUT")]
export class ChangeApplicantProviderStatusRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  Id: string;
  NewStatus: number;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'ChangeApplicantProviderStatusRequest';
  }
}

// [Route("/application/provider/getcanselect", "GET")]
export class GetApplicationCanSelectProviderRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  ApplicationId: string;
  AffiliationType: string[];
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetApplicationCanSelectProviderRequest';
  }
}

// [Route("/application/child/provider/recalculate/{Id}", "GET")]
export class RecalculatePriorityRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<number>>
{
  Id: string;
  createResponse() {
    return new Response<number>();
  }
  getTypeName() {
    return 'RecalculatePriorityRequest';
  }
}

export class PutPaymentApplicantProviderOrderResponse extends BaseResponse {
  PaymentOrderId: string;
  StripeKey: string;
  PayApplicationProductId: string;
  PayApplicationPriceId: string;
  PayApplicationCurrency: string;
}

// [Route("/payment/applicantprovider/createorder", "POST")]
export class PostPaymentApplicantProviderOrderRequest
  extends CareWaitBaseRequest
  implements IReturn<PutPaymentApplicantProviderOrderResponse>
{
  ApplicantProviderId: string[];
  createResponse() {
    return new PutPaymentApplicantProviderOrderResponse();
  }
  getTypeName() {
    return 'PostPaymentApplicantProviderOrderRequest';
  }
}

// [Route("/payment/applicantprovider/finishorder", "PUT")]
export class FinishPaymentApplicantProviderOrderRequest
  extends CareWaitBaseRequest
  implements IReturn<BaseResponse>
{
  PaymentOrderId: string;
  PaymentBill: string;
  RequestWorkspaceId: string;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'FinishPaymentApplicantProviderOrderRequest';
  }
}

// [Route("/setting", "GET")]
export class GetWorkspaceSettingRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  SettingKey: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetWorkspaceSettingRequest';
  }
}

// [Route("/application/allchild", "GET")]
export class GetApplicationAllChildRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  ApplicationId: string;
  IncludeDisable: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetApplicationAllChildRequest';
  }
}

// [Route("/application/allparent", "GET")]
export class GetApplicationAllParentRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  ApplicationId: string;
  IncludeDisable: boolean;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetApplicationAllParentRequest';
  }
}

// [Route("/thirdpart/getusereemplid/{RequestUserId}", "GET")]
export class GetUserEMPLIDRequest
  extends CareWaitBaseRequest
  implements IReturn<GetUserEMPLIDResponse>
{
  RequestUserId: number;
  createResponse() {
    return new GetUserEMPLIDResponse();
  }
  getTypeName() {
    return 'GetUserEMPLIDRequest';
  }
}

export class GetUserEMPLIDResponse extends BaseResponse {
  EMPLID: string;
  AffiliationDataType: number; //1: Employee 2: Student
}

// [Route("/application/getinvitestatus", "GET")]
export class GetInviteStatusRequest extends CareWaitBaseRequest implements IReturn<Response<any>> {
  ApplicationId: string; //Return:      0: No Link&No Invite 1: No Link&Had Invite 2: Linked
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetInviteStatusRequest';
  }
}

// [Route("/application/inviteparent", "POST")]
export class InviteParentRequest extends CareWaitBaseRequest implements IReturn<BaseResponse> {
  ApplicationId: string;
  ReceiverType: number; //0: email 1:phone
  Receiver: string;
  ReceiverInternationalTelephoneCode: string;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'InviteParentRequest';
  }
}

// [Route("/application/unlink", "POST")]
export class UnlinkParentRequest extends CareWaitBaseRequest implements IReturn<BaseResponse> {
  ApplicationId: string;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'UnlinkParentRequest';
  }
}

// [Route("/application/child/provider/batch", "POST")]
export class PostChildProvidersRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Entities: { [key: string]: any }[];
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'PostChildProvidersRequest';
  }
}

//得到application状态
// [Route("/application/status/{Id}", "GET")]
export class GetApplicationStatusRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<ApplicationStatusItem>>
{
  Id: string;
  createResponse() {
    return new Response<ApplicationStatusItem>();
  }
  getTypeName() {
    return 'GetApplicationStatusRequest';
  }
}
export class ApplicationStatusItem {
  StatusId: number;
}

// [Route("/auth/user/haveofficerole")]
export class GetUserHaveOfficeRoleRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<boolean>>
{
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'GetUserHaveOfficeRoleRequest';
  }
}

// [Route("/application/stripproduct", "GET")]
export class GetApplicationStripProductRequest
  extends CareWaitBaseRequest
  implements IReturn<GetApplicationStripProductResponse>
{
  Id: string;
  createResponse() {
    return new GetApplicationStripProductResponse();
  }
  getTypeName() {
    return 'GetApplicationStripProductRequest';
  }
}

export class GetApplicationStripProductResponse extends BaseResponse {
  Ret: number; //1: no affliationtype   2: no strip product
  ProductId: string;
  PriceId: string;
  Price: number;
}
