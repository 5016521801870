import { RLChildItem } from './waitlist/childItem';
import { Query } from './waitlist/query';
import { StatusItem } from './waitlist/statusItem';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse } from '../carecloud/common.api';
import { QueryOption } from './query.api';

export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class AppChildrenResponse extends BaseResponse {
  Data: RLChildItem[];
  Option: QueryOption;
}

export class SearchChildrenRequest
  extends CareWaitBaseRequest
  implements IReturn<AppChildrenResponse>
{
  Option: QueryOption;
  createResponse() {
    return new AppChildrenResponse();
  }
  getTypeName() {
    return 'SearchChildrenRequest';
  }
}

export enum StatusType {
  Application = 0,
  ScreenQueue = 1,
  Applicant = 2,
}

export class GetStatusListRequest
  extends CareWaitBaseRequest
  implements IReturn<GetStatusListResponse>
{
  StatusType: StatusType;
  NoShowClosedEnrollmentQueue: boolean;
  ShowVoided: boolean;
  createResponse() {
    return new GetStatusListResponse();
  }
  getTypeName() {
    return 'GetStatusListRequest';
  }
}

export class GetStatusListResponse extends Response<StatusItem[]> {}

export class GetCommonQueryRequest
  extends CareWaitBaseRequest
  implements IReturn<GetCommonQueryResponse>
{
  SearchStr: string;
  QueryEntityType: number; //0: Child List 3:Application List
  createResponse() {
    return new GetCommonQueryResponse();
  }
  getTypeName() {
    return 'GetCommonQueryRequest';
  }
}

export class GetCommonQueryResponse extends BaseResponse {
  Data: Query;
}

export class GetRequestCustomerIdByCustomerIdRequest
  extends CareWaitBaseRequest
  implements IReturn<GetRequestCustomerIdByCustomerIdResponse>
{
  createResponse() {
    return new GetRequestCustomerIdByCustomerIdResponse();
  }
  getTypeName() {
    return 'GetRequestCustomerIdByCustomerIdRequest';
  }
}

export class GetRequestCustomerIdByCustomerIdResponse extends Response<Int16Array> {}
