import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonClientFactoryService } from '../../core/carecloud/JsonClientFactory.service';
import { NotifyService } from '../../shared/message/notify.service';
import { TranslationPipe } from '../../core/pipe/translationPipe';
import { AuthService } from '../../core/auth/auth.service';
import $ from 'jquery';
import moment from 'moment';
import { FormatNamePipe } from '../../core/pipe/formatName.pipe';
import { environment } from '../../../environments/environment';
import { GatewayService } from '../../core/carecloud/gateway.service';
import { ReportService } from '../../core/services/reportService';
import { ToolService } from '../../shared/utils/tool.service';
import { GetS3FileUrlRequest, GetStatsReportFileRequest } from '../../core/models/report.api';
import { JsonServiceClient } from '@servicestack/client';

@Component({
  selector: 'app-pdf-report',
  templateUrl: 'component.html',
  styleUrls: ['component.scss'],
})
export class PDFReportComponent implements OnInit, OnChanges {
  client: JsonServiceClient;
  zoom_to: number;
  currentInnerWidth: number;
  @Input() pdfUrlData;
  pdfFullPath: any;
  isPdfLoaded: any;
  othSignClient: any;
  pageVariable = 1;
  profileSettings = [{ value: 1, name: 'PDF', icon: 'floppy' }];
  totalPages: any;
  pageItems: any[];
  fileList: any;
  pdfHeight = 500;
  queryFailed = false;

  constructor(
    private factory: JsonClientFactoryService,
    private message: NotifyService,
    private route: ActivatedRoute,
    private gatewayService: GatewayService,
    private router: Router,
    private translater: TranslationPipe,
    private reportService: ReportService,
    private auth: AuthService,
  ) {
    this.client = factory.MctCareWaitClient;
    this.zoom_to = 0.5;
    this.currentInnerWidth = this.getWindow();

    this.callBackFn = this.callBackFn.bind(this);
    const vm = this;
    reportService.reportPdfUrl.subscribe(x => {
      if (x.start && x.start == 'startLoding') {
        this.pdfFullPath = null;
        this.isPdfLoaded = false;
      } else {
        if (x) {
          this.pdfFullPath = x.FileUrl;
          this.isPdfLoaded = true;
          this.pageVariable = 1;
          vm.setPdfViewerHeight();
        }
      }
    });
    this.download = this.download.bind(this);
    this.downloadDefault = this.downloadDefault.bind(this);
    window.onresize = () => {
      vm.setPdfViewerHeight();
    };
  }

  scrollBottom() {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  }

  setPdfViewerHeight() {
    const h = window.innerHeight;
    this.pdfHeight = h - 175;
  }

  getFileIcon(type) {
    let t = 'floppy';
    if (type) {
      type = type.toLowerCase();
      if (type.indexOf('pdf') > -1) {
        t = 'fas fa-file-pdf';
      } else if (type.indexOf('xls') > -1) {
        t = 'fas fa-file-excel';
      } else if (type.indexOf('doc') > -1) {
        t = 'fas fa-file-word';
      }
    }
    return t;
  }

  setDownLoadList(list) {
    const vm = this;
    vm.profileSettings = [];
    if (list && list.length > 0) {
      list.forEach(pdf => {
        const t = vm.getFileIcon(pdf['FileType']);
        const p = { value: pdf['FileS3Path'], name: pdf['FileType'].toUpperCase(), icon: t };
        vm.profileSettings.push(p);
      });
      vm.profileSettings = vm.profileSettings.sort(ToolService.fieldSorter(['name']));
    }
  }

  getWindow() {
    return window.innerWidth;
  }

  zoom_in() {
    this.zoom_to = this.zoom_to + 0.25;
  }

  zoom_out() {
    if (this.zoom_to > 0.5) {
      this.zoom_to = this.zoom_to - 0.25;
    }
  }

  ngOnInit() {}

  downloadDefault(e) {
    const vm = this;
    this.setFilePath();
  }

  download(e) {
    const f = e.itemData;
    const vm = this;
    this.setFilePath();
  }

  async setFilePath() {
    const postData = new GetS3FileUrlRequest();
    postData.S3FilePath = this.pdfUrlData.FilePath;
    const response = await this.client.get(postData);
    if (response.IsSuccess) {
      if (response.Data) {
        window.open(response.Data, '_blank');
      }
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {}

  nextPage() {
    this.pageVariable += 1;
  }

  previousPage() {
    this.pageVariable -= 1;
  }

  selectPage(page) {
    this.pageVariable = page;
  }

  callBackFn() {
    console.log('====callbackfn');
    // this.isPdfLoaded = true;
    this.scrollBottom();
    this.reportService.setReportPdfLoaded(1);
  }

  refreshFdf() {
    this.reportService.setgetRefreshPdf(true);
  }

  onPdfError() {
    this.reportService.setReportPdfLoaded(2);
  }

  ngOnChanges(changes: SimpleChanges) {
    // for (const propName in changes) {
    //     if (propName == 'pdfUrlData') {
    //         if (this.pdfUrlData) {
    //             debugger;
    //             this.pdfFullPath = null;
    //             this.isPdfLoaded = false;
    //             this.pdfFullPath = this.pdfUrlData.FileUrl;
    //             this.pageVariable = 1;
    //             // this.isPdfLoaded = true;
    //             this.setPdfViewerHeight();
    //             this.setDownLoadList(this.fileList);
    //         }
    //     }
    // }
  }
}
