import { EntityType, EntityField } from './datamodel/entityType';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../carecloud/common.api';

import { SysMapping, SysMappingCategory } from './mapping.model';
import { EntityDataResponse } from './dictionary.api';
export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

// 批量发消息[Route("/reminder/batchsendapplication", "POST")]
export class BatchSendMessage2ApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  ApplicationIds: string[];
  Entity: { [key: string]: any };
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'BatchSendMessage2ApplicationRequest';
  }
}

// 获取一个批次信息
// [Route("/reminder/batchsendapplication/{Id}", "GET")]
export class GetBatchSendApplicationRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: string;
  ActionUserType: number; //0:parent 1: agency
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'GetBatchSendApplicationRequest';
  }
}

// // 获取批量列表 cw_msg_batch
// // [Route("/reminder/batchsendapplication/autoquery/and", "GET")]
// export class GetBatchSendApplicationListByAutoQueryRequest extends QueryDb<BatchMessageListV2>
// {
//     IncludeDisable: boolean;
// }

// // 获取一个批次的明细列表 cw_msg_batchdetail
// // [Route("/reminder/batchsendapplication/message/autoquery/and", "GET")]
// export class GetBatchSendApplicationMessageListByAutoQueryRequest extends QueryDb<BatchMessageDetailListV2>
// {
//     IncludeDisable: boolean;
// }
