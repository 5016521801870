import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { count } from 'rxjs/operators';
import { JsonClientFactoryService } from '../../core/carecloud/JsonClientFactory.service';
import { TranslationPipe } from '../../core/pipe/translationPipe';

@Component({
  selector: 'app-validate-password',
  templateUrl: './validatePassword.component.html',
  styleUrls: ['./validatePassword.component.scss'],
})
export class ValidatePasswordComponent implements OnInit, OnChanges {
  @Input() passwordValue;
  checkPassword: any = {
    value: undefined,
    rules: {
      hasNumber: false,
      hasLetter: false,
      hasUpperLetter: false,
      hasSymbal: false,
      isValidLength: false,
      isOverLength: false,
    },
    isValid: true,
  };
  constructor(
    private clientFactory: JsonClientFactoryService,
    private translater: TranslationPipe,
  ) {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.passwordValue) {
      this.checkPassword.isValid = true;
      this.checkPassword.value = changes.passwordValue.currentValue;
      if (this.checkPassword.value) {
        this.checkPassword.rules ? this.checkPassword.rules : {};
        this.checkPassword.rules.hasNumber = this.checkPassword.value.match(/\d/) ? true : false;
        this.checkPassword.rules.hasLetter = this.checkPassword.value.match(/[a-z]/) ? true : false;
        this.checkPassword.rules.hasUpperLetter = this.checkPassword.value.match(/[A-Z]/)
          ? true
          : false;
        // password.rules.hasSymbal = password.value.match(/[!@#$ %^&*()_+-=.<>,]/) ? true : false;
        this.checkPassword.rules.hasSymbal = this.checkPassword.value.match(/[^\w]/) ? true : false;

        this.checkPassword.rules.isValidLength = this.checkPassword.value.match(/^.{8,}$/)
          ? true
          : false;
        this.checkPassword.rules.isOverLength =
          this.checkPassword.value && this.checkPassword.value.length <= 16 ? true : false;
        for (const key in this.checkPassword.rules) {
          if (this.checkPassword.rules.hasOwnProperty(key)) {
            const element = this.checkPassword.rules[key];
            if (!element) {
              this.checkPassword.isValid = false;
              break;
            }
          }
        }
      } else {
        for (const key in this.checkPassword.rules) {
          if (this.checkPassword.rules.hasOwnProperty(key)) {
            this.checkPassword.rules[key] = false;
          }
        }
      }
      this.clientFactory.validatePassword.emit({ isValid: this.checkPassword.isValid });
    }
  }
}
