import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChildren } from '@angular/core';
import { FocusService } from '@app/core/services/focusService';
import { DxValidatorComponent } from 'devextreme-angular';
import _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-date-template',
  templateUrl: 'component.html',
  styleUrls: ['component.scss'],
})
export class DateTemplateComponent  implements OnInit,OnChanges{
  @ViewChildren(DxValidatorComponent) validator: DxValidatorComponent
  
  @Input() formData:any;
  @Input() type:any;
  @Input() item:any;
  @Input() readOnly:any;
  @Input() validationGroup:any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  monthDataSource = [];
  yearDataSource = [];
  dayDataSource = [];
  dayDefaultDataSource = [];

  constructor(private focusService: FocusService) {
    this.validateDateByMonth = this.validateDateByMonth.bind(this);
    this.validateDateByDay = this.validateDateByDay.bind(this);
    this.validateDateByYear = this.validateDateByYear.bind(this);
    this.getDateDataSource();
  }

  ngOnInit() { 

  }
  getDateDataSource(){

    this.monthDataSource = [];
    this.yearDataSource = [];
    this.dayDataSource = [];
    this.dayDefaultDataSource = [];

    this.getDayDataSource();
    this.getYearMonthDataSource() 
  }

  getYearMonthDataSource(){ 

    this.monthDataSource = [{
      Code: "", Name: ""
    },{
      Code: '01', Name: 'Jan'
    },{
      Code: '02', Name: 'Feb'
    },{
      Code: '03', Name: 'Mar'
    },{
      Code: '04', Name: 'Apr'
    },{
      Code: '05', Name: 'May'
    },{
      Code: '06', Name: 'Jun'
    },{
      Code: '07', Name: 'Jul'
    },{
      Code: '08', Name: 'Aug'
    },{
      Code: '09', Name: 'Sept'
    },{
      Code: '10', Name: 'Oct'
    },{
      Code: '11', Name: 'Nov'
    },{
      Code: '12', Name: 'Dec'
    }]

    var startYear = 1900;
    var currentyear = moment().year();
    var endYear = Number(currentyear)+5;

    for (var year = startYear; year <= endYear; year++) {
      this.yearDataSource.push({
         Code: year, Name:year
      });

      this.yearDataSource = this.yearDataSource.sort((a, b) => (b.Code < a.Code ? -1 : 1));
    }
    this.yearDataSource = [{ Code: "", Name: ""}].concat(this.yearDataSource);

  }

  getDayDataSource(){
    for (var i=1; i <= 31; i++) {
       let day = i+'';
       if(i<10){
        day = '0'+i;
       } 
      this.dayDefaultDataSource.push({
         Code: day, Name:i
      }); 
    } 

    this.dayDefaultDataSource = [{ Code: "", Name: ""}].concat(this.dayDefaultDataSource);

    this.dayDataSource = this.dayDefaultDataSource;
  }

  dateClick(e, type,dataField, isClick) {
    let returnValue = '';
    if(isClick){
      const month = this.formData[dataField+'___month'];
      const year = this.formData[dataField+'___year'];
      let day = this.formData[dataField+'___day'];
      if(year && month){
        var daysInMonth = moment(year + '-' + month, 'YYYY-MM').daysInMonth();
        if(Number(day)>daysInMonth){
          this.formData[dataField+'___day'] = daysInMonth.toString();
          day = daysInMonth;
        }
        this.dayDataSource = this.dayDefaultDataSource.filter(f=>f.Code<=daysInMonth);
      }else{
        this.dayDataSource = _.cloneDeep(this.dayDefaultDataSource);
      }
      if(year && month && day){
        returnValue = year.toString()+month+day;
      }

      this.valueChange.emit({dataField:dataField,data:returnValue});
    }
    
  }

  validateDateByMonth(params) {
     const isValid = this.validateDate(params,'month');
     return  isValid;
  }

  validateDateByDay(params) {
    const isValid = this.validateDate(params,'day');
    return  isValid;
  }

  validateDateByYear(params) {
    const isValid = this.validateDate(params,'year');
    return  isValid;
  }

  validateDate(params,type){
    if (!params.value) { 
      let isValid = true;
      let errMsg = ''; 

      const year = this.formData[this.item.dataField+'___year'];
      const month = this.formData[this.item.dataField+'___month'];
      const day = this.formData[this.item.dataField+'___day'];
      
      if(type=='year'){
        if(month || day){
          isValid = false;
        }
      }else if(type=='month'){
        if(year || day){
          isValid = false;
        }
      }else if(type=='day'){
        if(month || day){
          isValid = false;
        }
      }
      params.rule.message = errMsg;
      params.rule.isValid = isValid;
      params.validator.validate();
      return isValid;
  } else {
      return true;
  }
  }

  setInintData(){
    const currentData = this.formData[this.item.dataField];
    if(currentData){
       const year = currentData.length>0?currentData.substring(0, 4):''; 
       const month = currentData.length>4?currentData.substring(4, 6):'';
       const day = currentData.length>6?currentData.substring(6, 8):''; 
       _.set(this.formData,this.item.dataField+'___year',Number(year));
       _.set(this.formData,this.item.dataField+'___month',month);
       _.set(this.formData,this.item.dataField+'___day',day);
    }
   console.log(this.formData);
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    let log: string[] = [];
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      const changedProp = changes[propName];
      if (propName === 'formData') {
        if(this.formData){
         this.setInintData();
        }
      }
    }
  }


}
