<!-- START Sidebar (left)-->
<div class="aside-inner">
  <nav
    class="sidebar"
    sidebar-anyclick-close=""
    [class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')"
  >
    <!-- START sidebar nav-->
    <ul class="sidebar-nav mct-menu-left">
      <li *ngFor="let item of menuItems">
        <div
          *ngxPermissionsOnly="
            item.permissions || item.roles;
            else noPermissionBolck;
            then: menuBlock
          "
        ></div>
        <ng-template #menuBlock>
          <!-- menu heading -->
          <!-- <span *ngIf="item.submenu">{{(item.translate | translate) || item.text}}</span> -->
          <!-- external links -->
          <a
            href="#"
            mat-ripple
            *ngIf="!item.submenu && !item.link"
            title="{{ item.translate | translate }}"
          >
            <span
              class="float-right"
              *ngIf="item.alert"
              [ngClass]="item.label || 'badge badge-success'"
              >{{ item.alert }}</span
            >
            <em class="{{ item.icon }}" *ngIf="item.icon">
              <i class="{{ item.icon }}" *ngIf="item.icon === 'iconfont'">&#xe6e7;</i>
            </em>
            <span
              class="d-inline-block text-truncate"
              title="{{ (item.translate | translate) || item.text }}"
              >{{ (item.translate | translate) || item.text }}</span
            >
          </a>
          <!-- single menu item -->
          <!-- (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" -->
          <a
            href
            mat-ripple
            *ngIf="!item.submenu && item.link"
            [routerLink]="item.link"
            [queryParams]="{ QueryId: item.queryId }"
            [attr.route]="item.link"
            title="{{ item.translate | translate }}"
            [routerLinkActive]="['active']"
          >
            <span
              class="float-right"
              *ngIf="item.alert"
              [ngClass]="item.label || 'badge badge-success'"
              >{{ item.alert }}</span
            >
            <em class="{{ item.icon }}" *ngIf="item.icon"></em>
            <span
              class="d-inline-block text-truncate"
              title="{{ (item.translate | translate) || item.text }}"
              >{{ (item.translate | translate) || item.text }}</span
            >
          </a>
          <!-- has submenu -->
          <!-- (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" -->
          <a
            href
            mat-ripple
            *ngIf="item.submenu"
            title="{{ item.translate | translate }}"
            [ngClass]="{ 'nav-heading': item.submenu }"
          >
            <span
              class="float-right"
              *ngIf="item.alert"
              [ngClass]="item.label || 'badge badge-success'"
              >{{ item.alert }}</span
            >
            <em class="{{ item.icon }}" *ngIf="item.icon"></em>
            <span
              class="d-inline-block text-truncate"
              title="{{ (item.translate | translate) || item.text }}"
              >{{ (item.translate | translate) || item.text }}</span
            >
          </a>
          <!-- SUBLEVEL -->
          <!-- [routerLinkActive]="['opening']" -->
          <ul
            *ngIf="item.submenu"
            class="sidebar-nav sidebar-subnav"
            [ngClass]="{ opening: item.submenu }"
          >
            <li class="sidebar-subnav-header">{{ (item.translate | translate) || item.text }}</li>
            <li
              *ngFor="let subitem of item.submenu"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <div
                *ngxPermissionsOnly="
                  subitem.permissions || subitem.roles;
                  else noPermissionBolck;
                  then: submenuBlock
                "
              ></div>
              <ng-template #submenuBlock>
                <!-- sublevel: external links -->
                <a
                  href="#"
                  mat-ripple
                  *ngIf="!subitem.submenu && !subitem.link"
                  title="{{ subitem.translate | translate }}"
                >
                  <span
                    class="float-right"
                    *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'badge badge-success'"
                    >{{ subitem.alert }}</span
                  >
                  <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
                  <span
                    class="d-inline-block text-truncate"
                    title="{{ (subitem.translate | translate) || subitem.text }}"
                    >{{ (subitem.translate | translate) || subitem.text }}</span
                  >
                </a>
                <!-- sublevel: single menu item  -->
                <a
                  href
                  mat-ripple
                  *ngIf="!subitem.submenu && subitem.link"
                  [ngClass]="{ active: isActive(subitem) }"
                  [routerLink]="subitem.link"
                  [queryParams]="{ QueryId: subitem.queryId }"
                  [attr.route]="subitem.link"
                  title="{{ subitem.translate | translate }}"
                >
                  <span
                    class="float-right"
                    *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'badge badge-success'"
                    >{{ subitem.alert }}</span
                  >
                  <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
                  <span
                    class="d-inline-block text-truncate"
                    title="{{ (subitem.translate | translate) || subitem.text }}"
                    >{{ (subitem.translate | translate) || subitem.text }}</span
                  >
                </a>
                <!-- sublevel: has submenu -->
                <!-- (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" -->
                <a
                  href
                  mat-ripple
                  *ngIf="subitem.submenu"
                  title="{{ subitem.translate | translate }}"
                >
                  <span
                    class="float-right"
                    *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'badge badge-success'"
                    >{{ subitem.alert }}</span
                  >
                  <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
                  <span
                    class="d-inline-block text-truncate"
                    title="{{ (subitem.translate | translate) || subitem.text }}"
                    >{{ (subitem.translate | translate) || subitem.text }}</span
                  >
                </a>
                <!-- SUBLEVEL 2 -->
                <ul
                  *ngIf="subitem.submenu"
                  class="sidebar-nav sidebar-subnav level2"
                  [routerLinkActive]="['opening']"
                >
                  <li *ngFor="let subitem2 of subitem.submenu" [routerLinkActive]="['active']">
                    <div
                      *ngxPermissionsOnly="
                        subitem2.permissions || subitem2.roles;
                        else noPermissionBolck;
                        then: submenu2Block
                      "
                    ></div>
                    <ng-template #submenu2Block>
                      <!-- sublevel 2: single menu item  -->
                      <a
                        href
                        mat-ripple
                        *ngIf="!subitem2.submenu"
                        [routerLink]="subitem2.link"
                        [queryParams]="{ QueryId: subitem2.queryId }"
                        [attr.route]="subitem2.link"
                        title="{{ subitem2.translate | translate }}"
                      >
                        <span
                          class="float-right"
                          *ngIf="subitem2.alert"
                          [ngClass]="subitem2.label || 'badge badge-success'"
                          >{{ subitem2.alert }}</span
                        >
                        <em class="{{ subitem2.icon }}" *ngIf="subitem2.icon"></em>
                        <span
                          class="d-inline-block text-truncate"
                          title="{{ (subitem.translate | translate) || subitem.text }}"
                          >{{ (subitem2.translate | translate) || subitem2.text }}</span
                        >
                      </a>
                      <!-- sublevel2: has submenu -->
                      <!-- (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" -->
                      <a
                        href
                        mat-ripple
                        *ngIf="subitem2.submenu"
                        title="{{ subitem2.translate | translate }}"
                      >
                        <span
                          class="float-right"
                          *ngIf="subitem2.alert"
                          [ngClass]="subitem2.label || 'badge badge-success'"
                          >{{ subitem2.alert }}</span
                        >
                        <em class="{{ subitem2.icon }}" *ngIf="subitem2.icon"></em>
                        <span
                          class="d-inline-block text-truncate"
                          title="{{ (subitem.translate | translate) || subitem.text }}"
                          >{{ (subitem2.translate | translate) || subitem2.text }}</span
                        >
                      </a>
                      <!-- SUBLEVEL 3 -->
                      <ul
                        *ngIf="subitem2.submenu"
                        class="sidebar-nav sidebar-subnav level3"
                        [routerLinkActive]="['opening']"
                      >
                        <li
                          *ngFor="let subitem3 of subitem2.submenu"
                          [routerLinkActive]="['active']"
                        >
                          <div
                            *ngxPermissionsOnly="
                              subitem3.permissions || subitem3.roles;
                              else noPermissionBolck;
                              then: submenu3Block
                            "
                          ></div>
                          <ng-template #submenu3Block>
                            <!-- sublevel 2: single menu item  -->
                            <a
                              href
                              mat-ripple
                              *ngIf="!subitem3.submenu"
                              [routerLink]="subitem3.link"
                              [queryParams]="{ QueryId: subitem3.queryId }"
                              [attr.route]="subitem3.link"
                              title="{{ subitem3.translate | translate }}"
                            >
                              <span
                                class="float-right"
                                *ngIf="subitem3.alert"
                                [ngClass]="subitem3.label || 'badge badge-success'"
                                >{{ subitem3.alert }}</span
                              >
                              <em class="{{ subitem3.icon }}" *ngIf="subitem3.icon"></em>
                              <span
                                class="d-inline-block text-truncate"
                                title="{{ (subitem.translate | translate) || subitem.text }}"
                                >{{ (subitem3.translate | translate) || subitem3.text }}</span
                              >
                            </a>
                            <!-- sublevel3: has submenu -->
                            <!-- (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" -->
                            <a
                              href
                              mat-ripple
                              *ngIf="subitem3.submenu"
                              title="{{ subitem3.translate | translate }}"
                            >
                              <span
                                class="float-right"
                                *ngIf="subitem3.alert"
                                [ngClass]="subitem3.label || 'badge badge-success'"
                                >{{ subitem3.alert }}</span
                              >
                              <em class="{{ subitem3.icon }}" *ngIf="subitem3.icon"></em>
                              <span
                                class="d-inline-block text-truncate"
                                title="{{ (subitem.translate | translate) || subitem.text }}"
                                >{{ (subitem3.translate | translate) || subitem3.text }}</span
                              >
                            </a>
                            <!-- SUBLEVEL 4 -->
                            <ul
                              *ngIf="subitem3.submenu"
                              class="sidebar-nav sidebar-subnav level3"
                              [routerLinkActive]="['opening']"
                            >
                              <li
                                *ngFor="let subitem4 of subitem3.submenu"
                                [routerLinkActive]="['active']"
                              >
                                <div
                                  *ngxPermissionsOnly="
                                    subitem4.permissions || subitem4.roles;
                                    else noPermissionBolck;
                                    then: submenu4Block
                                  "
                                ></div>
                                <ng-template #submenu4Block>
                                  <!-- sublevel 2: single menu item  -->
                                  <a
                                    href
                                    mat-ripple
                                    *ngIf="!subitem4.submenu"
                                    [routerLink]="subitem4.link"
                                    [queryParams]="{ QueryId: subitem4.queryId }"
                                    [attr.route]="subitem4.link"
                                    title="{{ subitem4.translate | translate }}"
                                  >
                                    <span
                                      class="float-right"
                                      *ngIf="subitem4.alert"
                                      [ngClass]="subitem4.label || 'badge badge-success'"
                                      >{{ subitem4.alert }}</span
                                    >
                                    <em class="{{ subitem4.icon }}" *ngIf="subitem4.icon"></em>
                                    <span
                                      class="d-inline-block text-truncate"
                                      title="{{ (subitem.translate | translate) || subitem.text }}"
                                      >{{ (subitem4.translate | translate) || subitem4.text }}</span
                                    >
                                  </a>
                                </ng-template>
                              </li>
                            </ul>
                          </ng-template>
                        </li>
                      </ul>
                    </ng-template>
                  </li>

                  <li></li>
                </ul>
              </ng-template>
            </li>

            <li></li>
          </ul>
        </ng-template>
        <ng-template #noPermissionBolck></ng-template>
      </li>
    </ul>
    <!-- END sidebar nav-->
  </nav>
</div>
<!-- END Sidebar (left)-->
