import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../carecloud/common.api';
import { IReturn } from '@servicestack/client';
export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export interface LogItem {
  LogId: string;
  /**LogSource +"#"+ ActionDate(yyyyMMdd)+"#"+LogTime(yyyyMMddHHmmss) */
  LogRange: string;
  /**/ //  */
  ActionCategory: string;
  /**/ //  */
  //ActionDate: Date | string;
  /**/ //  */
  LogContent: string;
  /**/ //  */
  //LogTime: Date | string;
  /**/ //  */
  LogUser: string;
}

// Route("/log/entity/query", "POST")]
export class GetEntityLogListRequest
  extends CareWaitBaseRequest
  implements IReturn<GetEntityLogListResponse>
{
  Entity: string;
  EntityId: string;
  Source: string;
  LogStartDate: string;
  LogEndDate: string;
  StartIndex: string;
  PageSize: number;
  createResponse() {
    return new GetEntityLogListResponse();
  }
  getTypeName() {
    return 'GetEntityLogListRequest';
  }
}

export class GetEntityLogListResponse extends Response<LogItem[]> {
  LastIndex: string;
}
