import { EntityType, EntityField } from './datamodel/entityType';
import { IReturn } from '@servicestack/client';
import { BaseRequest, BaseResponse, BaseCareWaitRequest } from '../carecloud/common.api';

export class Response<T> extends BaseResponse {
  Data: T;
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

export class GetAllDictionaryCategoryRequest extends BaseRequest implements IReturn<Response<any>> {
  IncludeDisable: boolean;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetAllDictionaryCategoryRequest';
  }
}

export class GetDictionaryByIdRequest extends BaseCareWaitRequest implements IReturn<any> {
  Id: string;
  createResponse() {
    return {} as any;
  }
  getTypeName() {
    return 'GetDictionaryByIdRequest';
  }
}

export class PostDictionaryRequest extends BaseCareWaitRequest implements IReturn<any> {
  DictionaryCategory: any;
  Dictionary: any[];
  createResponse() {
    return {} as any;
  }
  getTypeName() {
    return 'PostDictionaryRequest';
  }
}

export class PutDictionaryRequest extends BaseCareWaitRequest implements IReturn<any> {
  Id: string;
  DictionaryCategory: any;
  Dictionary: any[];
  createResponse() {
    return {} as any;
  }
  getTypeName() {
    return 'PutDictionaryRequest';
  }
}

export class DeleteDictionaryRequest extends BaseCareWaitRequest implements IReturn<BaseResponse> {
  Id: string;
  createResponse() {
    return new BaseResponse();
  }
  getTypeName() {
    return 'DeleteDictionaryRequest';
  }
}

export class GetDictionaryByCodeRequest extends BaseCareWaitRequest implements IReturn<any> {
  Code: string;
  IncludeDisable: boolean;

  EntityCode?: string;
  EntityId?: string;
  ParentEntityDataId?: string;
  createResponse() {
    return {} as any;
  }
  getTypeName() {
    return 'GetDictionaryByCodeRequest';
  }
}

export class EntityDataResponse extends Response<{ [key: string]: any }> {
  Id: string;
}

//[Route("/dictionary/restore/{Id}", "PUT")]
export class RestoreDictionaryRequest
  extends CareWaitBaseRequest
  implements IReturn<EntityDataResponse>
{
  Id: string;
  createResponse() {
    return new EntityDataResponse();
  }
  getTypeName() {
    return 'RestoreDictionaryRequest';
  }
}
