import { Injectable } from '@angular/core';
import { JsonClientFactoryService } from '../carecloud/JsonClientFactory.service';
import {
  GetEntityTypeByCodeRequest,
  GetUsedParentEntityTypeByEntityTypeCodeRequest,
} from '../models/entity.api';
import { GetDictionaryByCodeRequest } from '../models/dictionary.api';
import {
  EntityType,
  EntityField,
  DataType,
  EditorType,
  FieldType,
  DataSourceType,
} from '../models/datamodel/entityType';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
// import { GetContractByCodeRequest, GetContractByIdRequest } from '../models/servicemodel/contract.api';
import { LogService } from '../log/log.service';
import { ConvertService } from '../../shared/utils/convert.service';

export class WksEntity {
  Title: string;
  EntityType: EntityType;
  EntityFields: EntityField[];
  Fields: EntityField[];
  FieldMap: { [name: string]: EntityField };
  Columns?: any[];
  FormItems?: any[];
  FormOptions?: any;
  TitleOptions?: any;
}

export class WksContract {
  ServiceEntityType: WksEntity;
  Id: number;
  Code: string;
  Name: string;
  Meta: any;
  public get EntityCode() {
    return 'ct.service.' + this.Code;
  }
}

@Injectable()
export class ParentEntityService {
  private entityMap: { [name: string]: WksEntity } = {};
  private dictMap: { [name: string]: any } = {};
  private contractMap: { [name: string]: WksContract } = {};

  constructor(
    private factory: JsonClientFactoryService,
    private convert: ConvertService,
    private logger: LogService,
  ) {}

  public async getEntity(entityCode: string, refresh = false) {
    let entity = this.entityMap[entityCode];
    if (!entity || refresh) {
      entity = await this.getRemoteEntityType(entityCode);
      if (entity) {
        entity.Columns = this.getDataGridColumns(
          entity.EntityFields.filter(f => f.Disable == false),
        );
        entity.FormOptions = entity.EntityType.DefaultFormOptions
          ? entity.EntityType.DefaultFormOptions
          : { labelLocation: 'top', colCount: 4 };
        // entity.FormItems = this.getDxFormItems(entity.EntityFields.filter(f => f.ParentFieldId == 0), entity);
        entity.FormItems = this.getDxFormItems(
          entity.EntityFields.filter(f => f.Disable == false),
          entity,
        );
        entity.TitleOptions = this.getTitleItems(
          entity.EntityFields.filter(f => f.Disable == false && f.TitleOptions),
          entity,
        );
        // this.logger.debug(entity);
      } else {
        // const contact = await this.getContract(entityCode);
        // if (contact) {
        //     entity = contact.ServiceEntityType;
        // } else {
        //     entity = new WksEntity();
        // }
        entity = new WksEntity();
      }
      this.entityMap[entityCode] = entity;
    }
    return entity;
  }

  public async getContract(code, refresh = false) {
    let contractCode = code;
    if (!code.startsWith('ct.service')) {
      contractCode = 'ct.service.' + code;
    }
    let contract = this.contractMap[contractCode];
    if (!contract || refresh) {
      let con = null;
      if (isNaN(Number(code))) {
        con = await this.getRemoteContractByCode(code);
      } else {
        con = await this.getRemoteContractById(code);
      }
      if (!con) {
        throw new Error(contractCode + ' is not correct contract code or id');
      }
      contract = new WksContract();
      contract.Id = con.Id;
      contract.Code = con.Code;
      contract.Name = con.Name;
      contract.Meta = {};
      if (con.Meta && con.Meta.length > 0) {
        const metas = JSON.parse(con.Meta);
        for (const item of metas) {
          contract.Meta[item.Key] = item.Value;
        }
      }
      contract.ServiceEntityType = await this.getEntity(contract.EntityCode);
      this.contractMap[contract.EntityCode] = contract;
      this.contractMap['ct.service.' + contract.Id] = contract;
    }
    return contract;
  }

  public async getDict(code: string, editorType: string = '') {
    let dict = this.dictMap[code];
    if (!dict) {
      if (code === 'ct.sys.organization') {
        dict = await this.getOrganizations();
      } else {
        dict = await this.searchDictDataByCode(code);
      }
      this.dictMap[code] = dict;
    }

    let dictionary = [];
    let isHasEmpty = false;
    for (const d in dict) {
      if (dict[d].Code == '') {
        isHasEmpty = true;
        break;
      }
    }
    if (!isHasEmpty && EditorType[editorType] == EditorType.SelectBox) {
      dictionary = [{ Code: '', Name: '', Order: 0 }].concat(dict);
    } else {
      dictionary = dict;
    }

    return dictionary;
  }

  public async getColumns(entityCode: string) {
    const entity = await this.getEntity(entityCode);
    if (entity) {
      return entity.Columns;
    }
    return null;
  }

  public async getFormItems(entityCode: string) {
    const entity = await this.getEntity(entityCode);
    if (entity) {
      return entity.FormItems;
    }
    return null;
  }

  private async getRemoteEntityType(entityCode) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetEntityTypeByCodeRequest();
    postData.EntityTypeCode = entityCode;
    const response = await client.get(postData);
    if (response.IsSuccess) {
      const wkentity = new WksEntity();
      wkentity.EntityType = response.EntityType;
      wkentity.EntityFields = response.Fields;
      wkentity.Fields = wkentity.EntityFields.filter(f => f.BindFieldName && f.Disable == false);
      wkentity.FieldMap = wkentity.Fields.reduce((map, f) => ((map[f.BindFieldName] = f), map), {});
      return wkentity;
    } else {
      return null;
    }
  }

  private async getRemoteContractByCode(code: string) {
    // if (code.startsWith('ct.service')) {
    //     code = code.replace('ct.service.', '');
    // }
    // const client = this.factory.CareTrackClient;
    // const postData = new GetContractByCodeRequest();
    // postData.Code = code;
    // const response = await client.get(postData);
    // if (response.IsSuccess) {
    //     return response.Data;
    // } else {
    //     return null;
    // }
  }

  private async getRemoteContractById(id: number) {
    // const client = this.factory.CareTrackClient;
    // const postData = new GetContractByIdRequest();
    // postData.Id = id;
    // const response = await client.get(postData);
    // if (response.IsSuccess) {
    //     return response.Data;
    // } else {
    //     return null;
    // }
  }

  public getDataGridColumns(fields: EntityField[]) {
    const cols = [];
    for (const item of fields) {
      cols.push(this.buildColumn(item));
    }
    return cols;
  }

  private buildColumn(item: EntityField) {
    const vm = this;
    const col: any = this.convertJsonType(item.GridColumnsOptions);
    col.dataField = item.FieldName;
    col.caption = col.caption ? col.caption : item.Name;
    col.dataType = this.getDataType(DataType[item.DataType]);
    col.format = col.format || item.Format;
    if (col.linkUrl && col.linkUrl.length > 1) {
      col.cellTemplate = 'urlDetail';
    }
    if (typeof col.visible == 'undefined') {
      col.visible = false;
    }

    // DataType
    if (DataType[item.DataType] === DataType.Image) {
      col.cellTemplate = 'image';
    }
    if (
      DataType[item.DataType] === DataType.Date ||
      DataType[item.DataType] === DataType.DateTime
    ) {
      // col.allowHeaderFiltering = false;
    }
    // DataSource
    if (DataType[item.DataType] === DataType.Array) {
      col.calculateDisplayValue = data => {
        return data[item.FieldName] ? data[item.FieldName].replace('[', '').replace(']', '') : '';
      };
    } else {
      if (item.DataSourceType == DataSourceType.Dictionary) {
        // dict
        if (EditorType[item.EditorType] != EditorType.TagBox) {
          col.lookup = {};
          col.lookup.dataSource = {
            store: new CustomStore({
              key: 'Code',
              loadMode: 'raw',
              load: () => {
                return this.getDict(item.DataSource);
              },
            }),
            sort: 'Order',
          };
          col.lookup.displayExpr = 'Name';
          col.lookup.valueExpr = 'Code';
        }
      } else if (item.DataSourceType == DataSourceType.Array) {
        // array
      } else if (item.DataSourceType == DataSourceType.Entity) {
        // entity
        col.lookup = {};
        // col.lookup.entityField = item;
        if (item.DataSource && item.DataSource.toLowerCase() == 'ct.sys.organization') {
          // col.lookup.showEditorAlways = true;
          col.lookup.dataSource = {
            store: new CustomStore({
              key: 'Id',
              loadMode: 'raw',
              load: loadOptions => {
                return vm.getDict('ct.sys.organization').then(dicts => {
                  if (loadOptions.requireTotalCount === true) {
                    return { data: dicts, totalCount: dicts.length };
                  } else {
                    return dicts;
                  }
                });
              },
              // byKey(id) {
              //     return vm.getOrganizationById(id);
              // },
            }),
            sort: 'Code',
          };
          col.lookup.displayExpr = 'Code';
          col.lookup.valueExpr = 'Id';
        }
      }
    }
    return col;
  }

  public getDxFormItems(fields: EntityField[], entity: WksEntity) {
    const items = [];
    for (const item of fields) {
      const formitem: any = this.buildFormItem(item, entity);
      items.push(formitem);
    }
    return items;
  }

  private buildFormItem(item: EntityField, entity: WksEntity) {
    const vm = this;
    const formitem: any = this.convertJsonType(item.FormItemOptions);
    formitem.entityField = item;
    // todo tabbed
    const itemType = this.getFormItemType(item.FieldType);
    formitem.itemType = itemType;
    if (itemType == 'simple') {
      if (item.Name == 'ChildSite') {
        formitem.template = 'gridTemplate_' + item.FieldName;
        formitem.visibleIndex = item.VisibleIndex;
      } else {
        formitem.dataField = item.BindFieldName;
        formitem.isRequired = item.IsRequired;
        formitem.helpText = item.Description;
        formitem.label = formitem.label || {};
        formitem.label.text = formitem.caption ? formitem.caption : item.Name;
        formitem.label.location = formitem.labelLocation ? formitem.labelLocation : undefined;
        formitem.label.visible = formitem.hideCaption ? false : true;
        formitem.editorType = this.getEditorType(EditorType[item.EditorType]);
        formitem.editorOptions = this.getEditorOptions(item.EditorOptions);
        formitem.visibleIndex = item.VisibleIndex;
        if (item.IsAutoValue) {
          formitem.editorOptions.readOnly = true;
        }
        if (typeof formitem.visible == 'undefined') {
          formitem.visible = false;
        }
        if (EditorType[item.EditorType] == EditorType.RadioBox) {
          if (!formitem.editorOptions['layout']) {
            formitem.editorOptions['layout'] = 'horizontal';
          }
        }

        if (EditorType[item.EditorType] == EditorType.RadioBox) {
          if (!formitem.editorOptions['layout']) {
            formitem.editorOptions['layout'] = 'horizontal';
          }
        }

        if (item.DefaultValue) {
          formitem.editorOptions['value'] = item.DefaultValue;
        }

        if (EditorType[item.EditorType] == EditorType.SelectBox && !item.DefaultValue) {
          formitem.editorOptions['value'] = '';
        }

        if (EditorType[item.EditorType] == EditorType.TagBox) {
          formitem.editorOptions.showDropDownButton = true;
          formitem.editorOptions.showSelectionControls = true;
          formitem.editorOptions.showClearButton = true;
          if (item.DataSourceType == DataSourceType.None) {
            formitem.editorOptions.acceptCustomValue = true;
          }
        }
        if (
          EditorType[item.EditorType] == EditorType.SelectBox ||
          EditorType[item.EditorType] == EditorType.TagBox ||
          EditorType[item.EditorType] == EditorType.RadioBox
        ) {
          if (item.DataSourceType == DataSourceType.Dictionary) {
            // dict
            // this.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
            formitem.editorOptions.displayExpr = 'Name';
            formitem.editorOptions.valueExpr = 'Code';
            formitem.editorOptions.dataSource = {
              store: new CustomStore({
                key: 'Code',
                loadMode: 'raw',
                load: loadOptions => {
                  return vm.getDict(item.DataSource, item.EditorType);
                },
                // byKey(id) {
                //     return vm.getOrganizationById(id);
                // },
              }),
              sort: 'Order',
            };
          } else if (item.DataSourceType == DataSourceType.Array) {
            // array
            formitem.editorOptions.items = item.DataSource.split(';').map(x => x.trim());
          } else if (item.DataSourceType == DataSourceType.Entity) {
            // entity
            if (item.DataSource && item.DataSource.toLowerCase() == 'ct.sys.organization') {
              formitem.editorOptions.displayExpr = 'Code';
              formitem.editorOptions.valueExpr = 'Id';
              // vm.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
              // formitem.editorOptions.items = [{Id: '5', Code: 'Code'}];
              formitem.editorOptions.dataSource = {
                store: new CustomStore({
                  key: 'Id',
                  loadMode: 'raw',
                  load: loadOptions => {
                    return vm.getDict('ct.sys.organization').then(dicts => {
                      if (loadOptions.requireTotalCount === true) {
                        return { data: dicts, totalCount: dicts.length };
                      } else {
                        return dicts;
                      }
                    });
                  },
                  // byKey(id) {
                  //     return vm.getOrganizationById(id);
                  // },
                }),
                sort: 'Code',
              };
              // this.getDict(item.DataSource).then(dict => formitem.editorOptions.items = dict);
            }
          }
        }
      }
    } else if (itemType == 'group') {
      formitem.caption = formitem.hideCaption ? null : formitem.caption || item.Name;
      const items = entity.EntityFields.filter(f => f.ParentFieldId == item.Id);
      formitem.items = this.getDxFormItems(items, entity);
      if (formitem.labelLocation) {
        formitem.items.forEach(ci => {
          if (!ci.label || !ci.label.location) {
            ci.label.location = formitem.labelLocation;
          }
        });
      }
    } else if (itemType == 'empty') {
      formitem.visibleIndex = item.VisibleIndex;
    }
    return formitem;
  }

  public getTitleItems(fields: EntityField[], entity: WksEntity) {
    const items = [];
    for (const item of fields) {
      const titleitem: any = this.buildTitleItem(item, entity);
      items.push(titleitem);
    }
    return items;
  }

  private buildTitleItem(item: EntityField, entity: WksEntity) {
    const vm = this;
    const titleitem: any = this.convertJsonType(item.TitleOptions);
    titleitem.entityField = item;
    // todo tabbed

    titleitem.dataField = item.BindFieldName;
    titleitem.Name = titleitem.caption ? titleitem.caption : item.Name;
    titleitem.editorType = this.getEditorType(EditorType[item.EditorType]);
    titleitem.NameVisible = titleitem.hideCaption ? false : true;
    titleitem.visibleIndex = titleitem.visibleIndex ? titleitem.visibleIndex : 0;
    titleitem.hasdic =
      item.DataSourceType && item.DataSourceType != 0 && item.DataSource ? true : false;
    if (typeof titleitem.visible == 'undefined') {
      titleitem.visible = false;
    }

    return titleitem;
  }

  private getDataType(type: DataType) {
    switch (type) {
      case DataType.Boolean:
        return 'boolean';
      case DataType.Date:
        return 'date';
      case DataType.DateTime:
        return 'datetime';
      case DataType.Int:
        return 'number';
      case DataType.Number:
        return 'number';
      default:
        return 'string';
    }
  }

  private getEditorType(type: EditorType) {
    switch (type) {
      case EditorType.CheckBox:
        return 'dxCheckBox';
      case EditorType.DateTimeBox:
        return 'dxDateBox';
      case EditorType.NumberBox:
        return 'dxNumberBox';
      case EditorType.RadioBox:
        return 'dxRadioGroup';
      case EditorType.SelectBox:
        return 'dxSelectBox';
      case EditorType.TagBox:
        return 'dxTagBox';
      case EditorType.TextArea:
        return 'dxTextArea';
      default:
        return 'dxTextBox';
    }
  }

  private getEditorOptions(options: any) {
    if (!options) {
      return {};
    }
    for (const key in options) {
      if (options[key] && options[key].indexOf && options[key].indexOf('{') == 0) {
        options[key] = JSON.parse(options[key]);
      }
    }
    return options;
  }

  private getFormItemType(type: FieldType) {
    switch (type) {
      case FieldType.Simple:
        return 'simple';
      case FieldType.Group:
        return 'group';
      case FieldType.Tabbed:
        return 'tabbed';
      case FieldType.Empty:
        return 'empty';
      case FieldType.Button:
        return 'button';
      default:
        return 'simple';
    }
  }

  private convertJsonType(col: any) {
    if (!col) {
      return {};
    }
    // tslint:disable-next-line:forin
    for (const att in col) {
      const val = col[att];
      if (val) {
        if (val === 'true' || val === 'True') {
          col[att] = true;
        } else if (val === 'false' || val === 'False') {
          col[att] = false;
        } else if (!isNaN(Number(val))) {
          col[att] = Number(val);
        }
      }
    }
    return col;
  }

  public convertFromEntityData(entityCode: string, entityData: any) {
    const entity = this.entityMap[entityCode];
    for (const field of entity.Fields) {
      if (DataType[field.DataType] === DataType.Boolean) {
        if (entityData[field.BindFieldName] === 'true') {
          entityData[field.BindFieldName] = true;
        } else {
          entityData[field.BindFieldName] = false;
        }
      }
    }
    return entityData;
  }

  public convertToEntityData(entityCode: string, entityData: any) {
    const entity = this.entityMap[entityCode];
    // tslint:disable-next-line:forin
    for (const att in entityData) {
      const field = entity.FieldMap[att];
      if (field && DataType[field.DataType] == DataType.Date) {
        const d = moment(entityData[att]);
        if (d.isValid) {
          entityData[att] = d.format('YYYY/MM/DD');
        } else {
          entityData[att] = null;
        }
      }
    }
    return entityData;
  }

  public getGridColumns(e) {
    return [];
  }

  public async getGetUsedParentEntityType(entityCode) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetUsedParentEntityTypeByEntityTypeCodeRequest();
    postData.EntityTypeCode = entityCode;
    const response = await client.get(postData);
    if (response.IsSuccess) {
      return response.Data;
    } else {
      return null;
    }
  }

  public getEntities() {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/entity/type/of/' + this.factory.currentWorkspaceId;
    client.get<any>(url).then(res => {
      return res.Data;
    });
  }

  public getOrganizations() {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/organization/all';
    return client.get<any>(url).then(res => {
      return res.Results;
    });
  }

  public getOrganizationById(id) {
    // const client = this.factory.MctCareWaitClient;
    // const postData = new GetOrganizationByIdRequest();
    // postData.Id = id;
    // return client.get(postData).then(res => {
    //     return res.Data;
    // });
  }

  public async searchDictDataByCode(code: string) {
    const client = this.factory.MctCareWaitClient;
    const postData = new GetDictionaryByCodeRequest();
    postData.Code = code;
    postData.IncludeDisable = false;
    const response = await client.get(postData);
    if (response.Dictionary) {
      response.Dictionary.forEach(data => (data.disabled = data.Disable));
    }
    return response.Dictionary;
  }
  async searchUsers(options) {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/organization/member/all';
    const response = await client.get<any>(url);
    return response.Results;
  }

  async searchGroups(options) {
    const client = this.factory.MctCareWaitClient;
    const url = '/core/groups/all';
    const response = await client.get<any>(url);
    return response.Results;
  }

  async searchTemplates(codes) {
    const client = this.factory.MctCareWaitClient;
    let url = '/core/reports/query?';
    url += 'CodeIn=' + codes;
    url += '&OrderBy=Name';
    const response = await client.get<any>(url);
    // return response.Data;
    return response.Results;
  }
}
